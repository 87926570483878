import React from "react";
import { useRadioInput } from "../../../../../context/RadioInputContext";

import Price from "../../ChoosePlan/OptionsPlan/Price/Price";
import Loading from "../../Loading/Loading";
import PaymentButton from "../../PaymentButton/PaymentButton";

import styles from "./SecondOptionsPlan.module.css";

const SecondOptionsPlan = () => {
  const { selectedOption, currentRender, error, handleOptionChange } =
    useRadioInput();

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (currentRender.length === 0) {
    return <Loading />;
  }

  return (
    <div className={styles.PlanContainer}>
      <div className={styles.OptionsPlanContainer}>
        {currentRender.map((element, index) => {
          const randomId = Math.round(Math.random() * 1000000);
          return (
            <label
              className={`${styles.optionStyle} ${
                selectedOption === index ? styles.active : ""
              }`}
              htmlFor={`${element.productID}_${randomId}`}
              key={element.productID}
            >
              {element.mostPopular && (
                <div className={styles.mostPopular}>
                  <span className={styles.mostPopularText}>MOST POPULAR</span>
                </div>
              )}
              <div className={styles.optionStyleRadio}>
                <input
                  type="radio"
                  name={element.month || element.days}
                  id={`${element.productID}_${randomId}`}
                  value={element.month || element.days}
                  checked={selectedOption === index}
                  onChange={() => handleOptionChange(index)}
                />
                <span className={styles.customCheckbox}></span>
                <span className={styles.optionStyleRadioText}>
                  {element.month
                    ? `${element.month} MONTH${element.month > 1 ? "S" : ""}`
                    : `${element.days}-DAY${element.days > 1 ? "S" : ""}`}
                </span>
              </div>
              <Price
                pricePerDay={element.pricePerDay}
                oldPrice={element.oldPrice}
                saveText={element.saveText ? element.saveText : null}
              />
            </label>
          );
        })}
      </div>
      <PaymentButton />
    </div>
  );
};

export default SecondOptionsPlan;
