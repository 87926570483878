import React from "react";
import styles from "./ContainerComponent.module.css";

const ContainerComponent = ({ children, styleOption }) => {
  return (
    <div
      className={styles.ContainerComponent}
      style={{
        maxWidth: styleOption.width,
      }}
    >
      {children}
    </div>
  );
};

export default ContainerComponent;
