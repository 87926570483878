import React, { useEffect } from "react";
import { useQuizContext } from "../../../context/QuizContext";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import styles from "./QuizLoader.module.css";

const Loader = ({ setResLoader, loaderInterval }) => {
  const { progress } = useQuizContext();

  useEffect(() => {
    if (progress === 100) {
      const interval = setTimeout(() => {
        setResLoader(false);
      }, loaderInterval);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress]);
  return (
    <div className={styles.Loader}>
      <div className={styles.LoaderContent}>
        <CircularProgressbar
          value={progress}
          text={`${progress}%`}
          strokeWidth={5}
          styles={{
            path: {
              stroke: "#4beeb3",
              strokeLinecap: "round",
            },
            trail: {
              stroke: "rgba(232, 237, 255, 0.2)",
              strokeLinecap: "round",
            },
            text: {
              fill: "rgba(75, 238, 179, 1)",
              textAlign: "center",
              fontSize: "22px",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "40px",
            },
          }}
          className={styles.Progressbar}
        />
      </div>
    </div>
  );
};

export default Loader;
