import React from "react";

// import { useABtest } from "../../../../../../../context/ABTestContext";

import PaymentSystems from "../../assets/PaymentSystems";
import GraySafeIconSVG from "../../assets/GraySafeIcon";

import styles from "../../FooterBlockStyle.module.css";

const VariantB = () => {
  // const { isActive } = useABtest();
  return (
    <div>
      <div className={styles.headerContainer}>
        {/* {isActive ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLine} />
        )}
        <div className={styles.iconTextContainer}>
          <GraySafeIconSVG />
          <span className={styles.headerText}>
            {isActive ? "No payment now. Secured by:" : "Secured by:"}
          </span>
        </div>
        {isActive ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLine} />
        )} */}
        <div className={styles.hrLine} />
        <GraySafeIconSVG />
        <span className={styles.headerText}>{"Secured by:"}</span>
        <div className={styles.hrLine} />
      </div>
      <PaymentSystems />
    </div>
  );
};

export default VariantB;
