import React, { createContext, useContext, useEffect, useState } from "react";
import { useFirebaseConfig } from "./FirebaseConfigContext";

const vwoContext = createContext();

export const useVWOContext = () => {
  return useContext(vwoContext);
};

export const VWOProvider = ({ children }) => {
  const {
    abTestCampaignName,
    setABTestPrice,
    abTestDesignVariant,
    defaultPriceVariant,
    setUseDefault,
  } = useFirebaseConfig();

  const [VWO] = useState(window.VWO);

  const [variantNumber, setVariantNumber] = useState(null);

  const [errorVWOCombination, setErrorVWOCombination] = useState(false);

  const [devMode, setDevMode] = useState(false);

  useEffect(() => {
    if (devMode) {
      if (abTestDesignVariant === "A") {
        if (variantNumber !== null && variantNumber === 1) {
          setVariantNumber(2);
        } else if (variantNumber !== null && variantNumber === 2) {
          setVariantNumber(1);
        }
      } else if (abTestDesignVariant === "B") {
        if (variantNumber !== null && variantNumber === 1) {
          setVariantNumber(2);
        } else if (variantNumber !== null && variantNumber === 2) {
          setVariantNumber(3);
        } else if (variantNumber !== null && variantNumber === 3) {
          setVariantNumber(1);
        }
      } else if (abTestDesignVariant === "C") {
        if (variantNumber !== null && variantNumber === 1) {
          setVariantNumber(2);
        } else if (variantNumber !== null && variantNumber === 2) {
          setVariantNumber(1);
        }
        // else if (variantNumber !== null && variantNumber === 3) {
        //   setVariantNumber(1);
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devMode]);

  const findCombinationChosen = () => {
    let attemptCount = 0;
    const maxAttempts = 10;

    const campaignName = abTestCampaignName;
    const campaign = Object.values(VWO).find(
      (value) =>
        typeof value === "object" && deepFind(value, "name") === campaignName
    );

    if (campaign) {
      const combinationChosen = deepFind(campaign, "combination_chosen");
      if (combinationChosen) {
        setVariantNumber(parseInt(combinationChosen));
        console.log("Combination chosen:", combinationChosen);
        setErrorVWOCombination(false);
      } else {
        attemptCount++;
        if (attemptCount < maxAttempts) {
          setTimeout(findCombinationChosen, 100);
        } else {
          setErrorVWOCombination(true);
          console.warn(
            `Campaign "${campaignName}" combination_chosen not found after ${maxAttempts} attempts. Using default values!`
          );
        }
      }
    } else {
      setErrorVWOCombination(true);
      console.warn(
        `Campaign "${campaignName}" for this page not found. Using default values!`
      );
    }
  };

  const deepFind = (obj, key) => {
    if (typeof obj === "object" && obj !== null) {
      if (key in obj) {
        return obj[key];
      }

      for (let prop in obj) {
        if (typeof obj[prop] === "object") {
          const result = deepFind(obj[prop], key);
          if (result !== undefined) {
            return result;
          }
        }
      }
    }

    return null;
  };

  useEffect(() => {
    if (VWO && Object.keys(VWO).length > 0) {
      findCombinationChosen();
    } else {
      setErrorVWOCombination(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [VWO]);

  useEffect(() => {
    if (variantNumber !== null) {
      setABTestPrice(variantNumber);
    } else if (errorVWOCombination) {
      setUseDefault(true);
      setVariantNumber(defaultPriceVariant);
      console.warn(
        `VWO campaign for this page not found. Using default values!`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantNumber, defaultPriceVariant, errorVWOCombination]);

  const contextValue = {
    variantNumber,
    setVariantNumber,
    setDevMode,
    devMode,
  };

  return (
    <vwoContext.Provider value={contextValue}>{children}</vwoContext.Provider>
  );
};
