import React, { useEffect, useState } from "react";
import { useVWOContext } from "../../../../../context/vwoContext";
import { useABtest } from "../../../../../context/ABTestContext";
import { useTimer } from "../../../../../context/TimerContext";
import { useConfig } from "../../../../../context/ConfigDataContext";

import Loading from "../../Loading/Loading";

import styles from "../PleaseNoteStyle.module.css";

const VariantB = ({ textData }) => {
  const { variantNumber } = useVWOContext();
  const { isActive, selectedABOption } = useABtest();
  const { showHideElement } = useTimer();
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "PleaseNote");

  const [isLoading, setIsLoading] = useState(true);

  const [currentRenderData, setCurrentRenderData] = useState([]);

  const updateNumber = (number) => {
    switch (number) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 2;
      default:
        return 0;
    }
  };

  const optionNumber = (option, toggle) => {
    switch (option) {
      case 0:
        if (!toggle) {
          return 0;
        } else if (toggle) {
          return 2;
        }
        break;
      case 1:
        return 1;

      default:
        break;
    }
  };

  useEffect(() => {
    if (showHideElement) {
      setIsLoading(false);
      setCurrentRenderData(
        textData.textWithDiscount[updateNumber(variantNumber)][
          optionNumber(selectedABOption, isActive)
        ]
      );
      //   findSplitText(currentRenderData);
    } else if (!showHideElement) {
      setIsLoading(false);
      setCurrentRenderData(
        textData.textWithoutDiscount[updateNumber(variantNumber)][
          optionNumber(selectedABOption, isActive)
        ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHideElement, selectedABOption, isActive, variantNumber]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <span className={styles.mainText}>
          <span className={styles.hightText}>{data.textHight}</span>
          {isLoading ? (
            <Loading />
          ) : (
            <span>
              {currentRenderData[0]}{" "}
              <a
                href={data.linkSubscriptionTerms}
                className={styles.subscrText}
                target="blank"
              >
                {data.textLink}
              </a>
              {currentRenderData[1]}
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default VariantB;
