import React from "react";

import styles from "./GarantyInfoStyle.module.css";

const GarantyInfo = ({ data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.element}>
        <img
          src="./img/Refund.png"
          alt="Refund"
          style={{ width: "106px", height: "84px" }}
        />
        <span className={styles.headerText}>{data.Refund.headerText}</span>
        <span className={styles.mainText}>{data.Refund.mainText}</span>
      </div>
      <div className={styles.element}>
        <img
          src="./img/Transparency.png"
          alt="Transparency"
          style={{ width: "92px", height: "84px" }}
        />
        <span className={styles.headerText}>
          {data.Transparency.headerText}
        </span>
        <span className={styles.mainText}>{data.Transparency.mainText}</span>
      </div>
      <div className={styles.element}>
        <img
          src="./img/Secure.png"
          alt="Secure"
          style={{ width: "92px", height: "84px" }}
        />
        <span className={styles.headerText}>{data.Secure.headerText}</span>
        <span className={styles.mainText}>{data.Secure.mainText}</span>
      </div>
    </div>
  );
};

export default GarantyInfo;
