import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./FooterContainer.module.css";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

const FooterContainer = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1, "FooterContainer");

  const handleEmailClick = () => {
    window.location.href = "mailto:support@memoryos.com";
  };

  const handlePhoneClick = () => {
    window.location.href = "tel:+17327439003";
  };
  return (
    <div className={styles.FooterContainer}>
      <LogoGlobal />
      <div className={styles.FooterContainerTextMisson}>{data.missionText}</div>
      <div className={styles.FooterContainerOtherText}>
        <div className={styles.FooterPolicyGroup}>
          <span className={styles.FooterContainerTextLink}>
            <a
              href="https://memoryos.com/privacy-policy"
              className={styles.FooterContainerText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
          </span>
          <span className={styles.FooterContainerTextLink}>
            <a
              href="https://memoryos.com/terms"
              className={styles.FooterContainerText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </a>
          </span>
          <span className={styles.FooterContainerTextLink}>
            {" "}
            <a
              href="https://memoryos.com/terms"
              className={styles.FooterContainerText}
              target="_blank"
              rel="noopener noreferrer"
            >
              Subscription Policy
            </a>
          </span>
        </div>
        <div className={styles.FooterContactGroup}>
          <span className={styles.TelMail} onClick={handleEmailClick}>
            support@memoryos.com
          </span>
          <span className={styles.TelMail} onClick={handlePhoneClick}>
            +1 (732) 743 9003
          </span>
        </div>
        <div className={styles.FooterCopyright}>
          <span className={styles.FooterContainerText}>
            © Copyright {new Date().getFullYear()} Encoder Inc., Delaware, the
            USA
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterContainer;
