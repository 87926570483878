import React, { useEffect, useState } from "react";
// import { useFirebaseConfig } from "../../../../../../context/FirebaseConfigContext";
// import { useTimer } from "../../../../../../context/TimerContext";
// import { useABtest } from "../../../../../../context/ABTestContext";
// import { useVWOContext } from "../../../../../../context/vwoContext";

// import VariantA from "./ABTestDesignVariants/VariantA";
// import VariantB from "./ABTestDesignVariants/VariantB";

import styles from "./PlanDetails.module.css";

import Loading from "../../../../ABtestPlanPage/Loading/Loading";
import VariantC from "./ABTestDesignVariants/VariantC";

const PlanDetails = ({ data, selectedOption }) => {
  // const { abTestDesignVariant } = useFirebaseConfig();
  // const { isActive } = useABtest();
  // const { variantNumber } = useVWOContext();
  // const { showHideElement } = useTimer();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (selectedOption !== undefined) {
      setLoading(false);
    }
  }, [selectedOption]);
  return (
    <div className={styles.PlanDetailsContainer}>
      {/* {isLoading ? (
        <Loading />
      ) : abTestDesignVariant === "A" ? (
        <VariantA
          isActive={isActive}
          variantNumber={variantNumber}
          showHideElement={showHideElement}
          detailsText={detailsText}
          selectedOption={selectedOption}
        />
      ) : (
        <VariantB
          isActive={isActive}
          detailsText={detailsText}
          selectedOption={selectedOption}
        />
      )} */}

      {isLoading ? (
        <Loading />
      ) : (
        <VariantC data={data} selectedOption={selectedOption} />
      )}
    </div>
  );
};

export default PlanDetails;
