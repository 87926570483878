import React, { useEffect, useRef, useState } from "react";

import { useScroll } from "../../../../context/ScrollContext";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import { useVWOContext } from "../../../../context/vwoContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import FooterBlock from "./FooterBlock/FooterBlock";
import ImgPlanBlock from "./ImgPlanBlock/ImgPlanBlock";

import Loading from "../../ABtestPlanPage/Loading/Loading";
import VariantС from "../ABTestPlanContainer/VariantC/VariantС";
import HeaderVariantB from "./HeaderRender/HeaderVariantB";

import styles from "./ChoosePlan.module.css";
import ActiveDownsale from "./ActiveDownsale/ActiveDownsale";

const ChoosePlan = () => {
  const { optionTextData, downSaleActive } = useFirebaseConfig();
  const choosePlanRef = useRef(null);
  const { findDataComponent } = useConfig();
  const { scrollRefOne } = useScroll();
  const { timer } = useTimer();
  const { variantNumber } = useVWOContext();

  const data = findDataComponent(2, "ChoosePlan");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    scrollRefOne.current = choosePlanRef.current;
  }, [scrollRefOne]);

  useEffect(() => {
    if (variantNumber !== null) {
      setIsLoading(false);
    }
  }, [variantNumber]);
  

  return (
    <div className={styles.bigContainer}>
      <ImgPlanBlock />
      <div className={styles.ChoosePlanContainer} ref={choosePlanRef}>
        {downSaleActive && <ActiveDownsale />}
        <HeaderVariantB
          variantNumber={variantNumber}
          data={optionTextData.firstPlanOptionHeaderText}
          timer={timer}
        />

        {isLoading ? <Loading /> : <VariantС />}

        <FooterBlock
          data={data.footerBlock}
          footerData={data.secondTextInfoBlock}
        />
      </div>
    </div>
  );
};

export default ChoosePlan;
