import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper";

import styles from "./VideoStoreSliderStyle.module.css";
import VideoPlayer from "./VideoPlayer/VideoPlayer";

SwiperCore.use([Autoplay, Pagination, Navigation]);

const VideoStoreSlider = ({ videoReview, videoText }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);

  const handlePrevSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <div className={styles.blockContainer}>
      <span className={styles.videoText}>{videoText}</span>

      <Swiper
        ref={swiperRef}
        slidesPerView={"auto"}
        spaceBetween={40}
        pagination={false}
        autoplay={false}
        loop={true}
        centeredSlides={true}
        centeredSlidesBounds={true}
        className={styles.videoSliderContainer}
      >
        <div className={styles.shadowLeftCont} />
        <div className={styles.shadowRightCont} />

        {videoReview.map((element, index) => {
          return (
            <SwiperSlide className={styles.videoContainer} key={index}>
              <VideoPlayer
                linkVideo={element.linkVideo}
                linkPoster={element.linkPoster}
              />
            </SwiperSlide>
          );
        })}
        <div className={styles.buttonContainer}>
          <button className={styles.buttonArrowL} onClick={handlePrevSlide}>
            <img
              src="/img/ArrowLeft_Btn.png"
              alt="ArrowLeft"
              className={styles.arrowImg}
            />
          </button>
          <button className={styles.buttonArrowR} onClick={handleNextSlide}>
            <img
              src="/img/ArrowRight_Btn.png"
              alt="ArrowRight"
              className={styles.arrowImg}
            />
          </button>
        </div>
      </Swiper>
    </div>
  );
};

export default VideoStoreSlider;
