import React from "react";

import styles from "../PlanDetails.module.css";
const VariantA = ({
  isActive,
  variantNumber,
  showHideElement,
  detailsText,
  selectedOption,
}) => {
  return (
    <div className={styles.PlanDetailsContainer}>
      <div className={styles.PlanDetailsTextContainer}>
        <span className={styles.PlanDetailsMonth}>
          {/* Plan name A-B option */}
          <span>
            {variantNumber === 1
              ? `${selectedOption.month} month ${selectedOption.fullPlanName}`
              : `${selectedOption.optionPlanName} ${selectedOption.fullPlanName}`}
          </span>
        </span>
        <span className={styles.PlanDetailsMonth}>
          &#36;
          {selectedOption.fullPlanPrice}
        </span>
      </div>
      {/* Plan trial price A-B option */}
      {variantNumber === 1 ? (
        showHideElement ? (
          <div className={styles.PlanDetailsTextContainer}>
            <span className={styles.PlanDetailsDiscount}>
              <span className={styles.sevenDayDiscount}>
                {detailsText.discountText}
              </span>
            </span>
            <span className={styles.PlanDetailsDiscountSavePrice}>
              -&#36;{selectedOption.savingsAmount}
            </span>
          </div>
        ) : (
          false
        )
      ) : isActive ? (
        <div className={styles.PlanDetailsTextContainer}>
          <span className={styles.PlanDetailsDiscount}>
            <span className={styles.sevenDayDiscount}>
              {detailsText.discountText}
            </span>
          </span>
          <span className={styles.PlanDetailsDiscountSavePrice}>
            -&#36;{selectedOption.savingsAmount}
          </span>
        </div>
      ) : (
        false
      )}
      {/* Plan trial price - End */}
      <div className={styles.Total}>
        <span className={styles.PlanDetailsTotalText}>
          {detailsText.totalText}
        </span>
        <span className={styles.PlanDetailsTotalText}>
          &#36;{selectedOption.priceTotal}
        </span>
      </div>

      {showHideElement && variantNumber === 1 ? (
        <div className={styles.PlanDetailsTextContainerBottomTrial}>
          <span className={styles.TextBottomTrial}>
            {detailsText.bottomText}
          </span>
        </div>
      ) : (
        false
      )}
    </div>
  );
};

export default VariantA;
