import React from "react";
import VariantA from "./RenderVariants/VariantA";
import VariantB from "./RenderVariants/VariantB";

const GraySafeBlock = ({ abTestDesignVariant, text, textSecond }) => {
  return (
    <div>
      {abTestDesignVariant === "A" ? (
        <VariantA text={text} textSecond={textSecond} />
      ) : (
        <VariantB />
      )}
    </div>
  );
};

export default GraySafeBlock;
