import React from "react";
import { useTimer } from "../../../../context/TimerContext";

const Timer = ({ styleProperty }) => {
  const { timer } = useTimer();

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60).toLocaleString(undefined, {
      minimumIntegerDigits: 1,
    });
    const seconds = (time % 60).toLocaleString(undefined, {
      minimumIntegerDigits: 2,
    });
    return `${minutes}:${seconds}`;
  };

  return (
    <div className={styleProperty ? styleProperty : null}>
      {formatTime(timer)}
    </div>
  );
};

export default Timer;
