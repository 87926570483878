import React from "react";

import Price from "../Price/Price";

import styles from "../VariantСStyle.module.css";

const SecondPlanOption = ({
  secondOptionRender,
  selectedOption,
  setSelectedDiscountOption,
}) => {
  const randomId = Math.round(Math.random() * 1000000);
  return (
    <label
      className={`${styles.optionStyle} ${
        selectedOption === 1 ? styles.actives : ""
      }`}
      htmlFor={`${secondOptionRender.productID}${randomId}`}
      key={secondOptionRender.productID}
    >
      {secondOptionRender.mostPopular && (
        <div className={styles.mostPopular}>
          <span className={styles.mostPopularText}>MOST POPULAR</span>
        </div>
      )}
      <div className={styles.optionStyleRadioOne}>
        <input
          type="radio"
          name={secondOptionRender.month || secondOptionRender.days}
          id={`${secondOptionRender.productID}${randomId}`}
          value={secondOptionRender.month || secondOptionRender.days}
          checked={selectedOption === 1}
          onChange={() => setSelectedDiscountOption(1)}
        />
        <span
          className={`${styles.customCheckboxOne} ${
            selectedOption === 1 ? styles.customCheckboxOneAfter : ""
          }`}
        ></span>
        <div className={styles.optionTextContainer}>
          <span className={styles.optionStyleRadioTextLeft}>
            {secondOptionRender.month}
          </span>
          <span className={styles.optionStyleRadioTextBottom}>
            {secondOptionRender.saveText}
          </span>
        </div>
      </div>
      <Price
        pricePerDay={secondOptionRender.pricePerDay}
        oldPrice={secondOptionRender.oldPrice}
      />
    </label>
  );
};

export default SecondPlanOption;
