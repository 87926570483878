import React from "react";

import styles from "./RatingContainerStyle.module.css";

const RatingContainer = () => {
  const ratings = Array.from({ length: 5 }, (_, index) => (
    <img
      key={index}
      src="./img/rating_star.png"
      alt="rating_star"
      className={styles.star}
    />
  ));

  return (
    <div className={styles.starContainer}>
      {ratings.map((rating, index) => (
        <div key={index}>{rating}</div>
      ))}
    </div>
  );
};

export default RatingContainer;
