import React from "react";

import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./JoinTheRestStyle.module.css";


const JoinTheRest = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1, "JoinTheRest");

  return (
    <div className={styles.container}>
      <div className={styles.imgPeople} />
      <span className={styles.bigText}>
        {data.mainText}{" "}
        <span className={styles.smallText}>
          {data.lowText}
        </span>
      </span>
    </div>
  );
};

export default JoinTheRest;
