import React from "react";

import { useScroll } from "../../../../context/ScrollContext";
import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./TopDiscount.module.css";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

const TopDiscount = () => {
  const { findDataComponent } = useConfig();

  const { scrollToComponent } = useScroll();
  const dataComponent = findDataComponent(2, "TopDiscount");

  const handleButtonClick = () => {
    setTimeout(() => {
      scrollToComponent();
    }, 50);
  };

  return (
    <>
      <div className={styles.containerDiscount}>
        <div className={styles.topLogo}>
          <LogoGlobal />
        </div>
        <div className={styles.topContent}>
          <button
            className={styles.containerDiscountButton}
            onClick={handleButtonClick}
          >
            <span className={styles.containerDiscountButtonText}>
              {dataComponent.buttonText}
            </span>
          </button>
        </div>
      </div>
    </>
  );
};

export default TopDiscount;
