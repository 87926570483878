import React, { useEffect, useState } from "react";

import { useRadioInput } from "../../../../../../context/RadioInputContext";
import { useTimer } from "../../../../../../context/TimerContext";

import styles from "./FooterTextContainerStyle.module.css";

const FooterTextContainer = ({ data }) => {
  const { selectedOption } = useRadioInput();
  const { showHideElement } = useTimer();

  const [currentRenderData, setCurrentRenderData] = useState("");

  const findDataInfo = (index, data) =>
    index >= 0 && index < data.length ? data[index] : "";

  useEffect(() => {
    if (showHideElement) {
      setCurrentRenderData(
        findDataInfo(selectedOption, data.textInfoWithTimer)
      );
    } else if (!showHideElement) {
      setCurrentRenderData(
        findDataInfo(selectedOption, data.textInfoWithoutTimer)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, showHideElement]);

  return (
    <div className={styles.Footer}>
      <span className={styles.FooterText}>{currentRenderData}</span>
    </div>
  );
};

export default FooterTextContainer;
