import React from "react";

import styles from "./ImgPlanBlockStyle.module.css";

const ImgPlanBlock = () => {
  return (
    <div className={styles.container}>
      <img
        src="../img/imgs-ChosePlan/Space_Refill.png"
        alt="Space_Refill"
        className={styles.spaceRefill}
      />
      <img
        src="../img/imgs-ChosePlan/Medieval_Scales.png"
        alt="Medieval_Scales"
        className={styles.medievalScales}
      />
      <img
        src="../img/imgs-ChosePlan/CyberEgypt.png"
        alt="CyberEgypt"
        className={styles.cyberEgypt}
      />

      <img
        src="../img/imgs-ChosePlan/Space_Spaceship.png"
        alt="Space_Spaceship"
        className={styles.spaceSpaceship}
      />
      <img
        src="../img/imgs-ChosePlan/Geta.png"
        alt="Geta"
        className={styles.geta}
      />

      <img
        src="../img/imgs-ChosePlan/Medieval_ShieldSword.png"
        alt="Medieval_ShieldSword"
        className={styles.medievalShieldSword}
      />

      <img
        src="../img/imgs-ChosePlan/Space_Spacesuit.png"
        alt="Space_Spacesuit"
        className={styles.spaceSpacesuit}
      />
      <img
        src="../img/imgs-ChosePlan/Medieval_Piano.png"
        alt="Medieval_Piano"
        className={styles.medievalPiano}
      />
    </div>
  );
};

export default ImgPlanBlock;
