import React from "react";

const WithDuplicateComponent = (WrappedComponent) => {
  class DuplicateComponent extends React.Component {
    render() {
      return (
        <React.Fragment>
          <WrappedComponent {...this.props} />
        </React.Fragment>
      );
    }
  }

  return DuplicateComponent;
};

export default WithDuplicateComponent;
