import React, { createContext, useContext, useState } from "react";

const ErrorHandlerContext = createContext();

export const useErrorHandler = () => useContext(ErrorHandlerContext);

export const ErrorHandlerProvider = ({ children }) => {
  const [showError, setError] = useState(false);

  const setErrorHandler = (errorMessage) => {
    console.log(errorMessage);
    setError(true);
    const intervalId = setInterval(() => {
      setError(false);
      clearInterval(intervalId);
    }, 10000);
  };

  const contextValue = {
    setError,
    showError,
    setErrorHandler,
  };

  return (
    <ErrorHandlerContext.Provider value={contextValue}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};
