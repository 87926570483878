import React from "react";
import { useFirebaseConfig } from "../../context/FirebaseConfigContext";
import { useVWOContext } from "../../context/vwoContext";

import PlanSub from "./PaidTrialsAllSubs/PlanSub";
import SecondPlanSub from "./RedesignPaymentPage/SecondPlanSub";
import ABtestPlanPage from "./ABtestPlanPage/ABtestPlanPage";
// import ShortPlanPage from "./ShortPlanPage/ShortPlanPage";

import Loading from "./ShortPlanPage/Loading/Loading";

const DesignSwitcher = () => {
  const { currentDesign } = useFirebaseConfig();
  const { variantNumber } = useVWOContext();

  // const variantNumber = 1;

  const getDesignOfferPage = (design) => {
    switch (design) {
      case "A":
        return <PlanSub />;
      case "B":
        return <SecondPlanSub />;
      case "C":
        // if (variantNumber === 2 || variantNumber === 3) {
        //   return <ShortPlanPage />;
        // } else if (variantNumber === 1) {
        //   return <ABtestPlanPage />;
        // }
        if (variantNumber === 1) {
          return <ABtestPlanPage />;
        } else if (variantNumber === 2) {
          return <ABtestPlanPage />;
        }
        break;

      default:
        break;
    }
  };

  if (!variantNumber) {
    return <Loading />;
  }

  return getDesignOfferPage(currentDesign);
};

export default DesignSwitcher;
