import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";
import { useDownSale } from "../../../../context/DownSaleContext";

import styles from "./PaymentButtonStyle.module.css";

const SpecialOfferPaymentButton = () => {
  const { findDataComponent } = useConfig();

  const { setShowHideSpecialOfferPopup } = useDownSale();

  const { timer } = useTimer();

  const data = findDataComponent(2, "NewPaymentButton");

  const { paymentButtonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      setShowHideSpecialOfferPopup(false);
      paymentButtonRef.current.click();
    }
  };

  return (
    <button className={styles.specialBtnContainer} onClick={handleButtonClick}>
      <p className={styles.specialpaymentButtonText}>
        {timer > 0 ? data.buttonText[1] : data.buttonText[0]} <span className={styles.specialpaymentButtonTextHands}>🙌</span>
      </p>
    </button>
  );
};

export default SpecialOfferPaymentButton;
