import React, { useEffect, useState } from "react";
import styles from "../ChoosePlan.module.css";
// import { useABtest } from "../../../../../context/ABTestContext";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";
import Loading from "../../Loading/Loading";

const HeaderVariantB = ({ timer }) => {
  // const { isActive } = useABtest();
  const [renderText, setRenderText] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const { specialOptionTextData } = useFirebaseConfig();

  useEffect(() => {
    if (specialOptionTextData && 
        specialOptionTextData.firstPlanOptionHeaderText &&
        specialOptionTextData.firstPlanOptionHeaderText.textWithTimer &&
        specialOptionTextData.firstPlanOptionHeaderText.textWithTimer[0] &&
        specialOptionTextData.firstPlanOptionHeaderText.textWithTimer[0][0]) {
      setRenderText(
        specialOptionTextData.firstPlanOptionHeaderText.textWithTimer[0][0]
      );
      setIsLoading(false);
    }
  }, [specialOptionTextData]);

  // useEffect(() => {
  //   if (!isLoading && timer > 0 && specialOptionTextData?.firstPlanOptionHeaderText?.textWithTimer?.[0]) {
  //     const textWithTimer = specialOptionTextData.firstPlanOptionHeaderText.textWithTimer[0];
  //     setRenderText(isActive ? textWithTimer[1] : textWithTimer[0]);
  //   } else if (!isLoading && specialOptionTextData?.firstPlanOptionHeaderText?.textWithoutTimer) {
  //     setRenderText(specialOptionTextData.firstPlanOptionHeaderText.textWithoutTimer);
  //   }
  // }, [isActive, timer, isLoading, specialOptionTextData]);

  if (isLoading) {
    return <Loading />;
  }

  return <div className={styles.HeaderText}>{renderText}</div>;
};

export default HeaderVariantB;