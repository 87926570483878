import React from "react";
import styles from "../PaymentFormStyle.module.css";

const HRContainer = ({ data }) => {
  return (
    <div className={styles.secureContainer}>
      <div className={styles.secureHrTwo} />
      <div className={styles.midContainer}>
        <img
          src="../img/shieldPaymentForm.png"
          alt="shieldPaymentForm"
          className={styles.shieldPaymentForm}
        />
        <span className={styles.newSecureText}>{data}</span>
      </div>

      <div className={styles.secureHrTwo} />
    </div>
  );
};

export default HRContainer;
