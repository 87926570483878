import React, { useCallback, useEffect, useRef, useState } from "react";
import { useScroll } from "../../../../context/ScrollContext";

import FooterBlock from "./FooterBlock/FooterBlock";
import ImgPlanBlock from "./ImgPlanBlock/ImgPlanBlock";
import HeaderVariantB from "./HeaderRender/HeaderVariantB";
import VariantС from "../ABTestPlanContainer/VariantC/VariantС";
import Loading from "../Loading/Loading";

import styles from "./ChoosePlan.module.css";

const ChoosePlan = () => {
  const scrollRef = useRef(null);
  const { scrollRefOne } = useScroll();
  const [isLoading, setIsLoading] = useState(true);

  const setScrollRef = useCallback(
    (node) => {
      if (node) {
        scrollRef.current = node;
        scrollRefOne.current = node;
      }
    },
    [scrollRefOne]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.bigContainer}>
      <ImgPlanBlock />
      <div className={styles.ChoosePlanContainer} ref={setScrollRef}>
        <HeaderVariantB />
        <VariantС />
        <FooterBlock />
      </div>
    </div>
  );
};

export default ChoosePlan;
