import React from "react";
import { useConfig } from "../../../context/ConfigDataContext";

import styles from "./LoaderStyles.module.css";
const Loader = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "LoaderGlobal");


  const importStyle = {
    border: `7px solid ${data.topColor}`,
    borderBottomColor: data.botColor,
  };
  return (
    <div className={styles.container}>
      <div className={styles.loader} style={importStyle}></div>
    </div>
  );
};
export default Loader;
