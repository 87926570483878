import React from "react";

import { useABtest } from "../../../../../../context/ABTestContext";

import styles from "../PaymentFormStyle.module.css";

const HRVariantB = () => {
  const { isActive } = useABtest();

  return (
    <div className={styles.secureContainer}>
      {isActive ? (
        <div className={styles.secureHrTwo} />
      ) : (
        <div className={styles.secureHr} />
      )}
      <div className={styles.secureImg} />

      <span className={styles.secureText}>
        {isActive ? "No payment now. Secured by:" : "Secured by:"}
      </span>
      {isActive ? (
        <div className={styles.secureHrTwo} />
      ) : (
        <div className={styles.secureHr} />
      )}
    </div>
  );
};

export default HRVariantB;
