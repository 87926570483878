import React from "react";
import styles from "./CustomProgresBarStyle.module.css";

const CustomProgressBar = ({ progress }) => {
  const sections = ["profile", "personality", "preferences"];
  const totalSections = sections.length;
  const sectionWidth = 100 / totalSections;

  const calculateSectionProgress = (sectionIndex) => {
    const sectionStart = sectionIndex * sectionWidth;
    const sectionEnd = (sectionIndex + 1) * sectionWidth;

    if (progress <= sectionStart) return 0;
    if (progress >= sectionEnd) return 100;

    const sectionProgress = ((progress - sectionStart) / sectionWidth) * 100;
    return Math.min(sectionProgress, 100);
  };

  return (
    <div className={styles.container}>
      {sections.map((section, index) => (
        <React.Fragment key={section}>
          <div
            className={`${styles.circle} ${
              calculateSectionProgress(index) > 0 ? styles.active : ""
            }`}
          />
          {index < sections.length && (
            <div className={styles.line}>
              <div
                className={styles.lineProgress}
                style={{
                  width: `${calculateSectionProgress(index)}%`,
                }}
              />
            </div>
          )}
        </React.Fragment>
      ))}
      <div className={styles.circle} />
    </div>
  );
};

export default CustomProgressBar;
