import React from "react";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";
import VideoContainer from "./VideoContainer/VideoContainer";
import WithDuplicateComponent from "../WithDuplicateComponent/WithDuplicateComponent";
import StoreSlider from "./Slider/StoreSlider";
import { Component } from "../../styles";
import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./ReviewContainer.module.css";

const ReviewContainer = () => {
  const { feedbackData } = useFirebaseConfig();

  const { findDataComponent } = useConfig();

  const data = findDataComponent(1, "ReviewContainer");

  const Slider1 = WithDuplicateComponent(StoreSlider);
  const Slider2 = WithDuplicateComponent(StoreSlider);
  return (
    <div className={styles.container}>
      <Component $dark $plain>
        <div className={styles.ReviewContainerHeaderText}>
          {data.headerText}
        </div>
        <VideoContainer
          posterImg={data.linkPoster}
          linkVideo={data.linkVideo}
        />
        <Slider1 dataReview={feedbackData.AppStoreReview} delayProps={3500} />
        <Slider2 dataReview={feedbackData.PlayStoreReview} delayProps={4100} />
      </Component>
    </div>
  );
};

export default ReviewContainer;
