import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import { useButtonRef } from "../../../../context/ButtonRefContext";

import styles from "./PaymentButtonStyle.module.css";


const PaymentButton = () => {
  const { findDataComponent } = useConfig();

  const { timer } = useTimer();

  const data = findDataComponent(2, "NewPaymentButton");

  const { paymentButtonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (paymentButtonRef.current) {
      paymentButtonRef.current.click();
    }
  };

  return (
    <div className={styles.btnContainer}>
      <button className={styles.paymentButton} onClick={handleButtonClick}>
        <span className={styles.paymentButtonText}>
          {timer > 0 ? data.buttonText[1] : data.buttonText[0]}
        </span>
        <span className={styles.arrow} />
      </button>
    </div>
  );
};

export default PaymentButton;
