import React, { createContext, useContext, useEffect, useState } from "react";
import { useFirebaseConfig } from "./FirebaseConfigContext";
import { usePaymentForm } from "./PaymentFormContext";

const ApplyDiscountContext = createContext();

export const useDiscount = () => useContext(ApplyDiscountContext);

export const ApplyDiscountProvider = ({ children }) => {
  const { discountPageData } = useFirebaseConfig();
  const { setCurrentOrder } = usePaymentForm();

  const [textDiscountToPlan, setDiscountToPlan] = useState({});

  const [firstDiscountRender, setFirstDiscountRender] = useState({});
  const [secondDiscountRender, setSecondDiscountRender] = useState({});

  const [selectedDiscountOption, setSelectedDiscountOption] = useState(0);

  const [optionToPay, setOptionToPay] = useState({});

  const [index] = useState(0);

  useEffect(() => {
    setFirstDiscountRender(
      getOptionsByIndex(index, discountPageData.applyDiscountPlanOptions[0])
    );
    setSecondDiscountRender(
      getOptionsByIndex(index, discountPageData.applyDiscountPlanOptions[1])
    );
    if (firstDiscountRender.length !== 0) {
      setDiscountToPlan(firstDiscountRender);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountPageData.applyDiscountPlanOptions]);

  function getOptionsByIndex(index, data) {
    const monthsToChoose = data.monthsToChoose;
    return monthsToChoose.map((element) => ({
      fullPlanName: data.planName,
      ...(element.days && { days: element.days }),
      ...(element.month && { month: element.month }),
      optionPlanName: element.optionPlanName,
      trialDays: data.trial_days,
      mostPopular: element.mostPopular,
      fullPlanPrice: element.fullPlanPrice,
      ...element.priceOptions[index],
    }));
  }

  useEffect(() => {
    if (selectedDiscountOption === 0) {
      setDiscountToPlan(firstDiscountRender[0]);
      setOptionToPay(firstDiscountRender[0]);
    } else if (selectedDiscountOption === 1) {
      setDiscountToPlan(secondDiscountRender[0]);
      setOptionToPay(secondDiscountRender[0]);
    }
  }, [firstDiscountRender, secondDiscountRender, selectedDiscountOption]);

  useEffect(() => {
    setCurrentOrder(optionToPay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionToPay]);

  const contextValue = {
    textDiscountToPlan,
    firstDiscountRender,
    secondDiscountRender,
    selectedDiscountOption,
    setSelectedDiscountOption,
    optionToPay,
  };

  return (
    <ApplyDiscountContext.Provider value={contextValue}>
      {children}
    </ApplyDiscountContext.Provider>
  );
};
