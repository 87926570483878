import React, { createContext, useContext, useState, useEffect } from "react";
import { useConfig } from "./ConfigDataContext";
import { useFirebaseConfig } from "./FirebaseConfigContext";

const TimerContext = createContext();

export const useTimer = () => useContext(TimerContext);

export const TimerProvider = ({ children }) => {
  const { setDownSaleActive } = useFirebaseConfig();
  const { configCopy } = useConfig();
  const [timer, setTimer] = useState(
    configCopy.time ? configCopy.time * 60 : null
  );
  // const [timer, setTimer] = useState(0.2 * 100);
  const [showHideElement, setShowHideElement] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer <= 0) {
          setShowHideElement(false);
          clearInterval(interval);
        }
        return prevTimer - 1;
      });
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (timer <= 0) {
      localStorage.setItem("MemoverseDownSale", false);
      setDownSaleActive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer]);

  const contextValue = {
    timer,
    showHideElement,
  };

  return (
    <TimerContext.Provider value={contextValue}>
      {children}
    </TimerContext.Provider>
  );
};
