import React from "react";

import styles from "./KickstarterStepStyle.module.css";

const KickstarterStep = ({ id, step, handleNextStep }) => {

  return (
    <div className={styles.container}>
      <span className={styles.title}>{step.title}</span>
      <span className={styles.subTitle}>{step.subTitle}</span>
      <div className={styles.mainContainer}>
        <span className={styles.topText}>{step.mainText[0]}</span>
        <span className={styles.mainText}>{step.mainText[1]}</span>
      </div>
      <span className={styles.imgHeader}>{step.infoText}</span>
      <div className={styles.imgContainer}>
        <img
          src="../../img/quizTheTimes.png"
          alt="TheTimes"
          className={styles.imgTheTimes}
        />
        <img src="../../img/quizBBC.png" alt="BBC" className={styles.imgBbc} />
        <img
          src="../../img/quizTheTelegraph.png"
          alt="TheTelegraph"
          className={styles.imgTheTelegraph}
        />
        <img
          src="../../img/quizTechCrunch.png"
          alt="TechCranch"
          className={styles.imgTechCranch}
        />
        <img
          src="../../img/quizTheNewYorkTimes.png"
          alt="TheNewYorkTimes"
          className={styles.imgTheNewYorkTimes}
        />
        <img
          src="../../img/quizIndependent.png"
          alt="Independent"
          className={styles.imgIndependent}
        />
      </div>
      <button
        onClick={() => handleNextStep({ id, type: "kickstarter" })}
        className={styles.button}
      >
        <span className={styles.buttonText}>Continue</span>
      </button>
    </div>
  );
};

export default KickstarterStep;
