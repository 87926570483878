import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";

import ElementsSlider from "./ElementsSlider/ElementsSlider";

import styles from "./WithinMemoryOS.module.css";

const WithinMemoryOS = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "WithinMemoryOS");

  const imgName = [
    "withinBlockUniversity.png",
    "withinBlockStar.png",
    "withinBlockComment.png",
    "withinBlockHeart.png",
    "withinBlockJug.png",
    "withinBlockBank.png",
    "withinBlockTimer.png",
  ];

  return (
    <div className={styles.container}>
      <div className={styles.headText}>{data.headerText}</div>

      <div className={styles.firstContainer}>
        {data.steps.map((step, index) => {
          return (
            <div key={index} className={styles.firstStepElement}>
              <div className={styles.WithinMemoryOSCheck} />
              <div className={styles.WithinMemoryOSText}>{step}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.hrLine} />
      <div className={styles.secondContainer}>
        {data.secondSteps.map((text, index) => {
          return (
            <div key={index} className={styles.secondElement}>
              <img
                src={`./img/${imgName[index]}`}
                alt={`img${index}`}
                className={styles.secondImg}
              />
              {text.length > 1 ? (
                <>
                  <span className={styles.solidText}>{text[0]} </span>
                  <span className={`${styles.secondStepText} ${styles.stepTextMargin}`}>{text[1]}</span>
                </>
              ) : (
                <span className={styles.secondStepText}>{text[0]}</span>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.hrLine} />
      <ElementsSlider data={data} />
    </div>
  );
};

export default WithinMemoryOS;
