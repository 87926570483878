import React, { useEffect, useState } from "react";
import { useButtonRef } from "../../../context/ButtonRefContext";

import styles from "./TopInfoStyle.module.css";

const TopInfo = ({ data }) => {
  const { buttonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  const [incStep] = useState(data.accounts.incrementStep);

  const [dataWaitlist, setDataWaitlist] = useState(
    parseInt(data.accounts.waitlist)
  );
  console.log(dataWaitlist);

  const upWaitlist = () => {
    setDataWaitlist((prevData) => prevData + 1);
    setTimeout(upWaitlist, Math.floor(Math.random() * (incStep - 5000)) + 5000);
  };

  useEffect(() => {
    upWaitlist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.headerButton} onClick={handleButtonClick}>
        <span className={styles.headerBtnText}>{data.headerBtn}</span>
      </div>
      <span className={styles.header}>{data.header}</span>
      <div className={styles.containerStatistic}>
        <div className={styles.infoContainer}>
          <span className={styles.topText}>{data.accounts.quantity}</span>
          <span className={styles.mainTextInfo}>
            {data.accounts.accountsText}
          </span>
        </div>
        <div className={styles.infoContainer}>
          {/* <span className={styles.topText}>{dataWaitlist}</span> */}
          <span className={styles.topText}>{data.accounts.waitlist}</span>
          <span
            className={styles.mainTextInfo}
            style={{
              width: "200px",
            }}
          >
            {data.peopleWaitlist}
          </span>
        </div>
      </div>
      <div className={styles.mainTextContainer}>
        {data.mainText.map((text, index) => (
          <span
            key={index}
            className={index % 2 === 0 ? styles.mainText : styles.mainTextBold}
          >
            {text}
          </span>
        ))}
      </div>
      <div className={styles.mainBtnContainer}>
        <div className={styles.button} onClick={handleButtonClick}>
          <div className={styles.btnTextContainer}>
            <span className={styles.buttonText}>{data.buttonText}</span>
          </div>
          <div className={styles.starOneL} />
          <div className={styles.starTwoL} />
          <div className={styles.starThreeL} />
          <div className={styles.starOneR} />
          <div className={styles.starTwoR} />
          <div className={styles.starThreeR} />
        </div>
        <a
          href={data.underBtnLink + "?utm_source=reserve_redirect"}
          className={styles.underBtnText}
          target="_blank"
          rel="noopener noreferrer"
        >
          {data.underBtnText}
        </a>
      </div>
      <span className={styles.footerText}>{data.footerText}</span>
    </div>
  );
};

export default TopInfo;
