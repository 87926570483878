import React, { useEffect, useState } from "react";

import { useVWOContext } from "../../../../../../context/vwoContext";
import { useRadioInput } from "../../../../../../context/RadioInputContext";
import { useTimer } from "../../../../../../context/TimerContext";
import { useABtest } from "../../../../../../context/ABTestContext";

import Loading from "../../../../ABtestPlanPage/Loading/Loading";
import styles from "./FooterTextContainerStyle.module.css";

const FooterTextContainer = ({ data }) => {
  const { variantNumber } = useVWOContext();

  const { isActive } = useABtest();

  const { selectedOption } = useRadioInput();
  const { selectedABOption } = useABtest();
  const { showHideElement } = useTimer();

  const [isLoading, setIsLoading] = useState(true);

  const [variantOption, setVariantOption] = useState(null);

  const [textOption, setTextOption] = useState(0);

  const [currentRenderData, setCurrentRenderData] = useState("");

  const setTextData = (index) => {
    if (index === 0) {
      setTextOption(selectedOption);
    } else if (index === 1) {
      setTextOption(selectedABOption);
    }
  };

  const findDataInfo = (index, data) =>
    index >= 0 && index < data.length ? data[index] : "";

  useEffect(() => {
    if (variantNumber === null) {
      return;
    }
    if (variantNumber !== null) {
      if (variantNumber === 1) {
        setVariantOption(0);
        setTextData(variantOption);
      } else if (variantNumber === 2) {
        setVariantOption(1);
        setTextData(variantOption);
      }
      if (variantOption !== null) {
        setIsLoading(false);
      }
      if (showHideElement && variantOption !== null && textOption !== null) {
        setCurrentRenderData(
          findDataInfo(textOption, data.textInfoWithTimer[variantOption])
        );
      } else if (
        !showHideElement &&
        variantOption !== null &&
        textOption !== null
      ) {
        setCurrentRenderData(
          findDataInfo(textOption, data.textInfoWithoutTimer[variantOption])
        );
      }
      if (isActive) {
        setCurrentRenderData(
          findDataInfo(2, data.textInfoWithTimer[variantOption])
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    variantNumber,
    selectedOption,
    selectedABOption,
    variantOption,
    showHideElement,
    textOption,
    isActive,
  ]);

  return (
    <div className={styles.Footer}>
      {isLoading ? (
        <Loading />
      ) : (
        <span className={styles.FooterText}>{currentRenderData}</span>
      )}
    </div>
  );
};

export default FooterTextContainer;
