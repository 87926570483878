import React from "react";
import VariantB from "./RenderVariants/VariantB";

const GraySafeBlock = () => {
  return (
    <div>
      <VariantB />
    </div>
  );
};

export default GraySafeBlock;
