import styled from "styled-components"

export const breakpoints = {
  desktop: 1100,
  tablet: 800,
}

export const mediaQueries = {
  desktop: `screen and (min-width: ${breakpoints.desktop}px)`,
  tablet: `screen and (min-width: ${breakpoints.tablet}px)`,
}

export const MobileOnly = styled.div`
  @media ${mediaQueries.desktop} {
    display: none;
  }
`

export const DesktopOnly = styled.div`
  display: none;
  @media ${mediaQueries.desktop} {
    display: block;
  }
`