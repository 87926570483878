import React, { useEffect, useState } from "react";
import { useConfig } from "../../../../../context/ConfigDataContext";

import Loading from "../../Loading/Loading";

import styles from "../PleaseNoteStyle.module.css";
import { useDiscount } from "../../../../../context/ApplyDiscountContext";

const VariantB = ({ textData }) => {
  const { findDataComponent } = useConfig();

  const { selectedDiscountOption } = useDiscount();

  const data = findDataComponent(2, "PleaseNote");

  const [isLoading, setIsLoading] = useState(true);

  const [currentRenderData, setCurrentRenderData] = useState([]);

  useEffect(() => {
    setIsLoading(false);
    setCurrentRenderData(textData.textWithDiscount[0][selectedDiscountOption]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textData, selectedDiscountOption]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <span className={styles.mainText}>
          <span className={styles.hightText}>{data.textHight}</span>
          {isLoading ? (
            <Loading />
          ) : (
            <span>
              {currentRenderData[0]}{" "}
              <a
                href={data.linkSubscriptionTerms}
                className={styles.subscrText}
                target="blank"
              >
                {data.textLink}
              </a>
              {currentRenderData[1]}
            </span>
          )}
        </span>
      )}
    </>
  );
};

export default VariantB;
