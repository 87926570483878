import React, { useEffect } from "react";
import { useConfig } from "../../context/ConfigDataContext";
// import { ButtonRefProvider } from "../../context/ButtonRefContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useControlPaymentPage } from "../../context/ControlPaymentPageContext";
import { useFirebaseConfig } from "../../context/FirebaseConfigContext";
import Header from "./Header/Header";
import TopInfo from "./TopInfo/TopInfo";
import MainInfo from "./MainInfo/MainInfo";
import GarantyInfo from "./GarantyInfo/GarantyInfo";
import Footer from "./Footer/Footer";
import PaymentBlock from "./PaymentBlock/PaymentBlock";

import styles from "./BlackFridayStyle.module.css";

const BlackFriday = () => {
  const navigate = useNavigate();
  const { redirectUrl } = useFirebaseConfig();

  const { checkQuiz } = useControlPaymentPage();

  useEffect(() => {
    checkQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { findDataComponent } = useConfig();
  const data = findDataComponent(1, "BlackFriday");
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/reserve") {
      navigate(redirectUrl ? redirectUrl : "/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    // <ButtonRefProvider>
      <div className={styles.bfContainer}>
        <Header data={data.Header} />
        <TopInfo data={data.TopInfo} />
        <MainInfo data={data.MainInfo} />
        <GarantyInfo data={data.GarantyInfo} />
        <Footer data={data.Footer} />
        <div>
          <PaymentBlock dataInfo={data.UserNameEmailStep} />
        </div>
      </div>
    // </ButtonRefProvider>
  );
};

export default BlackFriday;
