import React from "react";

import styles from "./NewsContainerStyle.module.css";

const NewsContainer = ({ featuredText }) => {
  return (
    <div className={styles.container}>
      <span className={styles.featuredText}>{featuredText}</span>
      <div className={styles.imgContainer}>
        <img
          src="/img/independent.png"
          alt="independent"
          className={styles.img}
        />
        <img src="/img/bbc.png" alt="bbc" className={styles.img} />

        <img src="/img/the_times.png" alt="the_times" className={styles.img} />
        <img
          src="/img/the_new_york_times.png"
          alt="the_new_york_times"
          className={styles.img}
        />
        <img
          src="/img/the_telegraph.png"
          alt="the_telegraph"
          className={styles.img}
        />
      </div>
    </div>
  );
};

export default NewsContainer;
