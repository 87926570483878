import React, { createContext, useContext, useEffect, useState } from "react";
import { useFirebaseConfig } from "./FirebaseConfigContext";
import LZUTF8 from "lzutf8";

const BFContext = createContext(null);

export const useBlackFriday = () => useContext(BFContext);

export const BlackFridayProvider = ({ children }) => {
  const { bfPaymentPlan } = useFirebaseConfig();

  const [option, setOption] = useState(1);

  const [selectedOptionBF, setSelectedOption] = useState(bfPaymentPlan.planOne);

  useEffect(() => {
    if (option === 1) {
      setSelectedOption(bfPaymentPlan.planOne);
    } else if (option === 2) {
      setSelectedOption(bfPaymentPlan.planTwo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [option]);

  const [extractedValue, setExtractedValue] = useState(null);

  const [dataUser, setDataUser] = useState({});

  useEffect(() => {
    const currentUrl = window.location.href;

    const payloadIndex = currentUrl.indexOf("payload=");

    if (payloadIndex !== -1) {
      const payloadSubstring = currentUrl
        .substring(payloadIndex + "payload=".length)
        .split("=")[0];

      setExtractedValue(payloadSubstring);
    }

    if (extractedValue) {
      const decompressedPayload = LZUTF8.decompress(extractedValue, {
        inputEncoding: "Base64",
      });
      const [userName, userEmail] = decompressedPayload.split("~");

      const userData = {
        userName,
        userEmail,
      };
      localStorage.setItem("MemoverseUserData", JSON.stringify(userData));
      setDataUser(userData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractedValue]);

  const contextValues = {
    dataUser,
    selectedOptionBF,
    option,
    setOption,
  };

  return (
    <BFContext.Provider value={contextValues}>{children}</BFContext.Provider>
  );
};
