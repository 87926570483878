import React from "react";

import { Step, Button } from "../../styles";

import styles from "./TrialStepStyle.module.css";

const TrialStep = ({ id, step, handleNextStep }) => {
  return (
    <Step>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <span className={styles.header}>{step.title}</span>
          <div className={styles.secureContainer}>
            <img
              src="../img/quizTrialStepImg/secureImg.png"
              alt="secureImg"
              className={styles.secureImg}
            />

            <span className={styles.secureText}>{step.secureText}</span>
          </div>
          <div className={styles.mainContainer}>
            <div className={styles.imgContainer}>
              <img
                src={`../img/quizTrialStepImg/frame0.png`}
                alt={`mainImg0`}
                className={styles.roundFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame1.png`}
                alt={`mainImg0`}
                className={styles.stripFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame2.png`}
                alt={`mainImg0`}
                className={styles.roundFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame3.png`}
                alt={`mainImg0`}
                className={styles.partStripOneFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame4.png`}
                alt={`mainImg0`}
                className={styles.partStripTwoFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame5.png`}
                alt={`mainImg0`}
                className={styles.roundFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame6.png`}
                alt={`mainImg0`}
                className={styles.stripFrame}
              />
              <img
                src={`../img/quizTrialStepImg/frame7.png`}
                alt={`mainImg0`}
                className={styles.roundFrame}
              />
            </div>
            <div className={styles.mainPart}>
              {step.steps.map((element, index) => {
                return (
                  <div key={index} className={styles.mainTextPart}>
                    <span className={styles.partHeader}>{element.header}</span>
                    <span className={styles.partMain}>{element.main}</span>
                  </div>
                );
              })}
            </div>
          </div>
          <Button
            $isActive={true}
            onClick={() => handleNextStep({ id, type: "trialStep" })}
          >
            Continue
          </Button>
          <div className={styles.footer}>
            <div className={styles.footerPolicyGroup}>
              <a
                href="https://memoryos.com/privacy-policy"
                className={styles.footerContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://memoryos.com/terms"
                className={styles.footerContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
              <a
                href="https://memoryos.com/terms"
                className={styles.footerContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Subscription Policy
              </a>
            </div>
            <span className={styles.footerCopyrightText}>
              © Copyright 2024 Encoder Inc., Delaware, the USA
            </span>
          </div>
        </div>
      </div>
    </Step>
  );
};

export default TrialStep;
