import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import CryptoJS from "crypto-js";
import * as amplitude from "@amplitude/analytics-browser";

import { useFirebaseConfig } from "./FirebaseConfigContext";
import { useEnvContext } from "./EnvironmentContext";
import { useVWOContext } from "./vwoContext";

const EventContext = createContext();

export const useEvent = () => useContext(EventContext);

export const EventProvider = ({ children }) => {
  const {
    appData,
    analyticsFirebase,
    fbEvent,
    apiGeoUrl,
    emailsToDisableAnalyticsEvent,
    abTestCampaignName,
    abTestStartCampaignDate,
  } = useFirebaseConfig();

  const { isProduction } = useEnvContext();

  const { variantNumber } = useVWOContext();

  const [userData, setSnaptrUserData] = useState(
    JSON.parse(localStorage.getItem("MemoverseUserData"))
  );
  const [userGeoData, setUserGeoData] = useState(
    JSON.parse(localStorage.getItem("MemoverseGeoData"))
  );

  const generateContentId = () => {
    const now = new Date();

    const day = String(now.getDate()).padStart(2, "0");
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const year = now.getFullYear();

    const timestamp = `${day}${month}${year}`;

    const randomNum = Math.floor(Math.random() * 1000000);

    const contentId = `${timestamp}${randomNum.toString().padStart(6, "0")}`;

    return contentId;
  };

  const hashEmail = (email) => {
    const lowercaseEmail = email.toLowerCase();
    const hashedEmail = CryptoJS.SHA256(lowercaseEmail).toString();
    return hashedEmail;
  };

  const getEmailFromLocalStorage = (emailsToDisableAnalyticsEvent) => {
    const userData = JSON.parse(localStorage.getItem("MemoverseUserData"));
    const quizData = JSON.parse(localStorage.getItem("MemoverseQuizData"));

    const findEmailInQuizData = (data) => {
      const formItem = data.find(
        (item) =>
          item.type === "form" && item.formValues && item.formValues.Email
      );
      return formItem ? formItem.formValues.Email : null;
    };

    const checkEmailInDisableList = (email) => {
      return !emailsToDisableAnalyticsEvent.includes(email.toLowerCase());
    };

    if (userData && userData.userEmail) {
      return checkEmailInDisableList(userData.userEmail) ? true : false;
    }

    if (quizData) {
      const quizEmail = findEmailInQuizData(quizData);
      if (quizEmail) {
        return checkEmailInDisableList(quizEmail) ? true : false;
      }
    }

    return true;
  };

  const [isAnalyticsAllowed, setIsAnalyticsAllowed] = useState(null);

  const checkAnalyticsConditions = useCallback(() => {
    return (
      isProduction &&
      appData.REACT_APP_MARKETING_TELEMETRY !== 0 &&
      getEmailFromLocalStorage(emailsToDisableAnalyticsEvent)
    );
  }, [
    isProduction,
    appData.REACT_APP_MARKETING_TELEMETRY,
    emailsToDisableAnalyticsEvent,
  ]);

  useEffect(() => {
    const updateAnalyticsAllowed = () => {
      const allowed = checkAnalyticsConditions();
      setIsAnalyticsAllowed(allowed);
    };
    updateAnalyticsAllowed();

    const interval = setInterval(updateAnalyticsAllowed, 60000);
    return () => clearInterval(interval);
  }, [checkAnalyticsConditions]);

  const getGoogleClientID = () => {
    return window.gtag("get", "G-4XWM51XKY7", "client_id", function (clientId) {
      console.log("clientID: ", clientId);
    });
  };

  useEffect(() => {
    getGoogleClientID();
  }, []);

  // eslint-disable-next-line no-unused-vars
  const userIdentifyEvent = (userInfo) => {
    // const userQuizData = JSON.parse(localStorage.getItem("MemoverseQuizData"))

    const identifyEvent = new amplitude.Identify();

    const identifyOptions = {
      userData: userInfo,
      abTestName: abTestCampaignName,
      abTestStartDate: abTestStartCampaignDate,
      userABtestNumber: variantNumber,
    };
    identifyEvent.set("userIdentify", identifyOptions);
    amplitude.identify(identifyEvent);

    console.log("userIdentify", identifyOptions);
  };

  // useEffect(() => {
  //   if (variantNumber !== null) {
  //     userIdentifyEvent(userData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [variantNumber]);

  const analyticsEvent = useCallback(
    (eventName, eventParams) => {
      if (!isAnalyticsAllowed) {
        console.warn(`Analytics events are currently blocked`);
        return;
      }

      if (appData.REACT_APP_MARKETING_TELEMETRY === 0) {
        console.warn(`Marketing events tracking disabled`);
        return;
      }

      if (eventParams) {
        console.log(
          `Processing analytics event. Name: ${eventName} Params: ${JSON.stringify(
            eventParams
          )}`
        );
      } else {
        console.log(`Processing analytics event. Name: ${eventName}`);
      }

      switch (eventName) {
        case "landingPageView":
          if (window.fbq !== null) {
            window.fbq("trackCustom", "landingPageView");
          }

          if (window.ttq !== null) {
            window.ttq.track("landingPageView", {
              contents: [
                {
                  content_id: generateContentId(),
                  content_name: "landingPageView",
                },
              ],
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("landing_page_view", {
              event_name: "landingPageView",
            });
          }

          if (window.snaptr !== null) window.snaptr("track", "PAGE_VIEW");

          break;

        case "getStartedClicked":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("click", {
              event_category: "click",
              event_label: "get_started_clicked",
            });
          }

          if (window.fbq !== null) window.fbq("track", "AddToWishlist");

          if (window.snaptr !== null) window.snaptr("track", "ADD_TO_WISHLIST");

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("get_started_clicked", {
              event_category: "click",
              event_label: "get_started_clicked",
            });
          }

          break;

        case "pageView":
          if (window.fbq !== null) {
            window.fbq("track", "PageView");
            window.fbq("track", "ViewContent");
          }

          if (window.ttq !== null) {
            window.ttq.track("ViewContent", {
              contents: [
                {
                  content_id: generateContentId(),
                  content_name: "ViewContent",
                },
              ],
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("page_view", {
              event_name: "ViewContent",
            });
          }

          if (window.snaptr !== null) window.snaptr("track", "PAGE_VIEW");

          break;

        case "quizPageView":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("quiz_page_view", {
              event_category: "quiz_page_view",
              event_label: "quiz_page_view",
              quiz_number: eventParams,
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("quiz_page_view", {
              event_category: "quiz_page_view",
              quiz_number: eventParams,
            });
          }

          break;

        /////////////////////////////////////// out of date
        case "salesCopyExpanded":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("click", {
              event_category: "click",
              event_label: "sales_copy_expanded",
            });
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentSalesCopy");

          if (window.snaptr !== null) window.snaptr("track", "VIEW_LIST");

          break;
        /////////////////////////////////////////////////////

        case "nameFormSubmit":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("name_form_submit", {
              event_category: "first_name",
              event_label: eventParams,
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("name_form_submit", {
              event_category: "first_name",
              event_label: eventParams,
            });
          }

          if (window.fbq !== null)
            window.fbq("trackCustom", "ViewContentContact");

          if (window.snaptr !== null) window.snaptr("track", "CUSTOM_EVENT_1");

          break;

        case "leadFormSubmit":
          if (analyticsFirebase !== null)
            analyticsFirebase.logEvent("lead_form_submit", eventParams);

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("lead_form_submit", eventParams);
          }

          const hashedEventParams = { ...eventParams };
          if (hashedEventParams.event_user_email) {
            hashedEventParams.event_user_email = hashEmail(
              hashedEventParams.event_user_email
            );
          }
          if (window.fbq !== null) {
            window.fbq("track", "Lead", { leadEventData: hashedEventParams });
            window.fbq("trackCustom", "ViewContentContact");
          }

          if (window.ttq !== null) {
            window.ttq.identify({
              email: hashedEventParams.event_user_email,
              name: hashedEventParams.event_user_name,
            });
          }

          if (window.snaptr !== null) {
            window.snaptr("track", "CUSTOM_EVENT_2");
          }

          break;

        case "conversion":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("conversion", {
              send_to: "AW-666333408/I4bYCIa4_MMBEODh3b0C",
              // send_to: "AW-666333408/VFZbCOTgjvoBEODh3b0C",
            });
          }

          if (window.snaptr !== null) window.snaptr("track", "CUSTOM_EVENT_3");

          break;

        case "addToCart":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("add_to_cart", {
              event_category: "add_to_cart",
              event_label: "add_to_cart",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("add_to_cart", {
              event_category: "add_to_cart",
              event_label: "add_to_cart",
            });
          }

          if (window.ttq !== null) {
            window.ttq.track("AddToCart", {
              contents: [
                {
                  content_id: generateContentId(),
                  content_name: "add_to_cart",
                },
              ],
            });
          }

          if (window.fbq !== null) window.fbq("track", "AddToCart");

          if (window.snaptr !== null) window.snaptr("track", "ADD_CART");

          break;

        case "beginCheckout":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("begin_checkout", {
              event_category: "begin_checkout",
              event_label: "begin_checkout",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("begin_checkout", {
              event_category: "begin_checkout",
              event_label: "begin_checkout",
            });
          }

          if (window.fbq !== null) window.fbq("track", "InitiateCheckout");

          if (window.snaptr !== null) window.snaptr("track", "START_CHECKOUT");

          if (window.ttq !== null) {
            window.ttq.track("InitiateCheckout", {
              value: 0,
              currency: "USD",
              contents: [
                {
                  content_id: generateContentId(),
                  content_type: "begin_checkout",
                },
              ],
            });
          }

          break;

        case "addPaymentInfo":
          if (analyticsFirebase !== null) {
            analyticsFirebase.logEvent("add_payment_info", {
              event_category: "add_payment_info",
              event_label: "add_payment_info",
            });
          }

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("add_payment_info", {
              event_category: "add_payment_info",
              event_label: "add_payment_info",
            });
          }

          if (window.fbq !== null) window.fbq("track", "AddPaymentInfo");

          if (window.snaptr !== null) window.snaptr("track", "ADD_BILLING");

          break;

        case "purchase":
          if (analyticsFirebase !== null)
            analyticsFirebase.logEvent("purchase", eventParams);

          if (
            typeof amplitude !== "undefined" &&
            typeof amplitude.track === "function"
          ) {
            amplitude.track("purchase", eventParams);
          }

          if (window.fbq !== null && fbEvent) {
            const metaParams = {
              value: eventParams.value.toString(),
              currency: eventParams.currency,
              eventID: eventParams.meta_capi_event_id,
            };
            window.fbq("track", "Purchase", metaParams);
          }

          if (window.snaptr !== null) {
            const snapParams = {
              description: eventParams.description,
              item_category: "Subscription",
              price: eventParams.value,
              currency: "USD",
              number_items: 1,
              success: 1,
              client_dedup_id: eventParams.meta_capi_event_id,
            };
            window.snaptr("track", "PURCHASE", snapParams);
          }

          if (window.ttq !== null) {
            window.ttq.track("CompletePayment", {
              value: Number(eventParams.value),
              currency: "USD",
              contents: [
                {
                  content_id: (eventParams.meta_capi_event_id || "").toString(),
                  content_type: "Subscription",
                  content_name: eventParams.description,
                  price: Number(eventParams.value),
                },
              ],
              status: "Fulfilled",
            });
          }

          break;

        default:
          console.error(`Analytics event not handled: ${eventName}`);
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAnalyticsAllowed, analyticsFirebase, fbEvent]
  );

  const initSnaptr = async () => {
    if (!isAnalyticsAllowed) {
      console.warn(`Init Snaptr are currently blocked`);
      return;
    }
    if (userGeoData === null) {
      console.log("fetch geo data");
      try {
        const response = await fetch(apiGeoUrl);
        if (response.ok) {
          const geoData = await response.json();
          localStorage.setItem("MemoverseGeoData", JSON.stringify(geoData));
          setUserGeoData(geoData);
        } else {
          console.error("Error response geo:", response.status);
        }
      } catch (error) {
        console.error("Error fetch geo:", error);
      }
    } else if (userGeoData !== null) {
      console.log("localStorage geo data");
      const hashedEmail =
        userData?.userEmail !== undefined
          ? await shaEmailHash(userData.userEmail)
          : null;

      const snaptrInitData = {
        user_email: userData?.userEmail ?? null,
        user_hashed_email: hashedEmail,
        firstname: userData?.userName ?? null,
        ip_address: userGeoData.ip,
        geo_city: userGeoData.city,
        geo_region: userGeoData.state,
        geo_postal_code: userGeoData.zip,
        geo_country: userGeoData.country,
      };

      const cleanedSnaptrData = removeUndefOrNullFields(snaptrInitData);
      if (userData === null) {
        console.log("Processing init snaptr event: send only geo data");
      } else {
        console.log("Processing init snaptr event: send geo + user data");
      }
      window.snaptr(
        "init",
        "828579a5-bbbe-4d06-bc6a-53cc466efb08",
        cleanedSnaptrData
      );
    }
  };
  useEffect(() => {
    if (isAnalyticsAllowed !== null) {
      initSnaptr();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, userGeoData, isAnalyticsAllowed]);

  const removeUndefOrNullFields = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj[key] !== undefined && obj[key] !== null) {
        newObj[key] = obj[key];
      }
    }
    return newObj;
  };

  const shaEmailHash = async (email) => {
    const msgBuffer = new TextEncoder().encode(email.toLowerCase());
    const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  };

  const contextValue = {
    analyticsEvent,
    setSnaptrUserData,
  };

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
};
