import React, { useState, useEffect } from "react";
import { useConfig } from "../../../../../context/ConfigDataContext";
import { useABtest } from "../../../../../context/ABTestContext";
import { useVWOContext } from "../../../../../context/vwoContext";
import { useRadioInput } from "../../../../../context/RadioInputContext";
import { useTimer } from "../../../../../context/TimerContext";

import Loading from "../../../PaidTrialsAllSubs/Loading/Loading";

import styles from "../PleaseNoteStyle.module.css";

const VariantA = () => {
  const { findDataComponent } = useConfig();
  const { isActive, textABToPlan } = useABtest();
  const { variantNumber } = useVWOContext();
  const { textToPlan } = useRadioInput();
  const { showHideElement } = useTimer();

  const data = findDataComponent(2, "PleaseNote");

  const [renderData, setRenderData] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const findSplitText = (textData) => {
    const mainTextStyles = (
      <span>
        {textData[0]}{" "}
        <a
          href={data.linkSubscriptionTerms}
          className={styles.subscrText}
          target="blank"
        >
          {data.textLink}
        </a>
        {textData[1]}
      </span>
    );
    setRenderData(mainTextStyles);
  };
  const planActions = {
    6: {
      withDiscount: () => findSplitText(data.secondTextMainWithDiscount[0][0]),
      withoutDiscount: () =>
        findSplitText(data.secondTextMainWithoutDiscount[0][0]),
    },
    3: {
      withDiscount: () => findSplitText(data.secondTextMainWithDiscount[0][1]),
      withoutDiscount: () =>
        findSplitText(data.secondTextMainWithoutDiscount[0][1]),
    },
    1: {
      withDiscount: () => findSplitText(data.secondTextMainWithDiscount[0][2]),
      withoutDiscount: () =>
        findSplitText(data.secondTextMainWithoutDiscount[0][2]),
    },
    Annual: {
      withDiscount: (isActive) =>
        findSplitText(
          isActive === true
            ? data.secondTextMainWithDiscount[1][2]
            : data.secondTextMainWithDiscount[1][0]
        ),
      withoutDiscount: () =>
        findSplitText(data.secondTextMainWithoutDiscount[1][0]),
    },
    Monthly: {
      withDiscount: () => findSplitText(data.secondTextMainWithDiscount[1][1]),
      withoutDiscount: () =>
        findSplitText(data.secondTextMainWithoutDiscount[1][1]),
    },
  };

  const executeAction = (value, isActive) => {
    if (planActions[value]) {
      if (showHideElement) {
        planActions[value].withDiscount(isActive);
      } else {
        planActions[value].withoutDiscount();
      }
    }
  };

  useEffect(() => {
    if (variantNumber === 1 && textToPlan !== undefined) {
      executeAction(textToPlan.month, isActive);
      executeAction(textToPlan.days, isActive);
      setIsLoading(false);
    } else if (variantNumber === 2 && textABToPlan !== undefined) {
      executeAction(textABToPlan.optionPlanName, isActive);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToPlan, textABToPlan, variantNumber, isActive]);

  if (!data || !textToPlan || !variantNumber) {
    return <Loading />;
  }

  return (
      <span className={styles.mainText}>
        <span className={styles.hightText}>{data.textHight}</span>
        {isLoading ? <Loading /> : renderData}
      </span>
  );
};

export default VariantA;
