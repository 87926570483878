import React, { useState } from "react";

import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./AnswerQuestions.module.css";

const AnswerQuestions = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "AnswerQuestions");

  const Accordion = ({ title, body }) => {
    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(!isOpen);
    };
    return (
      <div className={styles.elementContainer}>
        <div className={styles.accordionTitleCont} onClick={handleOpen}>
          <span className={styles.title}>{title}</span>
          {isOpen ? (
            <img
              src="./img/icon_minus.svg"
              alt="Accordion"
              className={styles.accordionImg}
            />
          ) : (
            <img
              src="./img/icon_plus.svg"
              alt="Accordion"
              className={styles.accordionImg}
            />
          )}
        </div>
        {isOpen ? (
          <div className={styles.accordionBody}>
            <span className={styles.mainText}>{body}</span>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerText}>{data.headerText}</div>
      {data.questions.map((questElem, index) => {
        return (
          <Accordion
            key={index}
            title={questElem.title}
            body={questElem.body}
          />
        );
      })}
    </div>
  );
};

export default AnswerQuestions;
