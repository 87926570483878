import React from "react";

import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";

import styles from "./DownsaleFlow.module.css";


const DownsaleFlow = () => {
  const { configCopy, specialDiscount, findDataComponent } = useConfig();
  const { showHideElement } = useTimer();

  const data = findDataComponent(1, "DownsaleFlow")

  return (
    <>
    { showHideElement && specialDiscount ? (
        <div className={styles.DownsaleFlowContainer}>
          <p className={styles.DownsaleFlowText}>
           {data.textBefore}{" "}
            <span className={styles.DownsaleFlowOldDiscount}>
              {`${configCopy.discountPercent}%`}
            </span>{" "}
            <span className={styles.DownsaleFlowNewDiscount}>
              {`${configCopy.specialDiscountPercent}%`}
            </span>{" "}
            {data.textAfter}
          </p>
        </div>
      ) : (
        null
      )}
      
    </>
  );
};

export default DownsaleFlow;
