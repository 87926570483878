import React from "react";

import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./MoreEfficientlyStyle.module.css";


const MoreEfficiently = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "MoreEfficiently");

  return (
    <div className={styles.container}>
      <div className={styles.imgsContainer}>
        <img
          src="./img/iphone_full.png"
          alt="iphoneFull"
          className={styles.iphoneFull}
        />
        <img
          src="./img/iphone_shadow.png"
          alt="iphoneShadow"
          className={styles.iphoneShadow}
        />
        <div className={styles.imgTextCont}>
          <img
            src="./img/gamepad.png"
            alt="gamepad"
            className={styles.gamepad}
          />
          <span className={styles.imgText}>{data.imgText}</span>
        </div>
      </div>
      <div className={styles.textCont}>
        <span className={styles.headerText}>{data.headerText}</span>
        <span className={styles.mainText}>{data.mainText}</span>
      </div>
    </div>
  );
};

export default MoreEfficiently;
