import React from "react";
// import ReactPlayer from "react-player/youtube";
import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./WhatIsMemoryOS.module.css";
import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";

const WhatIsMemoryOS = () => {
  const { findDataComponent } = useConfig();
  const data = findDataComponent(1,"WhatIsMemoryOS");

  return (
    <div className={styles.container}>
      <span className={styles.headText}>{data.headerText}</span>
      {/* <video
        controls
        poster={data.linkPoster}
        width="100%"
        preload="metadata"
        src={data.linkVideo}
        type="video/webm"
        className={styles.videoContainer}
      /> */}
      <div
      style={{
        width: "100%",
        borderRadius: "16px",
        overflow: "hidden",
        marginBottom: "10px",
      }}
      >
        {/* <ReactPlayer
          url={data.linkVideo}
          light={data.linkPoster || ""}
          playing={false}
          width="100%"
          controls={false}
        /> */}
        <VideoPlayer
          videoId={data.linkVideo}
          thumbnail={data.linkPoster || ""}
        />
      </div>
    </div>
  );
};

export default WhatIsMemoryOS;
