import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import VariantB from "./DesignRender/VariantB";

import styles from "./WithinMemoryOS.module.css";

const WithinMemoryOS = () => {
  const { discountPageData } = useFirebaseConfig();
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "WithinMemoryOS");

  return (
    <div className={styles.container}>
      <div className={styles.headText}>{data.headerText}</div>

      <div className={styles.firstContainer}>
        {data.steps.map((step, index) => {
          return (
            <div key={index} className={styles.firstStepElement}>
              <div className={styles.WithinMemoryOSCheck} />
              <div className={styles.WithinMemoryOSText}>{step}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.hrLine} />
      <VariantB data={discountPageData.planAchivment} />
    </div>
  );
};

export default WithinMemoryOS;
