import React, { createContext, useContext, useState } from "react";
import { useBlackFriday } from "./BFContext";
import { useFirebaseConfig } from "./FirebaseConfigContext";

const PaymentContext = createContext({});

export const usePaymentBlock = () => useContext(PaymentContext);

export const PaymentProvider = ({ children }) => {
  const { appData } = useFirebaseConfig();
  const { selectedOptionBF, dataUser, option, setOption } = useBlackFriday();

  const [startNameEmailForm, setStartNameEmailForm] = useState(false);
  const [startPaymentForm, setStartPaymentForm] = useState(false);
  const [startActiveSubscForm, setStartActiveSubscForm] = useState(false);

  const [loader, setLoader] = useState(false);

  const rewriteEmail = (email) => {
    const savedData = JSON.parse(localStorage.getItem("MemoverseUserData"));
    if (savedData !== null) {
      savedData.userEmail = email;

      localStorage.setItem("MemoverseUserData", JSON.stringify(savedData));

      const timerId = setTimeout(() => {
        setLoader(false);
        loadingForm("StartPaymentForm");
      }, 1000);
      return () => clearTimeout(timerId);
    }
  };
  //   ?payload=T2xla3NhbmRyfm/ICi5tdXJhbkBtZW1vcnlvcy5jb20=
  const setUserData = (data) => {
    localStorage.setItem("MemoverseUserData", JSON.stringify(data));
    const timerId = setTimeout(() => {
      setLoader(false);
      loadingForm("StartPaymentForm");
    }, 1000);
    return () => clearTimeout(timerId);
  };

  const loadingForm = async (key) => {
    switch (key) {
      case "StartPaymentForm":
        setStartPaymentForm(true);
        break;
      case "StartNameEmailForm":
        setStartNameEmailForm(true);
        break;
      case "StartActiveSubscForm":
        setStartActiveSubscForm(true);
        break;
      case "UpgradePlan":
        setStartPaymentForm(true);
        break;
      default:
        const userData = JSON.parse(localStorage.getItem("MemoverseUserData"));
        return Object.keys(dataUser).length > 0 && userData === null
          ? setUserData(dataUser)
          : userData !== null
          ? loadingForm("StartPaymentForm")
          : loadingForm("StartNameEmailForm");
    }
  };

  const changeOption = () => {
    if (option === 1) {
      setOption(2);
    } else if (option === 2) {
      setOption(1);
    }
    setStartPaymentForm(false);
    const timerId = setTimeout(() => {
      setLoader(false);
      loadingForm("StartPaymentForm");
    }, 1000);
    return () => clearTimeout(timerId);
  };

  const { dueTimeAmount, priceTotal } = selectedOptionBF;

  const setOrderBFLocalData = () => {
    const fullBFPrice =
      parseFloat(dueTimeAmount.replace(/[^0-9.-]+/g, "")) +
      parseFloat(priceTotal.replace(/[^0-9.-]+/g, ""));

    const currentOrderData = {
      fullPlanPrice: fullBFPrice,
      trialDays: false,
      total: selectedOptionBF.priceTotal,
      planExpiration: false,
      planExpirationDate: false,
      planName: selectedOptionBF.planName,
      currency: "USD",
      origin: 2,
    };

    localStorage.setItem(
      "MemoverseOrderLocalData",
      JSON.stringify(currentOrderData)
    );
  };

  const setOrderLocalData = (option) => {
    const currentOrderData = {
      origin: appData.REACT_APP_ORIGIN,
      fullPlanPrice: option.fullPlanPrice,
      trialDays: option.trialDays,
      total: option.priceTotal,
      planExpiration: option.days
        ? `days`
        : option.month > 1
        ? `months`
        : `month`,
      planExpirationDate: option.days ? option.days : option.month,
      optionPlanName: option.optionPlanName ? option.optionPlanName : null,
      varOption: option.varOpt,
      planName: option.fullPlanName,
      currency: "USD",
    };

    localStorage.setItem(
      "MemoverseOrderLocalData",
      JSON.stringify(currentOrderData)
    );
  };

  const contextValue = {
    loader,
    setLoader,
    startNameEmailForm,
    setStartNameEmailForm,
    startPaymentForm,
    setStartPaymentForm,
    startActiveSubscForm,
    setStartActiveSubscForm,
    rewriteEmail,
    loadingForm,
    selectedOptionBF,
    changeOption,
    setOrderBFLocalData,
    setOrderLocalData,
  };

  return (
    <PaymentContext.Provider value={contextValue}>
      {children}
    </PaymentContext.Provider>
  );
};
