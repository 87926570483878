import React, { useState, useEffect, useRef } from "react";
import Payment from "@solidgate/react-sdk";
import { useNavigate } from "react-router-dom";

import { postData } from "../../../../../utils/fetch";
import { useErrorHandler } from "../../../../../context/ErrorHandlerContext";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";
import { useEvent } from "../../../../../context/EventContext";
import { usePaymentBlock } from "../../../../../context/PaymentBlockContext";
import { useUrlContext } from "../../../../../context/urlContext";
import { useRadioInput } from "../../../../../context/RadioInputContext";
import { useConfig } from "../../../../../context/ConfigDataContext";

import PlanDetails from "./PlanDetails/PlanDetails";
import EmailBlock from "./EmailBlock/EmailBlock";
import PaymentSystems from "./PaymentSystems/PaymentSystems";

import loadScript from "../../../../Plan/loadScript";
import LoaderIcon from "../../../../Plan/loaderIcon";
import { Loader } from "../../../../Plan/styles";

import styles from "./PaymentFormStyle.module.css";

const PaymentForm = () => {
  const { setErrorHandler } = useErrorHandler();
  const { appData, paymentButtons, offDiscount } = useFirebaseConfig();
  const { analyticsEvent } = useEvent();
  const { findDataComponent } = useConfig();

  const { selectedOption, currentRender } = useRadioInput();

  const [selOption] = useState(currentRender[selectedOption]);

  const dataPaymentForm = findDataComponent(1, "PaymentForm");

  const {
    // selectedOption,
    setStartPaymentForm,
    loadingForm,
    loader,
    setLoader,
    setOrderLocalData,
  } = usePaymentBlock();

  const { queryParams, getCurrentPath } = useUrlContext();

  const paypalBtnRef = useRef(null);

  const [orderId, setOrderId] = useState("");
  const [scriptUrl, setScriptUrl] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    analyticsEvent("pageView");
    setOrderLocalData(selOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (paypalBtnRef.current) {
      paypalBtnRef.current.addEventListener(
        "order-started-processing",
        function (e) {
          console.log("order-started-processing", e);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-processed",
        function (e) {
          console.log("order-processed", e);
          handleOrderData();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-already-processed",
        function (e) {
          console.log("order-already-processed", e);
          handleOrderData();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-ready",
        function (e) {
          console.log("button-ready", e);
          setLoader(false);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-error",
        function (e) {
          console.log("button-error", e);
          setErrorHandler(e);
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptUrl]);

  const [merchantDataSet, setMerchantDataSet] = useState({});
  const [showForm, setShowForm] = useState(false);

  const hasActSubs = {
    statusCode: 400,
    message: "User already has active subscription",
  };

  async function handleOrderPrepare(planID) {
    analyticsEvent("beginCheckout");

    const savedData = JSON.parse(localStorage.getItem("MemoverseUserData"));

    const email = savedData.userEmail;
    const name = savedData.userName;

    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    }
    const checkSendData = {
      origin: appData.REACT_APP_ORIGIN,
      product_id: planID,
      // TODO: put PayPal parameter to either .env or directly to quiz data config
      is_paypal_order: Boolean(parseInt(appData.REACT_APP_IS_PAYPAL_ORDER)),
      customer_email: email,
      customer_name: name,
      fbc: getCookie("_fbc"),
      fbp: getCookie("_fbp"),
      snap_uuid_c1: getCookie("_scid"),
      snap_click_id: queryParams.ScCid,
      campaign: queryParams.utm_campaign || queryParams.campaign,
      placement:
        queryParams.utm_medium || queryParams.medium || queryParams.placement,
      source: queryParams.utm_source || queryParams.source,
      content: queryParams.utm_content || queryParams.content,
      page_url: await getCurrentPath(),
    };

    function removeUndefinedOrNullFields(obj) {
      const newObj = {};
      for (const key in obj) {
        if (obj[key] !== undefined && obj[key] !== null) {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    }
    const cleanedData = removeUndefinedOrNullFields(checkSendData);
    const saveDataRequest = postData(
      `${appData.REACT_APP_API_ENDPOINT}/order/prepare`,
      cleanedData,
      true
    );

    saveDataRequest.onload = async () => {
      try {
        const startTime = performance.now();
        const response = JSON.parse(saveDataRequest.response);
        if (
          response.statusCode === hasActSubs.statusCode &&
          response.message === hasActSubs.message
        ) {
          setLoader(false);
          setStartPaymentForm(false);
          return loadingForm("StartActiveSubscForm");
        }
        const getMemoverseOrderLocalData = JSON.parse(
          localStorage.getItem("MemoverseOrderLocalData")
        );
        getMemoverseOrderLocalData.capiPurchEventID =
          response.capi_purchase_event_id;
        localStorage.setItem(
          "MemoverseOrderLocalData",
          JSON.stringify(getMemoverseOrderLocalData)
        );

        const endTime = performance.now();
        const timeout = Math.max(0, 2000 - (endTime - startTime));
        await new Promise((resolve) => setTimeout(resolve, timeout));

        const merchantData = {
          merchant: response.merchant_data.merchant_id,
          signature: response.merchant_data.signature,
          paymentIntent: response.merchant_data.paymentIntent,
        };

        if (Object.keys(merchantData).length > 0) {
          setMerchantDataSet(merchantData);
          setShowForm(true);
          setLoader(true);
        } else {
          console.log("Merchant data is empty!");
          setErrorHandler("Merchant data is empty!");
        }

        setOrderId(response.order_id);
        loadScript(response.paypal_data.script_url || "");
        setScriptUrl(response.paypal_data.script_url || "");
      } catch (error) {
        console.error(
          "Error parsing response or setting merchant data:",
          error
        );
      }
    };

    saveDataRequest.onerror = (data) => {
      console.log("** An error occurred during the transaction");
      console.log({ data });
      setErrorHandler("** An error occurred during the transaction");
    };
    return false;
  }
  const handleOrderData = () => {
    const saveDataRequest = postData(
      `${appData.REACT_APP_API_ENDPOINT}/order/data`,
      {
        order_id: orderId,
      },
      true
    );
    saveDataRequest.onload = () => {
      const response = JSON.parse(saveDataRequest.response);

      if (response.order_state === "FULFILLED") {
        localStorage.setItem("MemoverseOrderData", JSON.stringify(response));
        navigate("/thankyou");
      } else {
        setTimeout(() => {
          handleOrderData();
        }, 2000);
      }
    };
    saveDataRequest.onerror = (data) => {
      console.log("** An error occurred during the transaction");
      console.log({ data });
      setErrorHandler("** An error occurred during the transaction");
    };
    return false;
  };

  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);

  const handleOnError = (e) => {
    console.log("handleOnError :", { e });
    setErrorHandler(`handleOnError: ${e}`);
  };

  const handleOnFail = (e) => {
    console.log("handleOnFail :", { e });
    setErrorHandler(`handleOnFail: ${e}`);
  };

  const handleOnMounted = (e) => {
    console.log("handleOnMounted: ", { e });
  };

  const handleOrderStatus = (e) => {
    console.log("handleOrderStatus: ", { e });
  };

  const handleOnResize = (e) => {
    console.log("handleOnResize: ", { e });
  };

  const handleOnSuccess = (e) => {
    console.log("handleOnSuccess: ", { e });
    analyticsEvent("addPaymentInfo");

    if (paypalBtnRef.current) {
      paypalBtnRef.current.style.display = "none";
    }
    handleOrderData();
  };

  const handleOnSubmit = (e) => {
    console.log("handleOnSubmit: ", { e });
  };

  const handleOnInteraction = (e) => {
    console.log("handleOnInteraction: ", { e });
  };

  const handleOnVerify = (e) => {
    console.log("handleOnVerify: ", { e });
  };

  const handleOnRedirectMessage = (e) => {
    console.log("handleOnRedirectMessage: ", { e });
  };

  const handleOnCustomStylesAppended = (e) => {
    console.log("handleOnCustomStylesAppended: ", { e });
  };

  const handleCard = (e) => {
    console.log("handleCard: ", { e });
  };

  const handleOnReadyPaymentInstance = (form) => {
    console.log("handleOnReadyPaymentInstance: ", { form });
  };
  const googlePayButtonParams = {
    enabled: paymentButtons.google_pay,
    color: "black",
  };
  const applePayButtonParams = {
    enabled: paymentButtons.apple_pay,
  };

  const customFormStyles = {
    template: "flat",
    submit_button: {
      "background-color": "#02BEB2;",
    },
  };
  const formParams = {
    formTypeClass: "flat",
    submitButtonText: "Continue",
  };

  useEffect(() => {
    handleOrderPrepare(selOption.productID);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={styles.ShadowPopup}>
      {showForm ? (
        <div className={styles.ScrollContainer}>
          <div className={styles.NewPaymentform}>
            <>
              <div className={styles.OrderDetailsContainer}>
                <button
                  className={styles.OrderDetailsButton}
                  onClick={() => {
                    setStartPaymentForm(false);
                  }}
                >
                  &#88;
                </button>
                <span className={styles.OrderDetails}>
                  Select Payment Method
                </span>
              </div>
              <div className={styles.googlePayPalContainer}>
                {parseInt(appData.REACT_APP_IS_PAYPAL_ORDER) ? (
                  <div
                    id="paypal-button"
                    ref={paypalBtnRef}
                    style={{ marginTop: "10px" }}
                  />
                ) : null}
              </div>
              <div
                className={styles.googleContainerBtn}
                ref={googleContainerRef}
              ></div>
              <div
                className={styles.appleContainerBtn}
                ref={appleContainerRef}
              ></div>
              <div className={styles.hrLine} />
              <EmailBlock />
              <div className={styles.textOrPayWithCard}>
                <span>or pay with card</span>
              </div>
              {offDiscount ? null : <PaymentSystems />}

              <div className={styles.CardPayContainer}>
                <Payment
                  merchantData={merchantDataSet}
                  onVerify={handleOnVerify}
                  googlePayButtonParams={googlePayButtonParams}
                  applePayButtonParams={applePayButtonParams}
                  googlePayContainerRef={googleContainerRef}
                  applePayContainerRef={appleContainerRef}
                  styles={customFormStyles}
                  formParams={formParams}
                  onError={handleOnError}
                  onFail={handleOnFail}
                  onCard={handleCard}
                  onMounted={handleOnMounted}
                  onOrderStatus={handleOrderStatus}
                  onResize={handleOnResize}
                  onSuccess={handleOnSuccess}
                  onSubmit={handleOnSubmit}
                  onInteraction={handleOnInteraction}
                  onFormRedirect={handleOnRedirectMessage}
                  onCustomStylesAppended={handleOnCustomStylesAppended}
                  onReadyPaymentInstance={handleOnReadyPaymentInstance}
                />
              </div>
              <PlanDetails
                detailsText={dataPaymentForm.PlanDetails}
                selectedOption={selOption}
              />

              {/* <div className={styles.superEasyStartCont}>
                <span className={styles.textSupEasStart}>
                  {selectedOption.infoText[4]}
                </span>
              </div> */}
              <div className={styles.secureContainer}>
                <div className={styles.secureHr} />
                <div className={styles.secureImg} />
                <span className={styles.secureText}>Secured by:</span>
                <div className={styles.secureHr} />
              </div>
              <PaymentSystems />
              <br />
            </>
          </div>
        </div>
      ) : (
        <Loader>
          <LoaderIcon />
        </Loader>
      )}
      {loader ? (
        <Loader>
          <LoaderIcon />
        </Loader>
      ) : null}
    </div>
  );
};

export default PaymentForm;
