import React from "react";

import styles from "./LogoGlobalStyle.module.css";

const LogoGlobal = ({ styleData }) => {
  const defaultStyle = {
    width: "130px",
    height: "41px",
  };

  return (
    <div
      className={styles.logoImg}
      style={{
        width: styleData ? styleData.width : defaultStyle.width,
        height: styleData ? styleData.height : defaultStyle.height,
      }}
    />
  );
};

export default LogoGlobal;
