import React, { createContext, useContext, useState } from "react";
import { useFirebaseConfig } from "./FirebaseConfigContext";

const EmailValidatorContext = createContext();

export const useEmailValidator = () => useContext(EmailValidatorContext);

export const EmailValidatorProvider = ({ children }) => {
  const { emailProviders } = useFirebaseConfig();

  const [threshold] = useState(emailProviders.threshold);

  const validEmail = (email) => {
    const emailRegex = new RegExp(`^${emailProviders.regExpression}$`);

    if (emailRegex.test(email)) {
      const domainZone = email.split("@")[1];

      if (domainZone) {
        const isValidDomainZone = popularService(domainZone);

        return isValidDomainZone;
      } else {
        return false;
      }
    } else {
      // console.log("Invalid email format");
      return false;
    }
  };

  const popularService = (service) => {
    for (const provider of emailProviders.domainZone) {
      const distance = levenshteinDistance(service, provider);
      if (distance === 0) {
        // console.log(`Dist:${distance}, ${service} = ${provider}`);
        return true;
      }
    }

    for (const provider of emailProviders.domainZone) {
      const distance = levenshteinDistance(service, provider);
      if (distance <= threshold && distance !== 0) {
        // console.log(
        //   `Err. dist:${distance} & thrd:${threshold}: ${service} != ${provider}`
        // );
        return false;
      }
    }
    // console.log(`New dom.zone: ${service} `);
    return true;
  };

  const levenshteinDistance = (a, b) => {
    const dp = Array.from(Array(a.length + 1), () =>
      Array(b.length + 1).fill(0)
    );

    for (let i = 0; i <= a.length; i++) {
      for (let j = 0; j <= b.length; j++) {
        if (i === 0) {
          dp[i][j] = j;
        } else if (j === 0) {
          dp[i][j] = i;
        } else {
          dp[i][j] = Math.min(
            dp[i - 1][j - 1] + (a[i - 1] !== b[j - 1] ? 1 : 0),
            dp[i][j - 1] + 1,
            dp[i - 1][j] + 1
          );
        }
      }
    }

    return dp[a.length][b.length];
  };

  const contextValue = { validEmail };

  return (
    <EmailValidatorContext.Provider value={contextValue}>
      {children}
    </EmailValidatorContext.Provider>
  );
};
