import React, { createContext, useContext, useState } from "react";

const ControlPaymentPageContext = createContext();

export const useControlPaymentPage = () =>
  useContext(ControlPaymentPageContext);

export const ControlPaymentPageProvider = ({ children }) => {
  // const [passedQuiz] = useState(localStorage.getItem("MemoverseQuizData"));

  const [userNameEmailModal, setUserNameEmailModal] = useState(false);

  const checkQuiz = () => {
    const passedQuiz = localStorage.getItem("MemoverseQuizData");

    if (passedQuiz === null) {
      setUserNameEmailModal(true);
    } else if (passedQuiz !== null) {
      const userData = JSON.parse(localStorage.getItem("MemoverseUserData"));
      if (userData !== null) {
        setUserNameEmailModal(false);
      } else {
        setUserNameEmailModal(true);
      }
    }
  };
  const contextValue = {
    userNameEmailModal,
    checkQuiz,
  };

  return (
    <ControlPaymentPageContext.Provider value={contextValue}>
      {children}
    </ControlPaymentPageContext.Provider>
  );
};
