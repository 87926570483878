import React, { useEffect, useState } from "react";
import { useEmailValidator } from "../../../../../context/EmailValidatorContext";

import styles from "./ReactivateEmailInputStyle.module.css";

const ReactivateEmailInput = ({
  data,
  updatenewUserEmai,
  setValidUserData,
}) => {
  const [newUserEmail, setNewUserEmail] = useState("");
  const [isValidEmailFormat, setIsValidEmailFormat] = useState(true);

  const { validEmail } = useEmailValidator();

  const [pasted, setPasted] = useState(false);
  const handleInputChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 50);

    if (!pasted) {
      setNewUserEmail(truncatedValue.toLowerCase());
      setIsValidEmailFormat(validEmail(truncatedValue));
    }

    setPasted(false);
  };

  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");
    const truncatedValue = pastedValue.slice(0, 50);
    setNewUserEmail(truncatedValue.toLowerCase());
    setIsValidEmailFormat(validEmail(truncatedValue));
    setPasted(true);
  };

  useEffect(() => {
    if (isValidEmailFormat && newUserEmail.length > 0) {
      updatenewUserEmai({ userEmail: newUserEmail });
      setValidUserData(true);
    } else {
      setValidUserData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidEmailFormat, newUserEmail]);

  return (
    <div className={styles.container}>
      <span className={styles.textAboveInput}>{data.input.textAboveInput}</span>
      <input
        type="text"
        placeholder={data.input.placeholder}
        name="emailInput"
        required
        value={newUserEmail}
        onChange={handleInputChange}
        onPaste={handlePaste}
        className={
          !isValidEmailFormat ? styles.customInput : styles.customInput
        }
      />
      {!isValidEmailFormat && (
        <span className={styles.invalidText}>{data.invalidEmailText}</span>
      )}
      <span className={styles.textUnderInput}>{data.textUnderInput}</span>
    </div>
  );
};

export default ReactivateEmailInput;
