import React, { useEffect, useState } from "react";

import { useVWOContext } from "../../../../../../context/vwoContext";
import { useTimer } from "../../../../../../context/TimerContext";
import { useABtest } from "../../../../../../context/ABTestContext";

import Loading from "../../../Loading/Loading";
import styles from "./FooterTextContainerStyle.module.css";

const FooterTextContainerTwo = ({ data }) => {
  const { variantNumber } = useVWOContext();
  const { isActive } = useABtest();
  const { selectedABOption } = useABtest();
  const { showHideElement } = useTimer();

  const [isLoading, setIsLoading] = useState(true);

  const [currentRenderData, setCurrentRenderData] = useState("");

  const updateNumber = (number) => {
    switch (number) {
      case 1:
        return 0;
      case 2:
        return 1;
      case 3:
        return 2;
      default:
        return 0;
    }
  };

  const optionNumber = (option, toggle) => {
    switch (option) {
      case 0:
        if (!toggle) {
          return 0;
        } else if (toggle) {
          return 2;
        }
        break;
      case 1:
        return 1;

      default:
        break;
    }
  };

  useEffect(() => {
    if (showHideElement) {
      setIsLoading(false);
      setCurrentRenderData(
        data.textInfoWithTimer[updateNumber(variantNumber)][
          optionNumber(selectedABOption, isActive)
        ]
      );
    } else if (!showHideElement) {
      setIsLoading(false);
      setCurrentRenderData(
        data.textInfoWithoutTimer[updateNumber(variantNumber)][
          optionNumber(selectedABOption, isActive)
        ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHideElement, selectedABOption, isActive, variantNumber]);

  return (
    <div className={styles.Footer}>
      {isLoading ? (
        <Loading />
      ) : (
        <span className={styles.FooterText}>{currentRenderData}</span>
      )}
    </div>
  );
};

export default FooterTextContainerTwo;
