import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
  const [showModal, setShowModal] = useState(false);
  const [showOrNotModal, setShowOrNotModal] = useState(false);
  const [showModalActSubsc, setShowModalActSubsc] = useState(false);

  const contextValue = {
    showModal,
    setShowModal,
    showOrNotModal,
    setShowOrNotModal,
    showModalActSubsc,
    setShowModalActSubsc
  };

  return (
    <ModalContext.Provider value={contextValue}>
      {children}
    </ModalContext.Provider>
  );
};
