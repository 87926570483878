import React from "react";

import styles from "./ErrorHandlerStyle.module.css";
import { useConfig } from "../../context/ConfigDataContext";

const ErrorHandler = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1,"ErrorHandler");

  return (
    <div className={styles.errContainer}>
      <span className={styles.errText}>{data.errorText}</span>
    </div>
  );
};

export default ErrorHandler;
