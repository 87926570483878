import React from "react";
import Timer from "../Timer/Timer";

import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import styles from "./ImgModule.module.css";

const ImgModule = () => {
  const { findDataComponent } = useConfig();
  const { showHideElement } = useTimer();

  const data = findDataComponent(2, "TopDoubleComponent");

  const styleTimerText = styles.headerText;

  return (
    <div className={styles.ImgModuleContainer}>
      {showHideElement ? (
        <div className={styles.headerContainer}>
          <span className={styles.headerText}>
            {data.components[1].discountText}
          </span>
          <Timer styleProperty={styleTimerText} />
        </div>
      ) : null}
      <div className={styles.mainContainer}>
        <div className={styles.imgContainer}>
          <div className={styles.headLeftCont}>
            <span className={styles.headLeftText}>
              {data.components[1].leftPicture.headerText}
            </span>
          </div>
          <img
            src="./img/brain_sick.png"
            alt="sick_brain"
            className={styles.brainImg}
          />
          <div className={styles.botTextCont}>
            <span className={styles.botTitle}>
              {data.components[1].leftPicture.mainText}
            </span>
            <span className={styles.botMain}>
              {data.components[1].leftPicture.lowText}
            </span>
          </div>
        </div>
        <img src="./img/arrows.png" alt="arrows" className={styles.arrowsImg} />
        <div className={styles.imgContainer}>
          <div className={styles.headRightCont}>
            <span className={styles.headRightText}>
              {data.components[1].rightPicture.headerText}
            </span>
          </div>
          <img
            src="./img/brain_win.png"
            alt="brain_winner"
            className={styles.brainImg}
          />
          <div className={styles.botTextCont}>
            <span className={styles.botTitle}>
              {data.components[1].rightPicture.mainText}
            </span>
            <span className={styles.botMain}>
              {data.components[1].rightPicture.lowText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgModule;
