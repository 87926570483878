import React from "react";

import { useVWOContext } from "../../../../../../context/vwoContext";
import { useABtest } from "../../../../../../context/ABTestContext";

import styles from "../PaymentFormStyle.module.css";

const HRVariantA = () => {
  const { variantNumber } = useVWOContext();
  const { isActive } = useABtest();

  return (
    <div className={styles.secureContainer}>
      {variantNumber >= 2 && !isActive ? (
        <div className={styles.secureHr} />
      ) : variantNumber === 1 ? (
        <div className={styles.secureHr} />
      ) : (
        <div className={styles.secureHrTwo} />
      )}

      <div className={styles.secureImg} />

      <span className={styles.secureText}>
        {variantNumber >= 2 && isActive
          ? "No payment now. Secured by:"
          : "Secured by:"}
      </span>
      {variantNumber >= 2 && !isActive ? (
        <div className={styles.secureHr} />
      ) : variantNumber === 1 ? (
        <div className={styles.secureHr} />
      ) : (
        <div className={styles.secureHrTwo} />
      )}
    </div>
  );
};

export default HRVariantA;
