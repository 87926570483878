import React from "react";

import styles from "./NotableBenefits.module.css";
import { useConfig } from "../../../../context/ConfigDataContext";

const NotableBenefits = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1,"NotableBenefits");

  return (
    <div className={styles.NotableBenefitsContainer}>
      <div className={styles.NotableBenefitsHeader}>{data.headerText}</div>
      <div className={styles.NotableBenefitsMain}>
        {data.benefits.map((benefit, index) => {
          return (
            <div key={index} className={styles.NotableBenefitsElements}>
              <div className={styles.NotableBenefitsElementsImg}>
                {benefit.img}
              </div>
              <div key={index} className={styles.NotableBenefitsElementsText}>
                {benefit.text}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotableBenefits;
