import React from "react";

const MoneyBackImage = () => {
  return (
    <svg
      width="132"
      height="112"
      viewBox="0 0 132 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.93701 91.45C2.93701 91.45 8.95684 94.7336 11.8982 96.5307C10.7169 99.9476 8.62345 106.875 8.62345 106.875C18.3313 102.15 28.6267 98.4581 39.4989 96.2288L35.4981 80.1406C22.7861 82.7568 11.8 86.4442 2.93701 91.45Z"
        fill="url(#paint0_linear_138_538)"
      />
      <path
        d="M128.743 91.45C128.743 91.45 122.724 94.7336 119.781 96.5307C120.963 99.9476 123.057 106.875 123.057 106.875C113.349 102.15 103.053 98.4581 92.1812 96.2288L96.1818 80.1406C108.894 82.7568 119.88 86.4442 128.743 91.45Z"
        fill="url(#paint1_linear_138_538)"
      />
      <path
        d="M68.6703 103.628L65.9145 107.964L63.1462 103.636L59.9041 107.612L57.6598 102.982L53.9761 106.541L52.2884 101.676L48.2149 104.767L47.1072 99.7336L42.7014 102.315L42.1888 97.1848L37.5125 99.221L37.6018 94.0641L32.7212 95.5254L33.4119 90.4152L28.3947 91.2813L29.6774 86.2899L24.5941 86.5484L26.4497 81.7458L21.3722 81.3936L23.7758 76.8469L18.7749 75.889L21.6918 71.6618L16.8381 70.1107L20.228 66.2635L15.5889 64.1418L19.4039 60.7274L15.0454 58.0647L19.2322 55.1315L15.2152 51.9649L19.7141 49.5545L16.0953 45.9282L20.8445 44.0738L17.6735 40.0398L22.6057 38.7679L19.9279 34.3817L24.9741 33.7099L22.8272 29.033L27.916 28.9707L26.3303 24.07L31.3908 24.6185L30.3875 19.5617L35.3487 20.7128L34.943 15.5711L39.7342 17.3085L39.9318 12.1549L44.4866 14.4546L45.2842 9.36008L49.539 12.1901L50.9254 7.22704L54.8203 10.547L56.7753 5.78467L60.2558 9.54816L62.7531 5.05436L65.7699 9.20848L68.7741 5.04492L71.2853 9.5318L74.7543 5.75762L76.7232 10.5138L80.6087 7.18237L82.0096 12.141L86.2561 9.29843L87.0683 14.3905L91.6168 12.0769L91.8288 17.2305L96.6156 15.4781L96.225 20.621L101.182 19.4548L100.194 24.5147L105.253 23.9511L103.682 28.8569L108.77 28.9034L106.637 33.5866L111.685 34.2433L109.02 38.6377L113.957 39.8945L110.797 43.9386L115.552 45.7784L111.944 49.4155L116.45 51.8121L112.442 54.9913L116.637 57.9112L112.287 60.5877L116.112 63.9901L111.479 66.1264L114.88 69.9634L110.032 71.5291L112.961 75.7468L107.963 76.7205L110.379 81.2596L105.302 81.627L107.173 86.4245L102.089 86.1804L103.386 91.1681L98.3662 90.317L99.072 95.4254L94.1869 93.9786L94.2914 99.1354L89.6095 97.1131L89.1113 102.246L84.6987 99.677L83.6047 104.714L79.523 101.634L77.8492 106.505L74.1549 102.957L71.925 107.594L68.6703 103.628Z"
        stroke="#757575"
        strokeWidth="1.34907"
        strokeMiterlimit="10"
      />
      <path
        d="M68.6703 103.628L65.9145 107.964L63.1462 103.636L59.9041 107.612L57.6598 102.982L53.9761 106.541L52.2884 101.676L48.2149 104.767L47.1072 99.7336L42.7014 102.315L42.1888 97.1848L37.5125 99.221L37.6018 94.0641L32.7212 95.5254L33.4119 90.4152L28.3947 91.2813L29.6774 86.2899L24.5941 86.5484L26.4497 81.7458L21.3722 81.3936L23.7758 76.8469L18.7749 75.889L21.6918 71.6618L16.8381 70.1107L20.228 66.2635L15.5889 64.1418L19.4039 60.7274L15.0454 58.0647L19.2322 55.1315L15.2152 51.965L19.7141 49.5545L16.0953 45.9282L20.8445 44.0738L17.6735 40.0398L22.6057 38.7679L19.9279 34.3817L24.9741 33.7099L22.8272 29.033L27.916 28.9707L26.3303 24.07L31.3908 24.6185L30.3875 19.5617L35.3487 20.7128L34.943 15.5711L39.7342 17.3085L39.9318 12.1549L44.4866 14.4546L45.2842 9.36008L49.539 12.1901L50.9254 7.22704L54.8203 10.547L56.7753 5.78467L60.2558 9.54816L62.7531 5.05436L65.7699 9.20848L68.7741 5.04492L71.2853 9.5318L74.7543 5.75762L76.7232 10.5138L80.6087 7.18237L82.0096 12.141L86.2561 9.29843L87.0683 14.3905L91.6168 12.0769L91.8288 17.2305L96.6156 15.4781L96.225 20.621L101.182 19.4548L100.194 24.5147L105.253 23.9511L103.682 28.8569L108.77 28.9034L106.637 33.5866L111.685 34.2433L109.02 38.6377L113.957 39.8945L110.797 43.9386L115.552 45.7784L111.944 49.4155L116.45 51.8121L112.442 54.9913L116.637 57.9112L112.287 60.5877L116.112 63.9901L111.479 66.1264L114.88 69.9634L110.032 71.5291L112.961 75.7468L107.963 76.7205L110.379 81.2596L105.302 81.627L107.173 86.4245L102.089 86.1805L103.386 91.1681L98.3662 90.317L99.072 95.4254L94.1869 93.9786L94.2914 99.1354L89.6096 97.1131L89.1113 102.246L84.6987 99.6769L83.6047 104.714L79.523 101.634L77.8492 106.505L74.1549 102.957L71.925 107.594L68.6703 103.628Z"
        fill="url(#paint2_linear_138_538)"
      />
      <path
        d="M65.8432 86.0864C82.0576 86.0864 95.2019 72.8426 95.2019 56.5056C95.2019 40.1686 82.0576 26.9248 65.8432 26.9248C49.6288 26.9248 36.4844 40.1686 36.4844 56.5056C36.4844 72.8426 49.6288 86.0864 65.8432 86.0864Z"
        fill="url(#paint3_radial_138_538)"
      />
      <path
        d="M65.8401 100.842C41.7068 100.842 22.0728 80.9436 22.0728 56.485C22.0728 32.0265 41.7093 12.1289 65.8401 12.1289C89.9711 12.1289 109.61 32.0302 109.61 56.485C109.61 80.9399 89.976 100.842 65.8401 100.842ZM65.8401 25.9487C49.2256 25.9487 35.7096 39.6478 35.7096 56.485C35.7096 73.3223 49.2262 87.022 65.8401 87.022C82.4541 87.022 95.9708 73.323 95.9708 56.485C95.9708 39.6471 82.4566 25.9487 65.8401 25.9487Z"
        fill="url(#paint4_linear_138_538)"
      />
      <path
        style={{
          mixBlendMode: "multiply",
        }}
        opacity="0.3"
        d="M109.285 61.8176C104.912 60.3019 100.45 59.0525 95.9249 58.0762C95.1072 74.1793 81.9214 87.0191 65.8345 87.0191C49.7798 87.0191 36.6186 74.2265 35.7505 58.1711C31.2281 59.1634 26.7703 60.4303 22.4019 61.9648C25.0752 83.8457 43.5329 100.839 65.8345 100.839C88.1866 100.839 106.676 83.7696 109.285 61.8176Z"
        fill="url(#paint5_linear_138_538)"
      />
      <path
        d="M57.9212 12.0443C82.0993 7.6707 105.321 24.0469 109.69 48.5502C114.059 73.0535 97.9395 96.5452 73.7621 100.921C49.5847 105.296 26.3596 88.9182 21.996 64.4149C17.6324 39.9117 33.7438 16.4186 57.9212 12.0443ZM73.6043 100.046C97.3074 95.7577 113.107 72.7257 108.83 48.7037C104.553 24.6816 81.7817 8.6262 58.0791 12.9118C34.3766 17.1974 18.5759 40.2319 22.8533 64.2546C27.1308 88.2773 49.9011 104.337 73.6043 100.048V100.046Z"
        fill="#0071BC"
        stroke="#0071BC"
        strokeWidth="0.826648"
        strokeMiterlimit="10"
      />
      <path
        d="M60.4974 26.4938C76.8151 23.5417 92.4874 34.5944 95.4345 51.1311C98.3815 67.6676 87.5036 83.523 71.1878 86.475C54.872 89.4271 39.1984 78.375 36.2514 61.8378C33.3044 45.3006 44.1803 29.4458 60.4974 26.4938ZM71.0374 85.6372C86.8984 82.7675 97.4718 67.355 94.6073 51.2808C91.7426 35.2065 76.5082 24.4627 60.6471 27.3323C44.7861 30.2019 34.2127 45.6163 37.0773 61.6881C39.9419 77.7598 55.1764 88.5062 71.0374 85.6372Z"
        stroke="#0071BC"
        strokeWidth="0.826648"
        strokeMiterlimit="10"
      />
      <path
        d="M60.4974 26.4938C76.8151 23.5417 92.4874 34.5944 95.4345 51.1311C98.3815 67.6676 87.5036 83.523 71.1878 86.475C54.872 89.4271 39.1984 78.375 36.2514 61.8378C33.3044 45.3006 44.1803 29.4458 60.4974 26.4938ZM71.0374 85.6372C86.8984 82.7675 97.4718 67.355 94.6073 51.2808C91.7426 35.2065 76.5082 24.4627 60.6472 27.3323C44.7861 30.2019 34.2127 45.6163 37.0773 61.6881C39.9419 77.7598 55.1764 88.5062 71.0374 85.6372Z"
        fill="#0071BC"
      />
      <path
        d="M94.6141 56.3899C94.6141 56.8239 94.6072 57.2585 94.5801 57.6926C93.9202 42.1901 81.3038 29.8309 65.8366 29.8309C50.3693 29.8309 37.7597 42.1901 37.0936 57.6932C37.0729 57.2592 37.0659 56.8245 37.0659 56.3905C37.0659 40.2873 49.9484 27.2324 65.8366 27.2324C81.7247 27.2324 94.6141 40.2873 94.6141 56.3899Z"
        fill="white"
      />
      <path
        d="M65.8452 88.5337L67.0756 92.3721H71.058L67.8362 94.5623L69.0672 98.3095L65.8452 95.8915L62.6233 98.2416L63.8537 94.5736L60.6318 92.3721H64.6142L65.8452 88.5337Z"
        fill="#E6E6E6"
      />
      <path
        d="M33.3252 63.7062L33.5139 64.524L27.7898 67.1797L33.8146 65.7832L33.9838 66.523L26.426 68.2755L26.1486 67.0879L31.8986 64.4378L25.5875 64.6265L25.3164 63.4407L32.8792 61.6914L33.0629 62.4966L26.9846 63.9056L33.3252 63.7062Z"
        fill="white"
      />
      <path
        d="M26.908 61.0417C26.3109 61.0837 25.8274 60.97 25.4575 60.7008C25.0877 60.4316 24.8821 59.9965 24.8411 59.3955C24.7991 58.7938 24.9434 58.3324 25.2738 58.0117C25.6043 57.6909 26.0674 57.5095 26.6633 57.4676L30.6647 57.1895C31.2542 57.1476 31.7365 57.2633 32.1114 57.5367C32.4863 57.8102 32.6939 58.2472 32.7342 58.8476C32.7761 59.449 32.6293 59.9086 32.2938 60.2264C31.9583 60.5444 31.4971 60.7236 30.91 60.7643L26.908 61.0417ZM30.8942 59.9032C31.2486 59.8784 31.518 59.7832 31.7025 59.6176C31.8871 59.4519 31.9686 59.2131 31.9472 58.9011C31.9258 58.582 31.8124 58.3549 31.6069 58.2199C31.4015 58.0848 31.1218 58.0297 30.7678 58.0545L26.6791 58.3388C26.3255 58.3635 26.0561 58.4568 25.8708 58.6187C25.6854 58.7805 25.6039 59.0213 25.6261 59.3408C25.6475 59.6553 25.7613 59.878 25.967 60.0164C26.1728 60.1548 26.4521 60.212 26.8055 60.1875L30.8942 59.9032Z"
        fill="white"
      />
      <path
        d="M32.5836 55.1502L32.5296 55.9301L24.7925 55.3848L24.8692 54.2827L30.5864 52.8724L24.9944 52.4786L25.0479 51.71L32.7849 52.2553L32.722 53.1574L26.3279 54.7098L32.5836 55.1502Z"
        fill="white"
      />
      <path
        d="M29.2994 47.4501L30.0586 47.6098L29.6711 49.478L32.3715 50.0441L32.8471 47.7545L33.617 47.9167L32.9653 51.0619L25.3735 49.4635L26.0259 46.3184L26.7958 46.4807L26.3203 48.7754L28.9125 49.3208L29.2994 47.4501Z"
        fill="white"
      />
      <path
        d="M31.7942 44.4641L26.5581 44.3188L26.8625 43.4677L30.8575 43.6256L27.6859 41.168L27.964 40.3911L32.0828 43.6558L34.699 44.6063L34.4096 45.4152L31.7942 44.4641Z"
        fill="white"
      />
      <path
        d="M31.1703 34.2934C31.4928 33.7776 31.8435 33.4856 32.2221 33.4177C32.6008 33.3498 33.0338 33.4706 33.521 33.7801L33.8305 33.9769C34.1803 34.1988 34.4275 34.4349 34.5721 34.6853C34.7168 34.9356 34.7424 35.2334 34.6489 35.5784C34.937 35.313 35.2454 35.19 35.5742 35.2092C35.903 35.2285 36.2483 35.353 36.6102 35.5829L37.1631 35.9345C37.6568 36.2477 37.9595 36.6116 38.0715 37.0259C38.1835 37.4402 38.0786 37.9052 37.757 38.421L36.8065 39.9421L30.2432 35.7747L31.1703 34.2934ZM33.8419 36.2402C34.0267 35.9466 34.1063 35.6871 34.0802 35.4615C34.0543 35.2359 33.8693 35.0142 33.5255 34.7966L33.1411 34.5525C32.8475 34.3663 32.596 34.2837 32.3863 34.3047C32.1766 34.3257 31.9892 34.4682 31.8239 34.7324L31.3716 35.4602L33.4903 36.8051L33.8419 36.2402ZM37.0902 38.0015C37.4168 37.479 37.2833 37.0292 36.6895 36.6522L36.1082 36.283C35.7396 36.0469 35.4398 35.9577 35.2087 36.0156C34.9776 36.0736 34.7607 36.2635 34.5577 36.5855L34.1564 37.2278L36.6026 38.7808L37.0902 38.0015Z"
        fill="white"
      />
      <path
        d="M38.7071 34.3417L39.7041 35.5904L39.1877 36.203L34.2397 30.0033L35.05 29.0415L41.9209 32.9623L41.361 33.6259L39.9772 32.8359L38.7071 34.3417ZM38.214 33.7756L39.333 32.4483L35.3557 30.197L38.214 33.7756Z"
        fill="white"
      />
      <path
        d="M38.6434 25.2887C39.0909 24.8883 39.5386 24.7262 39.9864 24.8025C40.4343 24.8788 40.8536 25.1394 41.2445 25.5844L41.708 26.1097L41.096 26.6588L40.6023 26.1002C40.367 25.8331 40.1303 25.6742 39.892 25.6234C39.6539 25.5727 39.4165 25.6534 39.18 25.8656C38.9477 26.0753 38.8416 26.3023 38.8617 26.5468C38.8818 26.7912 39.0096 27.0479 39.2448 27.3167L41.9691 30.4028C42.2044 30.6695 42.4424 30.8272 42.6831 30.8758C42.9238 30.9245 43.1599 30.8447 43.3914 30.6368C43.6285 30.4242 43.7367 30.1965 43.7141 29.9518C43.6914 29.7071 43.5624 29.4529 43.3266 29.1862L42.6566 28.427L43.2693 27.8778L43.9097 28.6037C44.303 29.0482 44.5111 29.4992 44.5337 29.9568C44.5563 30.4143 44.3443 30.8433 43.8977 31.2438C43.4583 31.64 43.0143 31.7997 42.5661 31.7225C42.1178 31.6453 41.6985 31.3847 41.308 30.9406L38.6422 27.9212C38.4585 27.719 38.3046 27.4918 38.1849 27.2463C38.0814 27.0347 38.024 26.8036 38.0163 26.5682C38.0128 26.3398 38.063 26.1138 38.1629 25.9084C38.2801 25.6719 38.4435 25.4612 38.6434 25.2887Z"
        fill="white"
      />
      <path
        d="M45.245 25.7815L45.291 26.676L46.7377 28.8588L46.0187 29.3419L41.7148 22.8396L42.4339 22.3571L44.5097 25.4966L44.1486 21.2085L44.877 20.7197L45.2047 24.7783L49.2356 27.1842L48.4983 27.68L45.245 25.7815Z"
        fill="white"
      />
      <path
        d="M84.9524 28.8563L84.2687 28.3808L86.7182 22.5308L83.2031 27.6543L82.5835 27.2228L86.9905 20.7935L87.9838 21.4853L85.5129 27.3492L90.042 22.9189L91.0352 23.6108L86.6282 30.0402L85.9583 29.5728L89.5029 24.4022L84.9524 28.8563Z"
        fill="white"
      />
      <path
        d="M91.4782 26.4622C91.8909 26.0266 92.3238 25.7821 92.7772 25.7288C93.2305 25.6755 93.6735 25.8569 94.1063 26.273C94.5391 26.6885 94.7414 27.1272 94.7133 27.5889C94.6852 28.0505 94.4646 28.4992 94.0515 28.935L91.2838 31.8593C90.8762 32.2909 90.443 32.533 89.9842 32.5859C89.5254 32.6387 89.08 32.4574 88.6476 32.0418C88.2143 31.6253 88.0145 31.1867 88.048 30.7258C88.0816 30.265 88.3024 29.8181 88.7105 29.3853L91.4782 26.4622ZM89.2973 30.0144C89.0528 30.2728 88.9178 30.526 88.8923 30.7742C88.8667 31.0226 88.9662 31.2544 89.1911 31.47C89.4204 31.6905 89.6563 31.7809 89.8987 31.7411C90.1411 31.7012 90.3843 31.5518 90.6284 31.2926L93.4591 28.3035C93.7035 28.0456 93.8398 27.7939 93.8679 27.5486C93.896 27.3033 93.7952 27.0701 93.5653 26.8491C93.3405 26.6336 93.1059 26.5445 92.8614 26.5818C92.6169 26.6191 92.3725 26.7672 92.128 27.0259L89.2973 30.0144Z"
        fill="white"
      />
      <path
        d="M91.2291 34.672L90.7485 34.0594L96.832 29.2158L97.5119 30.0801L94.2278 35.0023L98.6253 31.4986L99.099 32.1031L93.015 36.9511L92.4595 36.2447L96.1481 30.7589L91.2291 34.672Z"
        fill="white"
      />
      <path
        d="M98.8002 38.1366L98.1321 38.5342L97.1691 36.8905L94.7939 38.3033L95.9765 40.3219L95.2996 40.7251L93.6768 37.9573L100.355 33.9844L101.977 36.7521L101.3 37.1553L100.121 35.1368L97.8378 36.4949L98.8002 38.1366Z"
        fill="white"
      />
      <path
        d="M98.9597 42.0281L102.923 38.5791L103.267 39.4139L100.211 42.0111L104.198 41.6714L104.513 42.4338L99.2855 42.8213L96.7177 43.8963L96.3906 43.1031L98.9597 42.0281Z"
        fill="white"
      />
      <path
        d="M106.226 49.1292C106.333 49.7298 106.271 50.1835 106.038 50.4905C105.806 50.7974 105.405 51.0018 104.835 51.1037L104.476 51.1667C104.068 51.2397 103.727 51.2329 103.454 51.1465C103.179 51.0602 102.962 50.8574 102.8 50.5383C102.764 50.9308 102.618 51.2313 102.363 51.4396C102.109 51.6481 101.77 51.7903 101.347 51.8661L100.703 51.9819C100.129 52.0851 99.6619 52.0221 99.3025 51.7932C98.9432 51.5643 98.71 51.1488 98.6031 50.5471L98.2886 48.7777L105.923 47.4038L106.226 49.1292ZM99.3743 50.408C99.4824 51.0166 99.8809 51.2585 100.569 51.134L101.246 51.0119C101.675 50.9343 101.956 50.7949 102.09 50.5937C102.223 50.3924 102.256 50.1049 102.189 49.7312L102.057 48.9839L99.2101 49.496L99.3743 50.408ZM102.95 49.5036C103.011 49.8478 103.126 50.0937 103.295 50.2413C103.464 50.389 103.748 50.4265 104.148 50.3539L104.595 50.2734C104.937 50.2118 105.178 50.1022 105.318 49.9445C105.46 49.7868 105.502 49.5543 105.449 49.2469L105.298 48.4008L102.833 48.8443L102.95 49.5036Z"
        fill="white"
      />
      <path
        d="M100.618 54.2047L99.0458 53.9581L99.0176 53.1548L106.818 54.3782L106.861 55.6362L99.1622 57.4026L99.132 56.532L100.689 56.1823L100.618 54.2047ZM101.365 54.2901L101.427 56.0307L105.87 54.9922L101.365 54.2901Z"
        fill="white"
      />
      <path
        d="M106.722 60.8391C106.66 61.4388 106.438 61.8626 106.057 62.1104C105.674 62.3582 105.189 62.4517 104.602 62.391L103.91 62.3179L103.994 61.4965L104.732 61.5738C105.085 61.6103 105.366 61.5667 105.576 61.443C105.785 61.3193 105.907 61.098 105.939 60.7794C105.971 60.4686 105.897 60.229 105.718 60.0604C105.539 59.8918 105.273 59.7893 104.92 59.7528L100.843 59.3269C100.489 59.2904 100.208 59.3359 99.9984 59.4634C99.7889 59.5893 99.6675 59.8106 99.636 60.1214C99.6047 60.4321 99.6763 60.6806 99.8562 60.8461C100.036 61.0115 100.303 61.1115 100.655 61.148L101.658 61.2524L101.574 62.0746L100.615 61.9746C100.028 61.9129 99.5722 61.7212 99.248 61.3996C98.9238 61.078 98.793 60.6166 98.8555 60.0157C98.9162 59.4232 99.1377 59.0038 99.5197 58.7576C99.9017 58.5115 100.387 58.418 100.975 58.4771L104.964 58.8942C105.235 58.9187 105.5 58.9822 105.751 59.0828C105.97 59.1689 106.167 59.3006 106.33 59.4691C106.487 59.6348 106.602 59.835 106.665 60.0534C106.736 60.309 106.755 60.5761 106.722 60.8391Z"
        fill="white"
      />
      <path
        d="M101.52 64.9702L100.888 64.3412L98.3605 63.712L98.5688 62.8667L106.099 64.7437L105.891 65.5891L102.257 64.6814L105.396 67.6045L105.185 68.46L102.229 65.686L97.644 66.6465L97.8574 65.7797L101.52 64.9702Z"
        fill="white"
      />
      <path
        d="M65.8406 14.1064L66.837 17.2139H70.0607L67.4522 19.0469L68.4486 22.1109L65.8406 20.1685L63.2326 22.0781L64.229 19.0525L61.6211 17.2139H64.8442L65.8406 14.1064Z"
        fill="black"
      />
      <path
        d="M76.7482 18.1108L77.3998 20.142H79.5071L77.8024 21.341L78.4534 23.3438L76.7482 22.0737L75.0435 23.3224L75.6945 21.3441L73.9893 20.142H76.0971L76.7482 18.1108Z"
        fill="black"
      />
      <path
        d="M54.9328 18.1108L54.281 20.142H52.1738L53.8784 21.341L53.2274 23.3438L54.9328 22.0737L56.6374 23.3224L55.9864 21.3441L57.6917 20.142H55.5838L54.9328 18.1108Z"
        fill="black"
      />
      <path
        d="M65.8406 14.1064L66.837 17.2139H70.0607L67.4522 19.0469L68.4486 22.1109L65.8406 20.1685L63.2326 22.0781L64.229 19.0525L61.6211 17.2139H64.8442L65.8406 14.1064Z"
        fill="white"
      />
      <path
        d="M76.7482 18.1108L77.3998 20.142H79.5071L77.8024 21.341L78.4534 23.3438L76.7482 22.0737L75.0435 23.3224L75.6945 21.3441L73.9893 20.142H76.0971L76.7482 18.1108Z"
        fill="white"
      />
      <path
        d="M54.9328 18.1108L54.281 20.142H52.1738L53.8784 21.341L53.2274 23.3438L54.9328 22.0737L56.6374 23.3224L55.9864 21.3441L57.6917 20.142H55.5838L54.9328 18.1108Z"
        fill="white"
      />
      <path
        d="M64.0686 50.2245C63.3459 50.9309 62.3702 51.253 60.368 51.253C58.964 51.253 56.9857 51.1027 56.3051 49.5169C56.0497 48.9381 56.0283 48.167 56.0283 47.5669V45.7427H59.3471V47.3279C59.3471 47.6706 59.2622 48.7646 60.4957 48.7646C60.7473 48.7646 61.4317 48.7211 61.6015 47.9279C61.6644 47.6706 61.6644 46.9428 61.6644 46.6856C61.6644 46.4497 61.6644 45.3137 61.6015 45.0784C61.4317 44.2852 60.5806 44.3494 60.4101 44.3708H59.0068V41.7791C59.4962 41.7791 59.9849 41.7791 60.4743 41.7577C60.7933 41.7371 61.2191 41.6949 61.4531 41.4364C61.6417 41.2225 61.6864 40.9004 61.6864 40.3437C61.6864 40.065 61.6657 39.7864 61.6657 39.5077C61.6229 38.6076 61.5166 38.0509 60.4743 38.0509C60.3252 38.0509 59.5597 38.0509 59.3684 38.7366C59.333 38.9057 59.3186 39.0784 59.3257 39.2511V40.5148H56.1534V39.0586C56.1534 38.3081 56.1962 38.0296 56.324 37.6653C56.7071 36.5085 57.8965 35.5649 60.3868 35.5649C60.7563 35.5646 61.1256 35.5856 61.4927 35.6279C63.4075 35.8637 64.2604 36.6973 64.5749 37.5357C64.7875 38.0924 64.8089 38.6504 64.8089 39.8505C64.8089 40.9432 64.7461 41.3288 64.4259 41.8427C64.1743 42.2503 63.8944 42.5075 63.1288 43.0007C63.4264 43.1077 64.4259 43.4291 64.7643 44.4575C64.953 44.9935 64.9127 45.615 64.9127 46.7938C64.9197 48.7022 64.8134 49.4734 64.0686 50.2245Z"
        fill="white"
      />
      <path
        d="M74.5539 49.9674C73.8947 50.7387 72.8952 51.2318 70.832 51.2318C70.3621 51.2358 69.8925 51.2069 69.4266 51.1456C68.8738 51.06 67.1294 50.7813 66.491 48.9811C66.257 48.3382 66.2148 47.7809 66.2148 47.1166V39.9155C66.2148 39.0349 66.2777 38.6517 66.3847 38.2435C67.108 35.5003 70.001 35.5651 70.6816 35.5651C72.5749 35.5651 73.5532 36.0149 74.2337 36.6149C74.6576 36.9671 74.9809 37.425 75.171 37.9422C75.405 38.5424 75.4264 39.0782 75.4264 40.2992V46.838C75.4264 47.9954 75.3395 49.0452 74.5539 49.9674ZM71.9591 39.5727C71.9607 39.4004 71.9464 39.2284 71.9164 39.0588C71.8315 38.7373 71.5767 38.3297 70.8106 38.3297C70.5896 38.335 70.3722 38.3863 70.1721 38.48C69.8324 38.6517 69.6619 38.887 69.6619 39.5495V47.2865C69.6531 47.4956 69.6742 47.7049 69.7248 47.908C69.8097 48.2715 70.0223 48.6578 70.8093 48.6578C70.8942 48.6578 71.2345 48.6792 71.5326 48.4866C71.9579 48.2295 71.9579 47.7362 71.9579 47.3079L71.9591 39.5727Z"
        fill="white"
      />
      <path
        d="M64.2991 50.6869C63.5757 51.3939 62.6007 51.7154 60.5978 51.7154C59.1939 51.7154 57.2161 51.5651 56.5356 49.9793C56.2802 49.4005 56.2588 48.6294 56.2588 48.0293V46.2051H59.5769V47.7934C59.5769 48.1362 59.4921 49.2294 60.7256 49.2294C60.981 49.2294 61.6615 49.186 61.8313 48.3935C61.8943 48.1362 61.8943 47.4078 61.8943 47.1506C61.8943 46.9147 61.8943 45.7786 61.8313 45.5433C61.6615 44.7501 60.8104 44.8143 60.6406 44.8357H59.2367V42.2421C59.726 42.2421 60.2148 42.2422 60.7042 42.2214C61.0237 42.2001 61.4489 42.1585 61.6829 41.8994C61.8716 41.6855 61.9169 41.3641 61.9169 40.8067C61.9169 40.528 61.8955 40.2494 61.8955 39.9708C61.8527 39.0706 61.7465 38.5139 60.7042 38.5139C60.5557 38.5139 59.7895 38.5139 59.5983 39.1996C59.5629 39.3687 59.5485 39.5415 59.5555 39.7141V40.9778H56.3865V39.521C56.3865 38.7705 56.4286 38.4919 56.5563 38.1277C56.9394 36.9709 58.1289 36.0273 60.6192 36.0273C60.9887 36.0273 61.3579 36.0483 61.7251 36.0903C63.6399 36.3261 64.4928 37.1597 64.8073 37.9981C65.0199 38.5548 65.0413 39.1128 65.0413 40.3129C65.0413 41.4056 64.9784 41.7912 64.6583 42.3057C64.4029 42.7127 64.1261 42.9699 63.3606 43.4631C63.6581 43.5701 64.6583 43.8915 64.996 44.9199C65.1848 45.4559 65.1451 46.0774 65.1451 47.2562C65.1496 49.1646 65.0438 49.9365 64.2991 50.6869Z"
        fill="#231F20"
      />
      <path
        d="M74.7861 50.4273C74.1263 51.1985 73.1268 51.6916 71.0636 51.6916C70.5941 51.6955 70.125 51.6668 69.6595 51.6054C69.1066 51.5199 67.3623 51.2413 66.7245 49.4409C66.4905 48.7981 66.4478 48.2408 66.4478 47.5765V40.3754C66.4478 39.4947 66.5106 39.1117 66.6182 38.704C67.3415 35.9609 70.2339 36.025 70.9151 36.025C72.8078 36.025 73.786 36.4754 74.4673 37.0755C74.8917 37.4277 75.2153 37.886 75.4051 38.404C75.6385 39.004 75.6568 39.54 75.6568 40.7616V47.2979C75.6568 48.4553 75.573 49.5051 74.7861 50.4273ZM72.1907 40.0325C72.1923 39.8603 72.1782 39.6883 72.1487 39.5186C72.0631 39.1972 71.8083 38.7902 71.0428 38.7902C70.8219 38.7952 70.6044 38.8461 70.4043 38.94C70.064 39.1117 69.8935 39.3469 69.8935 40.0093V47.7464C69.8847 47.9555 69.9059 48.1647 69.9565 48.3678C70.0413 48.7321 70.2539 49.1176 71.0416 49.1176C71.1264 49.1176 71.4668 49.139 71.7643 48.9465C72.1895 48.6893 72.1895 48.1961 72.1895 47.7678L72.1907 40.0325Z"
        fill="#231F20"
      />
      <path
        d="M58.3211 63.0894C57.9815 63.477 57.2122 64.1588 55.9408 64.4602C55.3641 64.5941 54.8608 64.6243 54.2695 64.6243H50.1733V54.1069H53.5009C53.8261 54.1069 54.1368 54.1069 54.4444 54.1214C54.8847 54.1515 56.6334 54.1962 57.9041 55.2537C58.0966 55.4027 58.4074 55.6858 58.7326 56.2073C59.1024 56.7885 59.5163 57.727 59.5163 59.2763C59.5194 61.2571 58.8835 62.434 58.3211 63.0894ZM56.3082 57.1892C56.0469 56.8548 55.6916 56.6059 55.2879 56.4746C54.7998 56.3104 54.3564 56.3255 53.8538 56.3255H52.8057V62.5082H53.6041C54.3733 62.5082 55.2603 62.4635 55.9408 61.9572C56.4731 61.5552 56.902 60.7947 56.902 59.365C56.902 58.5605 56.8133 57.8302 56.3101 57.1892H56.3082Z"
        fill="white"
      />
      <path
        d="M68.1859 64.6243L67.6688 62.9404H63.8267L63.3096 64.6243H60.5293L64.4004 54.1069H67.1216L70.9662 64.6256L68.1859 64.6243ZM65.7138 56.6086L64.4274 61.0483H67.0744L65.7138 56.6086Z"
        fill="white"
      />
      <path
        d="M77.1445 59.6493V64.6256H74.5717V59.7236L70.6528 54.1069H73.6414L75.8889 57.6678L78.122 54.1069H81.0942L77.1445 59.6493Z"
        fill="white"
      />
      <path
        d="M58.7378 63.9268C58.3975 64.3143 57.6288 64.9962 56.3569 65.2974C55.7801 65.4314 55.2775 65.4616 54.6855 65.4616H50.5894V54.9424H53.9169C54.2421 54.9424 54.5528 54.9424 54.8604 54.9574C55.3046 54.987 57.0494 55.0317 58.3201 56.0897C58.6509 56.3559 58.9314 56.679 59.1486 57.0439C59.5184 57.6245 59.9323 58.5637 59.9323 60.1129C59.9355 62.0944 59.2995 63.2707 58.7378 63.9268ZM56.7248 58.0265C56.4633 57.6932 56.1086 57.4451 55.7058 57.3138C55.2184 57.1496 54.7742 57.1653 54.2716 57.1653H53.2219V63.3481H54.0207C54.7894 63.3481 55.6763 63.3034 56.3569 62.797C56.8891 62.3945 57.318 61.6346 57.318 60.2042C57.318 59.3972 57.2293 58.6674 56.7268 58.0265H56.7248Z"
        fill="#231F20"
      />
      <path
        d="M68.6037 65.4616L68.086 63.7777H64.2413L63.7237 65.4616H60.9434L64.8175 54.9424H67.5394L71.3846 65.4616H68.6037ZM66.1341 57.4452L64.8477 61.8856H67.4947L66.1341 57.4452Z"
        fill="#231F20"
      />
      <path
        d="M77.5605 60.4854V65.4616H74.9877V60.5589L71.0688 54.9424H74.0561L76.3037 58.5033L78.5367 54.9424H81.5096L77.5605 60.4854Z"
        fill="#231F20"
      />
      <path
        d="M115.379 95.4157C83.3836 83.6968 49.012 83.6968 17.0157 95.4157C15.35 89.8718 13.6841 84.3284 12.0181 78.7854C47.2387 65.8632 85.1562 65.8632 120.377 78.7854C118.711 84.3284 117.045 89.8718 115.379 95.4157Z"
        fill="url(#paint6_linear_138_538)"
      />
      <path
        d="M31.1622 82.502C31.0784 82.0382 30.9944 81.5746 30.9106 81.1112C31.8558 80.9375 32.8017 80.7698 33.7481 80.608C34.0225 82.2585 34.2965 83.9094 34.5704 85.5603C34.3263 85.6698 33.8199 85.8283 33.0549 86.0422C32.2901 86.256 31.734 86.3982 31.3849 86.463C29.9161 86.7379 28.8273 86.5372 28.1057 85.8396C27.3843 85.142 26.8421 83.933 26.4866 82.2044C26.1312 80.4759 26.1652 79.1543 26.6024 78.2416C27.0395 77.3288 28.0001 76.7218 29.4802 76.4444C30.4705 76.2703 31.4747 76.1885 32.48 76.2003L33.0179 76.211L33.1669 77.4735C32.0765 77.5126 30.9902 77.6288 29.9161 77.8213C28.9726 77.9981 28.37 78.3799 28.1209 78.9581C27.8718 79.5361 27.8844 80.5149 28.1548 81.8919C28.4253 83.2687 28.7732 84.1885 29.1934 84.6495C29.6135 85.1106 30.3055 85.2471 31.2735 85.0672C31.9655 84.9383 32.4763 84.8029 32.804 84.657C32.6657 83.8657 32.5274 83.0746 32.3895 82.2837C31.9806 82.3538 31.5715 82.4265 31.1622 82.502Z"
        fill="white"
      />
      <path
        d="M37.6369 82.0551C37.8256 83.3213 38.5842 83.8509 39.9191 83.666C41.2539 83.4811 41.8438 82.7659 41.6859 81.4959C41.4079 79.2733 41.1295 77.0497 40.8506 74.825C41.4101 74.7541 41.9695 74.6853 42.5289 74.6187L43.3045 81.2681C43.4391 82.4242 43.2321 83.3031 42.6918 83.9101C42.1515 84.517 41.2891 84.8926 40.1008 85.0561C38.9126 85.2197 37.9829 85.0908 37.3041 84.6542C36.6254 84.2177 36.1945 83.4276 36.0159 82.2777C35.6724 80.0727 35.3294 77.868 34.9868 75.6635C35.5441 75.5754 36.1019 75.4898 36.6601 75.4068C36.9859 77.6215 37.3115 79.8376 37.6369 82.0551Z"
        fill="white"
      />
      <path
        d="M44.8623 84.2961C45.3043 80.9317 45.7889 77.5681 46.316 74.2052C47.4219 74.0958 48.5287 73.996 49.6366 73.9058C50.7525 77.1202 51.8267 80.3431 52.8592 83.5747C52.3257 83.6111 51.7921 83.6502 51.2583 83.6916C51.0281 83.0006 50.7962 82.3086 50.5626 81.6158C49.2957 81.7173 48.0299 81.8314 46.7651 81.9581C46.6561 82.6793 46.5496 83.4008 46.4455 84.1226C45.9176 84.1796 45.3898 84.2374 44.8623 84.2961ZM47.7288 75.4187C47.4571 77.1196 47.1959 78.8208 46.9456 80.5225C48.0066 80.4173 49.0684 80.3215 50.131 80.2351C49.5897 78.6047 49.0378 76.9768 48.4755 75.3514C48.2255 75.3736 47.9766 75.396 47.7288 75.4187Z"
        fill="white"
      />
      <path
        d="M55.7829 79.871C55.8428 81.0452 55.9028 82.2173 55.9627 83.3873C55.4331 83.415 54.9037 83.4448 54.3744 83.4767L53.7876 73.6103C55.0917 73.5319 56.3966 73.4662 57.702 73.4134C60.0358 73.3209 61.2246 74.3569 61.2674 76.4478C61.2976 77.8524 60.7685 78.8142 59.6948 79.3477C60.2848 80.6238 60.864 81.9028 61.4322 83.1848C60.8498 83.1982 60.2673 83.2139 59.6848 83.232C59.1756 82.0745 58.6578 80.9188 58.1311 79.7647C57.3481 79.7953 56.5654 79.8307 55.7829 79.871ZM59.5772 76.5076C59.542 75.3257 58.935 74.75 57.7574 74.7973C57.0147 74.8274 56.2719 74.8616 55.5287 74.8998C55.5896 76.0962 55.6507 77.2913 55.7124 78.4853C56.4509 78.4471 57.19 78.4131 57.9298 78.3834C58.5168 78.3599 58.9421 78.1781 59.2055 77.838C59.4688 77.4979 59.5927 77.0544 59.5772 76.5076Z"
        fill="white"
      />
      <path
        d="M62.7271 83.1598C63.4819 79.8528 64.2803 76.5508 65.1223 73.2538C66.2329 73.2504 67.3441 73.2569 68.4562 73.2733C69.2639 76.5799 70.0288 79.8911 70.7509 83.2069C70.2171 83.1927 69.6827 83.1803 69.1476 83.1698C68.984 82.4599 68.8183 81.7499 68.6506 81.04C67.3808 81.0198 66.111 81.0122 64.8412 81.0173C64.6659 81.7256 64.4923 82.4336 64.3204 83.1416C63.7877 83.1453 63.2567 83.1513 62.7271 83.1598ZM66.4132 74.5968C65.9824 76.2638 65.5631 77.9324 65.155 79.6026C66.2202 79.5992 67.2853 79.6054 68.3506 79.6208C67.9656 77.9464 67.5699 76.2731 67.1636 74.6012C66.9101 74.5993 66.6629 74.5974 66.4132 74.5968Z"
        fill="white"
      />
      <path
        d="M72.2671 83.2537L72.6125 73.3779C73.5867 73.4127 74.5606 73.4547 75.5344 73.5038C76.4384 76.3759 77.2981 79.2539 78.1134 82.1378L78.3291 82.1523C78.517 79.3355 78.7057 76.5185 78.8953 73.7012C79.4517 73.7389 80.0084 73.779 80.5653 73.8214L79.8256 83.6771C78.9202 83.6087 78.0146 83.5458 77.1088 83.4883C76.2646 80.6031 75.3743 77.7237 74.4379 74.8499L74.2284 74.8404L73.8604 83.3167C73.3279 83.2932 72.7968 83.2722 72.2671 83.2537Z"
        fill="white"
      />
      <path
        d="M81.9888 75.3703C82.0282 74.8951 82.0676 74.4206 82.107 73.9468C84.6118 74.1564 87.1137 74.4154 89.6126 74.7236L89.4409 76.1415C88.4836 76.024 87.5255 75.9135 86.5668 75.8099C86.2687 78.612 85.9707 81.4145 85.673 84.217C85.14 84.1595 84.6072 84.1044 84.0746 84.0515L84.8993 75.6376C83.9293 75.5432 82.9591 75.4541 81.9888 75.3703Z"
        fill="white"
      />
      <path
        d="M89.8853 84.719L91.1495 74.9175C93.3059 75.2001 95.4586 75.5186 97.6078 75.873L97.3833 77.2569C95.7981 76.9955 94.2105 76.7538 92.6203 76.5316L92.2391 79.3043C93.5135 79.4821 94.7864 79.6731 96.0579 79.8774C95.9862 80.3336 95.9143 80.7897 95.8421 81.2455C94.5795 81.043 93.3156 80.8543 92.0504 80.6794C91.9187 81.6317 91.7874 82.5845 91.6566 83.5377C93.1902 83.7516 94.7208 83.9847 96.2485 84.2372L96.022 85.6323C93.9802 85.2923 91.9347 84.9878 89.8853 84.719Z"
        fill="white"
      />
      <path
        d="M97.6772 85.9082C98.2342 82.662 98.7911 79.4156 99.348 76.1689C101.494 76.5426 103.636 76.9523 105.772 77.3981C105.679 77.8548 105.584 78.3115 105.491 78.7682C103.914 78.4397 102.335 78.1307 100.754 77.841C100.589 78.7589 100.423 79.6771 100.257 80.5955C101.525 80.8269 102.791 81.0716 104.056 81.3295C103.965 81.7825 103.874 82.2351 103.783 82.6877C102.528 82.4318 101.27 82.1886 100.009 81.9579C99.8384 82.9048 99.6677 83.8513 99.4971 84.7974C101.023 85.0767 102.545 85.3748 104.065 85.6919C103.97 86.1532 103.875 86.6144 103.781 87.0757C101.752 86.6521 99.7175 86.2631 97.6772 85.9082Z"
        fill="white"
      />
      <path
        d="M31.1622 82.502C31.0784 82.0382 30.9944 81.5746 30.9106 81.1112C31.8558 80.9375 32.8017 80.7698 33.7481 80.608C34.0225 82.2585 34.2965 83.9094 34.5704 85.5603C34.3263 85.6698 33.8199 85.8283 33.0549 86.0422C32.2901 86.256 31.734 86.3982 31.3849 86.463C29.9161 86.7379 28.8273 86.5372 28.1057 85.8396C27.3843 85.142 26.8421 83.933 26.4866 82.2044C26.1312 80.4759 26.1652 79.1543 26.6024 78.2416C27.0395 77.3288 28.0001 76.7218 29.4802 76.4444C30.4705 76.2703 31.4747 76.1885 32.48 76.2003L33.0179 76.211L33.1669 77.4735C32.0765 77.5126 30.9902 77.6288 29.9161 77.8213C28.9726 77.9981 28.37 78.3799 28.1209 78.9581C27.8718 79.5361 27.8844 80.5149 28.1548 81.8919C28.4253 83.2687 28.7732 84.1885 29.1934 84.6495C29.6135 85.1106 30.3055 85.2471 31.2735 85.0672C31.9655 84.9383 32.4763 84.8029 32.804 84.657C32.6657 83.8657 32.5274 83.0746 32.3895 82.2837C31.9806 82.3538 31.5715 82.4265 31.1622 82.502Z"
        fill="black"
      />
      <path
        d="M37.6369 82.0551C37.8256 83.3213 38.5842 83.8509 39.9191 83.666C41.2539 83.4811 41.8438 82.7659 41.6859 81.4959C41.4079 79.2733 41.1295 77.0497 40.8506 74.825C41.4101 74.7541 41.9695 74.6853 42.5289 74.6187L43.3045 81.2681C43.4391 82.4242 43.2321 83.3031 42.6918 83.9101C42.1515 84.517 41.2891 84.8926 40.1008 85.0561C38.9126 85.2197 37.9829 85.0908 37.3041 84.6542C36.6254 84.2177 36.1945 83.4276 36.0159 82.2777C35.6724 80.0727 35.3294 77.868 34.9868 75.6635C35.5441 75.5754 36.1019 75.4898 36.6601 75.4068C36.9859 77.6215 37.3115 79.8376 37.6369 82.0551Z"
        fill="black"
      />
      <path
        d="M44.8623 84.2961C45.3043 80.9317 45.7889 77.5681 46.316 74.2052C47.4219 74.0958 48.5287 73.996 49.6366 73.9058C50.7525 77.1202 51.8267 80.3431 52.8592 83.5747C52.3257 83.6111 51.7921 83.6502 51.2583 83.6916C51.0281 83.0006 50.7962 82.3086 50.5626 81.6158C49.2957 81.7173 48.0299 81.8314 46.7651 81.9581C46.6561 82.6793 46.5496 83.4008 46.4455 84.1226C45.9176 84.1796 45.3898 84.2374 44.8623 84.2961ZM47.7288 75.4187C47.4571 77.1196 47.1959 78.8208 46.9456 80.5225C48.0066 80.4173 49.0684 80.3215 50.131 80.2351C49.5897 78.6047 49.0378 76.9768 48.4755 75.3514C48.2255 75.3736 47.9766 75.396 47.7288 75.4187Z"
        fill="black"
      />
      <path
        d="M55.7829 79.871C55.8428 81.0452 55.9028 82.2173 55.9627 83.3873C55.4331 83.415 54.9037 83.4448 54.3744 83.4767L53.7876 73.6103C55.0917 73.5319 56.3966 73.4662 57.702 73.4134C60.0358 73.3209 61.2246 74.3569 61.2674 76.4478C61.2976 77.8524 60.7685 78.8142 59.6948 79.3477C60.2848 80.6238 60.864 81.9028 61.4322 83.1848C60.8498 83.1982 60.2673 83.2139 59.6848 83.232C59.1756 82.0745 58.6578 80.9188 58.1311 79.7647C57.3481 79.7953 56.5654 79.8307 55.7829 79.871ZM59.5772 76.5076C59.542 75.3257 58.935 74.75 57.7574 74.7973C57.0147 74.8274 56.2719 74.8616 55.5287 74.8998C55.5896 76.0962 55.6507 77.2913 55.7124 78.4853C56.4509 78.4471 57.19 78.4131 57.9298 78.3834C58.5168 78.3599 58.9421 78.1781 59.2055 77.838C59.4688 77.4979 59.5927 77.0544 59.5772 76.5076Z"
        fill="black"
      />
      <path
        d="M62.7271 83.1598C63.4819 79.8528 64.2803 76.5508 65.1223 73.2538C66.2329 73.2504 67.3441 73.2569 68.4562 73.2733C69.2639 76.5799 70.0288 79.8911 70.7509 83.2069C70.2171 83.1927 69.6827 83.1803 69.1476 83.1698C68.984 82.4599 68.8183 81.7499 68.6506 81.04C67.3808 81.0198 66.111 81.0122 64.8412 81.0173C64.6659 81.7256 64.4923 82.4336 64.3204 83.1416C63.7877 83.1453 63.2567 83.1513 62.7271 83.1598ZM66.4132 74.5968C65.9824 76.2638 65.5631 77.9324 65.155 79.6026C66.2202 79.5992 67.2853 79.6054 68.3506 79.6208C67.9656 77.9464 67.5699 76.2731 67.1636 74.6012C66.9101 74.5993 66.6629 74.5974 66.4132 74.5968Z"
        fill="black"
      />
      <path
        d="M72.2671 83.2537L72.6125 73.3779C73.5867 73.4127 74.5606 73.4547 75.5344 73.5038C76.4384 76.3759 77.2981 79.2539 78.1134 82.1378L78.3291 82.1523C78.517 79.3355 78.7057 76.5185 78.8953 73.7012C79.4517 73.7389 80.0084 73.779 80.5653 73.8214L79.8256 83.6771C78.9202 83.6087 78.0146 83.5458 77.1088 83.4883C76.2646 80.6031 75.3743 77.7237 74.4379 74.8499L74.2284 74.8404L73.8604 83.3167C73.3279 83.2932 72.7968 83.2722 72.2671 83.2537Z"
        fill="black"
      />
      <path
        d="M81.9888 75.3703C82.0282 74.8951 82.0676 74.4206 82.107 73.9468C84.6118 74.1564 87.1137 74.4154 89.6126 74.7236L89.4409 76.1415C88.4836 76.024 87.5255 75.9135 86.5668 75.8099C86.2687 78.612 85.9707 81.4145 85.673 84.217C85.14 84.1595 84.6072 84.1044 84.0746 84.0515L84.8993 75.6376C83.9293 75.5432 82.9591 75.4541 81.9888 75.3703Z"
        fill="black"
      />
      <path
        d="M89.8853 84.719L91.1495 74.9175C93.3059 75.2001 95.4586 75.5186 97.6078 75.873L97.3833 77.2569C95.7981 76.9955 94.2105 76.7538 92.6203 76.5316L92.2391 79.3043C93.5135 79.4821 94.7864 79.6731 96.0579 79.8774C95.9862 80.3336 95.9143 80.7897 95.8421 81.2455C94.5795 81.043 93.3156 80.8543 92.0504 80.6794C91.9187 81.6317 91.7874 82.5845 91.6566 83.5377C93.1902 83.7516 94.7208 83.9847 96.2485 84.2372L96.022 85.6323C93.9802 85.2923 91.9347 84.9878 89.8853 84.719Z"
        fill="black"
      />
      <path
        d="M97.6772 85.9082C98.2342 82.662 98.7911 79.4156 99.348 76.1689C101.494 76.5426 103.636 76.9523 105.772 77.3981C105.679 77.8548 105.584 78.3115 105.491 78.7682C103.914 78.4397 102.335 78.1307 100.754 77.841C100.589 78.7589 100.423 79.6771 100.257 80.5955C101.525 80.8269 102.791 81.0716 104.056 81.3295C103.965 81.7825 103.874 82.2351 103.783 82.6877C102.528 82.4318 101.27 82.1886 100.009 81.9579C99.8384 82.9048 99.6677 83.8513 99.4971 84.7974C101.023 85.0767 102.545 85.3748 104.065 85.6919C103.97 86.1532 103.875 86.6144 103.781 87.0757C101.752 86.6521 99.7175 86.2631 97.6772 85.9082Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_138_538"
          x1="-73.4626"
          y1="133.091"
          x2="35.0094"
          y2="87.7847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4A9AD" />
          <stop offset="0.17" stopColor="#A7ADB1" />
          <stop offset="0.25" stopColor="#AAB1B5" />
          <stop offset="0.29" stopColor="#CAD1D5" />
          <stop offset="0.33" stopColor="#E1E8EC" />
          <stop offset="0.37" stopColor="#EFF6F9" />
          <stop offset="0.4" stopColor="#F4FBFE" />
          <stop offset="0.63" stopColor="#F4FBFE" />
          <stop offset="0.75" stopColor="#979DA1" />
          <stop offset="0.83" stopColor="#B0B7BC" />
          <stop offset="0.85" stopColor="#959B9F" />
          <stop offset="0.87" stopColor="#7D8387" />
          <stop offset="0.89" stopColor="#6F7478" />
          <stop offset="0.91" stopColor="#6A6F73" />
          <stop offset="0.93" stopColor="#71767A" />
          <stop offset="0.95" stopColor="#84898E" />
          <stop offset="0.97" stopColor="#A4A9AE" />
          <stop offset="1" stopColor="#CBD1D6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_138_538"
          x1="205.141"
          y1="133.091"
          x2="96.6705"
          y2="87.7847"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A4A9AD" />
          <stop offset="0.17" stopColor="#A7ADB1" />
          <stop offset="0.25" stopColor="#AAB1B5" />
          <stop offset="0.29" stopColor="#CAD1D5" />
          <stop offset="0.33" stopColor="#E1E8EC" />
          <stop offset="0.37" stopColor="#EFF6F9" />
          <stop offset="0.4" stopColor="#F4FBFE" />
          <stop offset="0.63" stopColor="#F4FBFE" />
          <stop offset="0.75" stopColor="#979DA1" />
          <stop offset="0.83" stopColor="#B0B7BC" />
          <stop offset="0.85" stopColor="#959B9F" />
          <stop offset="0.87" stopColor="#7D8387" />
          <stop offset="0.89" stopColor="#6F7478" />
          <stop offset="0.91" stopColor="#6A6F73" />
          <stop offset="0.93" stopColor="#71767A" />
          <stop offset="0.95" stopColor="#84898E" />
          <stop offset="0.97" stopColor="#A4A9AE" />
          <stop offset="1" stopColor="#CBD1D6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_138_538"
          x1="33.7628"
          y1="4.65303"
          x2="100.411"
          y2="112.145"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CBD1D6" />
          <stop offset="0.09" stopColor="#C7CED2" />
          <stop offset="0.17" stopColor="#BCC3C7" />
          <stop offset="0.25" stopColor="#AAB1B5" />
          <stop offset="0.29" stopColor="#CAD1D5" />
          <stop offset="0.33" stopColor="#E1E8EC" />
          <stop offset="0.37" stopColor="#EFF6F9" />
          <stop offset="0.4" stopColor="#F4FBFE" />
          <stop offset="0.63" stopColor="#F4FBFE" />
          <stop offset="0.75" stopColor="#979DA1" />
          <stop offset="0.83" stopColor="#B0B7BC" />
          <stop offset="0.85" stopColor="#959B9F" />
          <stop offset="0.87" stopColor="#7D8387" />
          <stop offset="0.89" stopColor="#6F7478" />
          <stop offset="0.91" stopColor="#6A6F73" />
          <stop offset="0.93" stopColor="#71767A" />
          <stop offset="0.95" stopColor="#84898E" />
          <stop offset="0.97" stopColor="#A4A9AE" />
          <stop offset="1" stopColor="#CBD1D6" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_138_538"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(42.8037 32.2887) scale(138.318 139.575)"
        >
          <stop stopColor="#CBD1D6" />
          <stop offset="0.09" stopColor="#C7CED2" />
          <stop offset="0.17" stopColor="#BCC3C7" />
          <stop offset="0.25" stopColor="#AAB1B5" />
          <stop offset="0.29" stopColor="#CAD1D5" />
          <stop offset="0.33" stopColor="#E1E8EC" />
          <stop offset="0.37" stopColor="#EFF6F9" />
          <stop offset="0.4" stopColor="#F4FBFE" />
          <stop offset="0.61" stopColor="#717578" />
          <stop offset="0.63" stopColor="#F4FBFE" />
          <stop offset="0.75" stopColor="#979DA1" />
          <stop offset="0.83" stopColor="#B0B7BC" />
          <stop offset="0.85" stopColor="#959B9F" />
          <stop offset="0.87" stopColor="#7D8387" />
          <stop offset="0.89" stopColor="#6F7478" />
          <stop offset="0.91" stopColor="#6A6F73" />
          <stop offset="0.93" stopColor="#71767A" />
          <stop offset="0.95" stopColor="#84898E" />
          <stop offset="0.97" stopColor="#A4A9AE" />
          <stop offset="1" stopColor="#CBD1D6" />
        </radialGradient>
        <linearGradient
          id="paint4_linear_138_538"
          x1="65.8427"
          y1="17.0215"
          x2="65.8427"
          y2="106.603"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#01B3FF" />
          <stop offset="0.07" stopColor="#017EB3" />
          <stop offset="0.26" stopColor="#00A1E5" />
          <stop offset="0.31" stopColor="#0073B2" />
          <stop offset="0.44" stopColor="#004F91" />
          <stop offset="0.71" stopColor="#0083D9" />
          <stop offset="0.77" stopColor="#053361" />
          <stop offset="0.86" stopColor="#025D8A" />
          <stop offset="0.96" stopColor="#01B3FF" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_138_538"
          x1="65.8434"
          y1="130.976"
          x2="65.8434"
          y2="29.7767"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_138_538"
          x1="12.0181"
          y1="82.2545"
          x2="120.223"
          y2="82.2545"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CBD1D6" />
          <stop offset="0.05" stopColor="#C7CED2" />
          <stop offset="0.09" stopColor="#BCC3C7" />
          <stop offset="0.13" stopColor="#AAB1B5" />
          <stop offset="0.19" stopColor="#CAD1D5" />
          <stop offset="0.23" stopColor="#E1E8EC" />
          <stop offset="0.28" stopColor="#EFF6F9" />
          <stop offset="0.32" stopColor="#F4FBFE" />
          <stop offset="0.71" stopColor="#F4FBFE" />
          <stop offset="0.87" stopColor="#AAB1B5" />
          <stop offset="0.9" stopColor="#B8BFC3" />
          <stop offset="0.96" stopColor="#C6CCD1" />
          <stop offset="1" stopColor="#CBD1D6" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MoneyBackImage;
