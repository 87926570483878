import React from "react";
import CustomProgresBar from "../../components/CustomProgresBar/CustomProgresBar";
import { ProgressBar } from "../../styles";

const ProgressBarModule = ({ currentStep, steps, variantNumber }) => {
  const renderProgressBar = [
    "",
    <ProgressBar $progress={((currentStep + 1) / steps.length) * 100} />,
    <CustomProgresBar progress={((currentStep + 1) / steps.length) * 100} />,
  ];
  return (
    <>
      {currentStep !== 0 &&
        steps.length &&
        steps[currentStep].showProgressBar &&
        renderProgressBar[variantNumber]}
    </>
  );
};

export default ProgressBarModule;
