import React from "react";

import styles from "./Price.module.css";

const Price = ({ pricePerDay }) => {
  return (
    <div className={styles.optionStylePriceContainer}>
      <div className={styles.optionStyleDollar}>&#36;</div>
      <div className={styles.optionStyleFirstNumber}>
        {pricePerDay.charAt(0)}
      </div>
      <div className={styles.optionStyle_AfterDot_text}>
        <div className={styles.optionStyleElseNumber}>
          {pricePerDay.split(".")[1].slice(0, 2)}
        </div>

        <div className={styles.optionStyleText}>per day</div>
      </div>
    </div>
  );
};

export default Price;
