import React from "react";

import styles from "../PlanDetails.module.css";
const VariantB = ({ isActive, selectedOption, detailsText }) => {
  return (
    <div className={styles.PlanDetailsContainer}>
      <div className={styles.PlanDetailsTextContainer}>
        <span className={styles.PlanDetailsMonth}>
          <span>
            {selectedOption.optionPlanName} {selectedOption.fullPlanName}
          </span>
        </span>
        <span className={styles.PlanDetailsMonth}>
          &#36;
          {selectedOption.fullPlanPrice}
        </span>
      </div>
      {isActive && (
        <div className={styles.PlanDetailsTextContainer}>
          <span className={styles.PlanDetailsDiscount}>
            <span className={styles.sevenDayDiscount}>
              {detailsText.discountText}
            </span>
          </span>
          <span className={styles.PlanDetailsDiscountSavePrice}>
            -&#36;{selectedOption.savingsAmount}
          </span>
        </div>
      )}
      <div className={styles.Total}>
        <span className={styles.PlanDetailsTotalText}>
          {detailsText.totalText}
        </span>
        <span className={styles.PlanDetailsTotalText}>
          &#36;{selectedOption.priceTotal}
        </span>
      </div>
    </div>
  );
};

export default VariantB;
