import React from "react";

const GraySafeIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5374 4.38647C10.2068 4.18742 9.79325 4.18742 9.46268 4.38648L9.03242 4.64555C7.8814 5.33864 6.58921 5.76395 5.25155 5.88997L4.98048 5.91551C4.87346 5.92559 4.79169 6.01544 4.79169 6.12293V7.49283C4.79169 9.58245 5.64004 11.5825 7.14242 13.0349L9.85522 15.6574C9.93597 15.7355 10.0641 15.7355 10.1448 15.6574L12.8576 13.0349C14.36 11.5825 15.2084 9.58245 15.2084 7.49283V6.12293C15.2084 6.01544 15.1266 5.92559 15.0196 5.91551L14.7485 5.88997C13.4108 5.76395 12.1186 5.33864 10.9676 4.64555L10.5374 4.38647ZM8.81786 3.31563C9.54513 2.8777 10.4549 2.8777 11.1822 3.31563L11.6124 3.5747C12.6028 4.17108 13.7147 4.53704 14.8657 4.64548L15.1368 4.67102C15.8859 4.7416 16.4584 5.3705 16.4584 6.12293V7.49283C16.4584 9.9213 15.4724 12.2457 13.7264 13.9336L11.0136 16.5561C10.4484 17.1026 9.55168 17.1026 8.98642 16.5561L6.27362 13.9336C4.52761 12.2457 3.54169 9.92131 3.54169 7.49283V6.12293C3.54169 5.3705 4.11413 4.7416 4.86323 4.67102L5.1343 4.64548C6.28531 4.53704 7.39719 4.17108 8.38761 3.5747L8.81786 3.31563Z"
        fill="#AEACBF"
      />
    </svg>
  );
};

export default GraySafeIconSVG;
