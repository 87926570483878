import React from "react";
import Timer from "../Timer/Timer";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";

import styles from "./ImgModule.module.css";
import AfterBrain from "./svg/AfterBrain";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

const ImgModule = () => {
  const { offDiscount } = useFirebaseConfig();
  const { percentConfig, findDataComponent } = useConfig();
  const { showHideElement } = useTimer();

  const data = findDataComponent(1, "ImgModule");

  return (
    <div className={styles.ImgModuleContainer}>
      {showHideElement ? (
        <div className={styles.ImgModuleContainerHeader}>
          <span className={styles.ImgModuleContainerHeaderText}>
            {offDiscount === true
              ? `${data.discountText7dTrial}`
              : `-${percentConfig}${data.discountText}`}
          </span>
          <Timer />
        </div>
      ) : (
        false
      )}
      <div className={styles.ImgModuleContainerMain}>
        <div className={styles.ImgModuleContainerMainLeft}>
          <div className={styles.ImgModuleContainerMainLeftHeaderText}>
            {data.leftPicture.headerText}
          </div>
          <div className={styles.beforeBrain} />
          <div className={styles.ImgModuleContainerMainText}>
            <span className={styles.ImgModuleContainerMainTextHeadLeft}>
              {data.leftPicture.mainText}
            </span>
            <span className={styles.ImgModuleContainerMainTextFooter}>
              {data.leftPicture.lowText}
            </span>
          </div>
        </div>
        <div className={styles.ImgModuleContainerMainRectangleContainer}>
          <div className={styles.ImgModuleContainerMainRectangle}></div>
        </div>
        <div className={styles.ImgModuleContainerMainRight}>
          <div className={styles.ImgModuleContainerMainRightHeaderText}>
            {data.rightPicture.headerText}
          </div>
          <AfterBrain className={styles.ImgModuleContainerMainRightImg} />
          <div className={styles.ImgModuleContainerMainText}>
            <span className={styles.ImgModuleContainerMainTextHead}>
              {data.rightPicture.mainText}
            </span>
            <span className={styles.ImgModuleContainerMainTextFooter}>
              {data.rightPicture.lowText}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImgModule;
