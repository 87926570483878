import React, { useEffect, useState } from "react";

import { useConfig } from "../../../../context/ConfigDataContext";
import { useVWOContext } from "../../../../context/vwoContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import FooterBlock from "./FooterBlock/FooterBlock";
import Loading from "../Loading/Loading";

import AssetsContainer from "./AssetsContainer/AssetsContainer";
import VariantCsecond from "../ABTestPlanContainer/VariantCsecond/VariantCsecond";

import styles from "./SecondChoosePlan.module.css";

const SecondChoosePlan = () => {
  const { findDataComponent } = useConfig();
  const { variantNumber } = useVWOContext();
  const { discountPageData } = useFirebaseConfig();

  const data = findDataComponent(2, "SecondChoosePlan");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (variantNumber !== null) {
      setIsLoading(false);
    }
  }, [variantNumber]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={styles.ChoosePlanContainer}>
      <div className={styles.optionsCont}>
        <div className={styles.HeaderText}>
          {discountPageData.secondPlanOptionHeaderText}
        </div>
        <VariantCsecond />
        <FooterBlock />
      </div>
      <AssetsContainer data={data} />
    </div>
  );
};

export default SecondChoosePlan;
