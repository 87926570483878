import React, { createContext, useContext, useState, useEffect } from "react";
import { useConfig } from "./ConfigDataContext";
import { useTimer } from "./TimerContext";
import { useFirebaseConfig } from "./FirebaseConfigContext";
import { usePaymentForm } from "./PaymentFormContext";
import { useVWOContext } from "./vwoContext";

const RadioInputContext = createContext();

export const useRadioInput = () => useContext(RadioInputContext);

export const RadioInputProvider = ({ children }) => {
  const { offDiscount, abTestDesignVariant } = useFirebaseConfig();
  const { configCopy, specialDiscount } = useConfig();
  const { showHideElement } = useTimer();
  const { variantNumber } = useVWOContext();
  const { setCurrentOrder } = usePaymentForm();

  const [currentRender, setCurrentRender] = useState([]);
  const [selectedOption, setSelectedOption] = useState(0);
  const [textToPlan, setTextToPlan] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!configCopy) {
      setError("Error: Config data is not available.");
      return;
    }
    let index =
      offDiscount === true && showHideElement === true
        ? 0
        : offDiscount === true && showHideElement !== true
        ? 1
        : showHideElement && specialDiscount
        ? 2
        : showHideElement
        ? 1
        : 0;
    setCurrentRender(getOptionsByIndex(index));
    setSelectedOption(getDefaultOptionIndex(getOptionsByIndex(index)));
    setError(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHideElement, specialDiscount, configCopy]);

  useEffect(() => {
    if (currentRender.length === 0) {
      return;
    }
    const selectedOptionData = currentRender[selectedOption];
    setTextToPlan(selectedOptionData);
  }, [selectedOption, currentRender, setTextToPlan]);

  function getOptionsByIndex(index) {
    const monthsToChoose = configCopy.monthsToChoose;
    return monthsToChoose.map((element) => ({
      fullPlanName: configCopy.planName,
      ...(element.days && { days: element.days }),
      ...(element.month && { month: element.month }),
      trialDays: configCopy.trial_days,
      mostPopular: element.mostPopular,
      fullPlanPrice: element.fullPlanPrice,
      ...element.priceOptions[index],
    }));
  }

  function getDefaultOptionIndex(options) {
    return options.findIndex((option) => option.mostPopular);
  }

  const handleOptionChange = (index) => {
    setSelectedOption(index);
  };

  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === "/apply-discount") {
      return;
    }
    if (abTestDesignVariant === "A") {
      if (variantNumber === 1) {
        setCurrentOrder(currentRender[selectedOption]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variantNumber]);

  const contextValue = {
    textToPlan,
    selectedOption,
    currentRender,
    error,
    setTextToPlan,
    handleOptionChange,
  };

  return (
    <RadioInputContext.Provider value={contextValue}>
      {children}
    </RadioInputContext.Provider>
  );
};
