import React, { createContext, useContext } from "react";

const ButtonRefContext = createContext();

export const ButtonRefProvider = ({ children }) => {
  const buttonRef = React.createRef();
  const paymentButtonRef = React.createRef();

  const contextValue = {
    buttonRef,
    paymentButtonRef,
  };

  return (
    <ButtonRefContext.Provider value={contextValue}>
      {children}
    </ButtonRefContext.Provider>
  );
};

export const useButtonRef = () => {
  const { buttonRef, paymentButtonRef } = useContext(ButtonRefContext);

  return {
    buttonRef,
    paymentButtonRef,
  };
};
