import React from "react";
import { useButtonRef } from "../../../context/ButtonRefContext";
import { usePaymentBlock } from "../../../context/PaymentBlockContext";

import UserNameEmailStep from "./UserNameEmailStep/UserNameEmailStep";
import HasActiveSubscModal from "./HasActiveSubscModal/HasActiveSubscModal";
import PaymentForm from "./PaymentForm/PaymentForm";
import LoaderIcon from "../../Plan/loaderIcon";

import styles from "./PaymentBlock.module.css";
import { Loader } from "../../Plan/styles";

const PaymentBlock = ({ dataInfo }) => {
  const {
    loader,
    startNameEmailForm,
    startPaymentForm,
    startActiveSubscForm,
    loadingForm,
  } = usePaymentBlock();

  const { buttonRef } = useButtonRef();

  return (
    <div className={styles.PaymentFormContainer}>
      <button
        ref={buttonRef}
        onClick={async () => {
          loadingForm();
        }}
        style={{
          display: "none",
        }}
      />
      {startNameEmailForm ? <UserNameEmailStep dataInfo={dataInfo} /> : null}
      {startActiveSubscForm ? <HasActiveSubscModal /> : null}
      {startPaymentForm ? (
        <PaymentForm checkboxUpgrade={dataInfo.paymentForm.checkboxUpgrade} />
      ) : null}

      {loader ? (
        <Loader>
          <LoaderIcon />
        </Loader>
      ) : null}
    </div>
  );
};

export default PaymentBlock;
