import React from "react";
import { useABtest } from "../../../../../../../context/ABTestContext";

import styles from "../PlanDetails.module.css";

const VariantC = ({ data, selectedOption }) => {
  const { isActive } = useABtest();

  return (
    <div className={styles.vcContainer}>
      <span className={styles.totalTextVC}>{data.totalText}</span>
      <span className={styles.totalTextVC}>
        ${selectedOption.priceTotal}{" "}
        {isActive && (
          <span className={styles.trialTotalTextVC}>{data.trialTotalText}</span>
        )}
      </span>
    </div>
  );
};

export default VariantC;
