// import React from "react";
// import Timer from "../Timer/Timer";
// import { useScroll } from "../../../../context/ScrollContext";
// import { useTimer } from "../../../../context/TimerContext";
// import { useConfig } from "../../../../context/ConfigDataContext";

// import LogoGlobal from "../../../templates/Logo/LogoGlobal";
// import styles from "./TopDiscount.module.css";
// import { useVWOContext } from "../../../../context/vwoContext";

// const TopDiscount = () => {
//   const { findDataComponent, statusPaymentForm } = useConfig();
//   const { showHideElement } = useTimer();
//   const { scrollToComponent } = useScroll();

//   const { devMode, setDevMode } = useVWOContext();

//   const dataComponent = findDataComponent(2, "TopDiscount");

//   const handleButtonClick = () => {
//     setTimeout(() => {
//       scrollToComponent();
//     }, 50);
//   };

// const handleDevModeClick = () => {
//   if (devMode === false) {
//     setDevMode(true);
//   } else if (devMode === true) {
//     setDevMode(false);
//   }
// };

//   return (
//     <>
//       {!statusPaymentForm ? (
//         showHideElement ? (
//           <div className={styles.containerDiscount}>
//             <button
//               onClick={() => handleDevModeClick()}
//               style={{
//                 position: "absolute",
//                 left: "0",
//               }}
//             >
//               DevMode
//             </button>
//             <div className={styles.topLogo}>
//               <LogoGlobal />
//             </div>
//             <div className={styles.topContent}>
//               <div className={styles.containerDiscountInfo}>
//                 <div className={styles.containerDiscountTitle}>
//                   {dataComponent.text}
//                 </div>
//                 <div className={styles.containerDiscountTimer}>
//                   <Timer />
//                 </div>
//               </div>
//               <button
//                 className={styles.containerDiscountButton}
//                 onClick={handleButtonClick}
//               >
//                 <span className={styles.containerDiscountButtonText}>
//                   {dataComponent.buttonText}
//                 </span>
//               </button>
//             </div>
//           </div>
//         ) : null
//       ) : null}
//     </>
//   );
// };

// export default TopDiscount;

import React  from "react";
import Timer from "../Timer/Timer";
import { useScroll } from "../../../../context/ScrollContext";
import { useTimer } from "../../../../context/TimerContext";
import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./TopDiscount.module.css";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

const TopDiscount = () => {
  const { findDataComponent, statusPaymentForm } = useConfig();
  const { showHideElement } = useTimer();
  const { scrollToComponent } = useScroll();
  const dataComponent = findDataComponent(2, "TopDiscount");


  const handleButtonClick = () => {
    setTimeout(() => {
      scrollToComponent();
    }, 50);
  };


  return (
    <>
      {!statusPaymentForm && showHideElement && (
        <div className={styles.containerDiscount}>
          <div className={styles.topLogo}>
            <LogoGlobal />
          </div>
          <div className={styles.topContent}>
            <div className={styles.containerDiscountInfo}>
              <div className={styles.containerDiscountTitle}>
                {dataComponent.text}
              </div>
              <div className={styles.containerDiscountTimer}>
                <Timer />
              </div>
            </div>
            <button
              className={styles.containerDiscountButton}
              onClick={handleButtonClick}
            >
              <span className={styles.containerDiscountButtonText}>
                {dataComponent.buttonText}
              </span>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TopDiscount;
