import React from "react";

import styles from "./PrizesStyle.module.css";
const PrizesContainer = () => {
  const prizesData = [
    { id: 1, imgSrc: "/img/prizes_01.png", alt: "Kickstarter" },
    { id: 2, imgSrc: "/img/prizes_02.png", alt: "Google Brand Accelerator" },
    { id: 3, imgSrc: "/img/prizes_03.png", alt: "Memory Champion" },
    { id: 4, imgSrc: "/img/prizes_04.png", alt: "Techcrunch" },
  ];
  return (
    <div className={styles.prizesContainer}>
      {prizesData.map((prize) => (
        <div key={prize.id} className={styles.prizesFrame}>
          <img src={prize.imgSrc} alt={prize.alt} className={styles.prizesFrameImg} />
        </div>
      ))}
    </div>
  );
};
export default PrizesContainer;
