import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { Component, NewsContainer, NewsTitle } from "../../styles";

import VideoPlayer from "../../../templates/VideoPlayer/VideoPlayer";

import styles from "./YourGrandmaster.module.css";

const YourGrandmaster = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1,"YourGrandmaster");

  return (
    <div className={styles.container}>
      <Component $dark>
        <span className={styles.YourGranmasterHeaderText}>
          {data.headerText}
        </span>
        <span className={styles.YourGranmasterMainText}>{data.mainText}</span>
        <span className={styles.YourGranmasterMainText}>{data.footerText}</span>
        <div className={styles.awardsContainer}>
          {data.awards.map((element, index) => {
            return (
              <div key={index} className={styles.awardElement}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M7.58153 0.366236C7.32242 -0.122078 6.67758 -0.12208 6.41847 0.366236L4.59371 3.80521C4.46572 4.04643 4.24449 4.21104 3.99232 4.25269L0.572597 4.81753C-0.00176972 4.9124 -0.203242 5.70544 0.244068 6.11071L2.79162 8.41881C3.02743 8.63246 3.13831 8.96947 3.08103 9.29845L2.41463 13.1255C2.30929 13.7305 2.89004 14.2005 3.38835 13.9136L6.60845 12.0594C6.85353 11.9183 7.14647 11.9183 7.39156 12.0594L10.6117 13.9136C11.11 14.2005 11.6907 13.7305 11.5854 13.1255L10.919 9.29845C10.8617 8.96947 10.9726 8.63246 11.2084 8.41881L13.7559 6.11071C14.2032 5.70544 14.0018 4.9124 13.4274 4.81753L10.0077 4.25269C9.75552 4.21104 9.53428 4.04643 9.40629 3.80521L7.58153 0.366236Z"
                    fill="#FFD860"
                  />
                </svg>
                <span className={styles.awardText}>{element}</span>
              </div>
            );
          })}
        </div>
        <div
          style={{
            width: "100%",
            borderRadius: "16px",
            overflow: "hidden",
            marginBottom: "-20px",
          }}
        >
          <VideoPlayer
            videoId={data.linkVideo}
            thumbnail={data.linkPoster || ""}
          />
        </div>
        <NewsContainer>
          <NewsTitle>Featured on</NewsTitle>
          <div className={styles.newsLogoContainer}>
            <div className={styles.theTelegraphImg} />
            <div className={styles.bbcImg} />
            <div className={styles.theTimesImg} />
            <div className={styles.independentImg} />
            <div className={styles.nyTimesImg} />
          </div>
        </NewsContainer>
      </Component>
    </div>
  );
};

export default YourGrandmaster;
