import React from "react";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";

import SafeIconSVG from "./assets/SafeIcon";
import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";
import FooterTextContainer from "../../ChoosePlanSecond/FooterBlock/FooterTextContainer/FooterTextContainer";
import FooterTextContainerTwo from "../../ChoosePlanSecond/FooterBlock/FooterTextContainer/FooterTextContainerTwo";

import styles from "./FooterBlockStyle.module.css";

const FooterBlock = ({ data, footerData }) => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();

  return (
    <div className={styles.container}>
      <GraySafeBlock
        abTestDesignVariant={abTestDesignVariant}
        text={data.headerText}
        textSecond={data.headerTextOffDiscount}
      />

      <div className={styles.FooterSafe}>
        <div className={styles.FooterSafeIcon}>
          <SafeIconSVG />
        </div>
        <span className={styles.FooterSafeText}>{data.footerText}</span>
      </div>
      {abTestDesignVariant === "A" ? (
        <FooterTextContainer data={footerData} />
      ) : (
        <FooterTextContainerTwo
          data={optionTextData.firstPlanOptionBottomText}
        />
      )}
    </div>
  );
};

export default FooterBlock;
