import React, {
  createContext,
  useContext,
  // useEffect,
  // useState,
  // useRef,
} from "react";

const CoockieBotContext = createContext();

export const useCoockieBot = () => useContext(CoockieBotContext);

export const CoockieBotProvider = ({ children }) => {
  // const [consentChanged, setConsentChanged] = useState(false);
  // const isInitialLoad = useRef(true);
  // const hasInteracted = useRef(false);
  // const lastConsent = useRef(null);

  // useEffect(() => {
  //   const handleCookieConsentChange = () => {
  //     if (window.Cookiebot && window.Cookiebot.consent) {
  //       const { necessary, preferences, statistics, marketing } =
  //         window.Cookiebot.consent;
  //       const currentConsent = {
  //         necessary,
  //         preferences,
  //         statistics,
  //         marketing,
  //       };

  //       console.log("Current consent:", currentConsent);
  //       console.log("Last consent:", lastConsent.current);
  //       console.log("Is initial load:", isInitialLoad.current);
  //       console.log("Has interacted:", hasInteracted.current);

  //       if (isInitialLoad.current) {
  //         console.log("Initial load, setting last consent");
  //         lastConsent.current = currentConsent;
  //         isInitialLoad.current = false;
  //       } else {
  //         console.log("Not initial load, checking for changes");
  //         const consentChanged =
  //           JSON.stringify(currentConsent) !==
  //           JSON.stringify(lastConsent.current);

  //         if (consentChanged || hasInteracted.current) {
  //           console.log(
  //             "Consent changed or user interacted, triggering reload"
  //           );
  //           lastConsent.current = currentConsent;
  //           setConsentChanged(true);
  //         }
  //       }

  //       hasInteracted.current = false;
  //     }
  //   };

  //   const handleUserInteraction = () => {
  //     console.log("User interaction detected");
  //     hasInteracted.current = true;
  //     handleCookieConsentChange();
  //   };

  //   const initCookiebot = () => {
  //     if (window.Cookiebot) {
  //       handleCookieConsentChange();
  //     }
  //   };

  //   if (window.Cookiebot) {
  //     initCookiebot();
  //   } else {
  //     window.addEventListener("CookiebotOnLoad", initCookiebot);
  //   }

  //   window.addEventListener("CookiebotOnAccept", handleUserInteraction);
  //   window.addEventListener("CookiebotOnDecline", handleUserInteraction);

  //   return () => {
  //     window.removeEventListener("CookiebotOnLoad", initCookiebot);
  //     window.removeEventListener("CookiebotOnAccept", handleUserInteraction);
  //     window.removeEventListener("CookiebotOnDecline", handleUserInteraction);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (consentChanged) {
  //     window.location.reload();
  //   }
  // }, [consentChanged]);

  const contextValue = {
    // consentChanged,
  };

  return (
    <CoockieBotContext.Provider value={contextValue}>
      {children}
    </CoockieBotContext.Provider>
  );
};

// import React, {
//   createContext,
//   useContext,
//   useEffect,
//   useState,
//   useRef,
// } from "react";

// const CoockieBotContext = createContext();

// export const useCoockieBot = () => useContext(CoockieBotContext);

// export const CoockieBotProvider = ({ children }) => {
//   const [consentChanged, setConsentChanged] = useState(false);
//   const isFirstInteraction = useRef(true);
//   const lastConsent = useRef(null);

//   useEffect(() => {
//     const handleCookieConsentChange = () => {
//       if (window.Cookiebot && window.Cookiebot.consent) {
//         const { necessary, preferences, statistics, marketing } =
//           window.Cookiebot.consent;
//         const currentConsent = {
//           necessary,
//           preferences,
//           statistics,
//           marketing,
//         };

//         console.log("Current consent:", currentConsent);
//         console.log("Last consent:", lastConsent.current);

//         if (
//           isFirstInteraction.current ||
//           JSON.stringify(currentConsent) !== JSON.stringify(lastConsent.current)
//         ) {
//           console.log("Consent changed or first interaction detected");

//           lastConsent.current = currentConsent;

//           if (!isFirstInteraction.current) {
//             console.log("Triggering reload");
//             setConsentChanged(true);
//           } else {
//             console.log("First interaction, not reloading");
//             isFirstInteraction.current = false;
//           }
//         }
//       }
//     };

//     const initCookiebot = () => {
//       if (window.Cookiebot) {
//         handleCookieConsentChange();
//       }
//     };

//     if (window.Cookiebot) {
//       initCookiebot();
//     } else {
//       window.addEventListener("CookiebotOnLoad", initCookiebot);
//     }

//     window.addEventListener("CookiebotOnAccept", handleCookieConsentChange);
//     window.addEventListener("CookiebotOnDecline", handleCookieConsentChange);

//     return () => {
//       window.removeEventListener("CookiebotOnLoad", initCookiebot);
//       window.removeEventListener(
//         "CookiebotOnAccept",
//         handleCookieConsentChange
//       );
//       window.removeEventListener(
//         "CookiebotOnDecline",
//         handleCookieConsentChange
//       );
//     };
//   }, []);

//   useEffect(() => {
//     if (consentChanged) {
//       window.location.reload();
//     }
//   }, [consentChanged]);

//   const contextValue = {
//     consentChanged,
//   };

//   return (
//     <CoockieBotContext.Provider value={contextValue}>
//       {children}
//     </CoockieBotContext.Provider>
//   );
// };

// import React, { createContext, useContext, useEffect, useState } from "react";

// const CoockieBotContext = createContext();

// export const useCoockieBot = () => useContext(CoockieBotContext);

// export const CoockieBotProvider = ({ children }) => {
//   const [consentChanged, setConsentChanged] = useState(false);
//   const [userInteracted, setUserInteracted] = useState(false);

//   useEffect(() => {
//     let isInitialLoad = true;
//     let lastReloadTime = 0;

//     const getStoredConsent = () => {
//       const storedConsent = localStorage.getItem("cookiebotConsent");
//       return storedConsent ? JSON.parse(storedConsent) : null;
//     };

//     const setStoredConsent = (consent) => {
//       localStorage.setItem("cookiebotConsent", JSON.stringify(consent));
//     };

//     const handleCookieConsentChange = () => {
//       if (window.Cookiebot && window.Cookiebot.consent) {
//         const { necessary, preferences, statistics, marketing } =
//           window.Cookiebot.consent;
//         const currentConsent = {
//           necessary,
//           preferences,
//           statistics,
//           marketing,
//         };
//         const storedConsent = getStoredConsent();

//         console.log("Current consent:", currentConsent);
//         console.log("Stored consent:", storedConsent);

//         if (isInitialLoad) {
//           if (!storedConsent) {
//             setStoredConsent(currentConsent);
//           }
//           isInitialLoad = false;
//           return;
//         }

//         const hasChanged =
//           !storedConsent ||
//           Object.keys(currentConsent).some(
//             (key) => currentConsent[key] !== storedConsent[key]
//           );

//         console.log("Has changed:", hasChanged);

//         const currentTime = new Date().getTime();
//         const timeSinceLastReload = currentTime - lastReloadTime;

//         if ((hasChanged || userInteracted) && timeSinceLastReload > 5000) {
//           console.log(
//             "Consent settings changed or user interacted, updating stored consent and reloading..."
//           );
//           setStoredConsent(currentConsent);
//           lastReloadTime = currentTime;
//           setConsentChanged(true);
//         }
//         setUserInteracted(false);
//       }
//     };

//     const initCookiebot = () => {
//       if (window.Cookiebot) {
//         handleCookieConsentChange();
//       }
//     };

//     if (window.Cookiebot) {
//       initCookiebot();
//     } else {
//       window.addEventListener("CookiebotOnLoad", initCookiebot);
//     }

//     const handleUserInteraction = () => {
//       setUserInteracted(true);
//       handleCookieConsentChange();
//     };

//     window.addEventListener("CookiebotOnAccept", handleUserInteraction);
//     window.addEventListener("CookiebotOnDecline", handleUserInteraction);

//     const checkInterval = setInterval(handleCookieConsentChange, 1000);

//     return () => {
//       window.removeEventListener("CookiebotOnLoad", initCookiebot);
//       window.removeEventListener("CookiebotOnAccept", handleUserInteraction);
//       window.removeEventListener("CookiebotOnDecline", handleUserInteraction);
//       clearInterval(checkInterval);
//     };
//   }, [userInteracted]);

//   useEffect(() => {
//     if (consentChanged) {
//       window.location.reload();
//     }
//   }, [consentChanged]);

//   const contextValue = {
//     consentChanged,
//   };

//   return (
//     <CoockieBotContext.Provider value={contextValue}>
//       {children}
//     </CoockieBotContext.Provider>
//   );
// };
