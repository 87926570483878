import styled from "styled-components";
import { mediaQueries } from "../../constants/breakpoints";
import ReactPlayer from "react-player";

export const Page = styled.div`
  width: 100%;
  // height: 5750px;
  border: 1px solid transparent;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #3e3a54 0%, #120b3b 100%);
  position: relative;
  overflow: hidden;
  font-size: 14px;
  @media ${mediaQueries.desktop} {
    font-size: 16px;
    // height: 5700px;
  }
`;

export const Asset1 = styled.div`
  position: absolute;
  width: 562px;
  height: 762px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg1.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  top: -61px;
  right: -202px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  @media ${mediaQueries.desktop} {
    width: 841px;
    height: 1139px;
    top: -38px;
    right: -233px;
  }
`;

export const Asset2 = styled.div`
  position: absolute;
  width: 829px;
  height: 664px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg2.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  top: 1035px;
  left: -429px;
  @media ${mediaQueries.desktop} {
    width: 1435px;
    height: 1149px;
    top: 864px;
    left: -499px;
  }
`;
export const Asset3 = styled.div`
  position: absolute;
  width: 611px;
  height: 684px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg3.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  bottom: 319px;
  left: -76px;
  @media ${mediaQueries.desktop} {
    width: 1156px;
    height: 1294px;
    bottom: 110px;
    left: -98px;
  }
`;
export const Asset4 = styled.div`
  position: absolute;
  width: 1053px;
  height: 1022px;
  background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
    process.env.REACT_APP_TRECI_PACKAGE}/img/bg4.webp")
    no-repeat 0 0 / cover;
  opacity: 0.5;
  bottom: -346px;
  right: -281px;
  @media ${mediaQueries.desktop} {
    width: 1053px;
    height: 1022px;
    bottom: -346px;
    right: -281px;
  }
`;
export const Notification = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 32px;
  background: rgba(21, 19, 32, 0.5);
  span {
    font-size: 14px;
    line-height: 14px;
    b {
      font-size: 10px;
      font-weight: 600;
      text-decoration: underline;
    }
  }
  @media ${mediaQueries.desktop} {
  }
`;

export const Header = styled.div`
  width: 90%;
  margin: 24px auto 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${mediaQueries.desktop} {
    width: 80%;
    justify-content: space-between;
    margin: 16px auto 0;
  }
`;

export const Logo = styled.img`
  width: 120px;
  height: 40px;
  @media ${mediaQueries.desktop} {
    width: 180px;
    height: 58px;
  }
`;

export const Nav = styled.div`
  display: flex;
  align-items: center;
  display: none;
  a {
    text-decoration: none;
    color: #aeacbf;
    font-weight: 600;
    display: inline-block;
    margin-right: 40px;
    transition: color 0.2s;
    :hover {
      color: #fff;
    }
  }
`;

export const Container = styled.div`
  max-width: 736px;
  margin: 0px auto;
  margin-top: 20px;
  font-family: Inter, sans-serif;
  width: 100%;
  position: relative;
  @media ${mediaQueries.desktop} {
    padding: 0 0 10px;
  }
`;
export const StickyButton = styled.div`
  display: block;
  width: 100%;
  padding: 0 16px;
  margin-top: 32px;
  position: sticky;
  bottom: 0px;
  z-index: 200;
  padding: 16px 16px 24px;
  background: linear-gradient(180deg, #3e3a54 0%, #120b3b 100%);
  border-radius: 16px 16px 0px 0px;
  transition: bottom 0.2s;
  a {
    max-width: 100%;
  }
  div {
    text-align: center;
    line-height: 22px;
    font-size: 13px;
    margin-top: 15px;
    span {
      color: #4beeb3;
      font-size: 11.5px;
      display: block;
    }
  }
  ${(props) =>
    props.$isSticky === true
      ? `
        bottom: 0;
      `
      : ``}
  @media ${mediaQueries.desktop} {
    bottom: -150px;
    max-width: 736px;
    left: 50%;
    transform: translateX(-50%);
    ${(props) =>
      props.$isDesktopSticky === true
        ? `
          bottom: 0;
        `
        : ``}
  }
`;
export const Button = styled.a`
  box-sizing: border-box;
  display: flex;
  text-decoration: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 8px;
  color: #151320;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  background: #4beeb3;
  opacity: 1;
  cursor: pointer;
  max-width: 257px;
  transition: opacity 0.2s;
  white-space: nowrap;
  :hover {
    opacity: 0.9;
  }
  ${(props) =>
    props.$small &&
    `
        padding: 10px 16px;
        font-size: 14px;
        line-height: 16px;
      `}
  ${(props) =>
    props.$white &&
    `
        background: transparent;
        border: 1px solid #fff;
        color: #fff;
      `}
  ${(props) =>
    props.$desktop &&
    `
        display: none;
        @media ${mediaQueries.desktop} {
          display: flex;
        }
      `}
  ${(props) =>
    props.$mobile &&
    `
        min-width: 100%;
        @media ${mediaQueries.desktop} {
          display: none;
        }
      `}
`;

export const Hero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 16px auto 0;
  align-items: center;
  justify-centent: space-between;
  padding: 0 16px;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    padding: 0;
    margin: 50px auto 0;
  }
`;
export const HeroColumn = styled.div`
  flex: 0 0 48%;
`;
export const HeroTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 16px;
  color: #fff;
  text-align: center;
  div {
    color: #4beeb3;
  }
  @media ${mediaQueries.desktop} {
    text-align: left;
    font-size: 48px;
    line-height: 64px;
  }
`;
export const HeroDescription = styled.div`
  box-sizing: border-box;
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  @media ${mediaQueries.desktop} {
    text-align: left;
  }
`;
export const HeroSocial = styled.div`
  display: flex;
  align-items: center;
  margin: 32px 0 16px;
  img {
    width: 56px;
    height: 24px;
    margin-right: 12px;
  }
  span {
    font-size: 12px;
    line-height: 16px;
  }
  ${(props) =>
    props.$desktop &&
    `
        display: none;
        @media ${mediaQueries.desktop} {
          display: flex;
        }
      `}
  ${(props) =>
    props.$mobile &&
    `
        @media ${mediaQueries.desktop} {
          display: none;
        }
      `}
`;
export const HeroPic = styled.picture`
  border: 0;
  img {
    max-width: 100%;
    @media ${mediaQueries.desktop} {
      margin-bottom: 0;
      position: relative;
      right: -50px;
    }
  }
`;
export const HeroButton = styled.div`
  background: rgba(21, 19, 32, 0.5);
  border-radius: 12px;
  padding: 34px 15px 15px;
  position: relative;
  top: -3px;
  width: 100%;
  div {
    text-align: center;
    line-height: 22px;
    font-size: 13px;
    margin-top: 15px;
    span {
      color: #4beeb3;
      font-size: 11.5px;
      display: block;
    }
  }
  @media ${mediaQueries.desktop} {
    display: none;
  }
`;
export const Component = styled.div`
  background: rgba(232, 237, 255, 0.2);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;
  margin-top: 36px;
  @media ${mediaQueries.desktop} {
    margin-top: 40px;
  }
  ${(props) =>
    props.$light &&
    `
      background: linear-gradient(110.46deg, rgba(99, 191, 230, 0.5) 33.37%, rgba(145, 162, 255, 0.5) 63.44%);
    `}
  ${(props) =>
    props.$dark &&
    `
      background: rgba(21, 19, 32, 0.5);
    `}
  ${(props) =>
    props.$inner &&
    `
      width: 100%;
      gap: 16px;
      margin-top: 0;
      align-items: flex-start;
    `}
  ${(props) =>
    props.$plain &&
    `
      width: 100%;
      display: block;
      margin-top: 20px;
    `}
  .slick-slider {
    padding: 20px 0 0;
    @media ${mediaQueries.desktop} {
      padding: 0;
    }
  }
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
  .slick-dots {
    z-index: 0;
    width: 300px !important;
    bottom: 10px;
    ul {
      display: flex;
      justify-content: center;
    }
    li {
      button:before {
        color: #00558b;
      }
      &.slick-active {
        button:before {
          color: #fff;
        }
      }
    }
  }
  .slick-slide {
    > div {
      > div {
        height: 388px;
        margin-top: 0;
        margin-bottom: 30px;
        @media ${mediaQueries.desktop} {
          height: 300px;
        }
      }
    }
  }
`;
export const ComponentTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  @media ${mediaQueries.desktop} {
    font-size: 32px;
    line-height: 40px;
  }
  ${(props) =>
    props.$icon &&
    `
      text-align: left;
      display: flex;
      align-items: center;
      width: 100%;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      img {
        margin-right: 16px;
      }
    `}
  span.green {
    color: #4beeb3;
  }
  ${(props) =>
    props.$leftAligned &&
    `
      text-align: left;
      line-height: 24px;
      font-size: 16px;
      width: 100%;
      span.large {
        color: #fff;
        font-size: 24px;
        text-transform: uppercase;
      }
      @media ${mediaQueries.desktop} {
        line-height: 32px;
        font-size: 24px;
        text-align: center;
        span.large {
          font-size: 32px;
          text-transform: uppercase;
        }
      }
    `}
`;
export const ComponentSubTitle = styled.div`
  width: 100%;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
`;
export const ComponentText = styled.div`
  font-size: 14px;
  line-height: 22px;
`;
export const ExpandableText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  p {
    margin: 0 0 16px;
  }
`;
export const ToggleButton = styled.button`
  border: none;
  background: transparent;
  font-weight: bold;
  color: #4beeb3;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
`;
export const StyledReactPlayer = styled(ReactPlayer)`
  width: 688px;
  height: 320px;
  border-radius: 16px;
  overflow: hidden;
  .react-player__play-icon {
    background: url("${process.env.REACT_APP_MEDIA_ENDPOINT +
      process.env.REACT_APP_TRECI_PACKAGE}/img/play.svg")
      no-repeat 0 0;
    width: 64px;
    height: 64px;
    border: none !important;
    margin-left: 0 !important;
  }
`;
export const Prizes = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-around;
  img {
    max-width: 150px;
  }
  @media ${mediaQueries.desktop} {
    gap: 28px;
  }
`;
export const NewsContainer = styled.div``;
export const NewsTitle = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 11px;
  line-height: 22px;
  color: rgba(194, 194, 195, 0.5);
  margin-bottom: 12px;
  @media ${mediaQueries.desktop} {
    font-size: 14px;
  }
`;
export const News = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  img {
    :nth-child(1) {
      width: 38%;
    }
    :nth-child(2) {
      width: 15%;
      height: 15px;
      @media ${mediaQueries.desktop} {
        height: 31px;
      }
    }
    :nth-child(3) {
      width: 38%;
    }
    :nth-child(4) {
      width: 40%;
    }
    :nth-child(5) {
      width: 40%;
    }
  }
  @media ${mediaQueries.desktop} {
    gap: 10px;
    img {
      width: 20%;
    }
  }
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 100%;
  div {
    display: flex;
    align-items: center;
    &.highlight {
      background: rgba(232, 237, 255, 0.2);
      border-radius: 12px;
      padding: 10px 10px 10px 0;
      span {
        display: flex;
        flex-direction: column;
        img {
          width: 180px;
          margin-bottom: 10px;
        }
      }
    }
  }
  img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
  }
  i {
    font-size: 24px;
    font-style: normal;
    width: 35px;
  }

  ${(props) =>
    props.$thin &&
    `
      img {
        margin-right: 12px;
      }
    `}
  ${(props) =>
    props.$center &&
    `
      @media ${mediaQueries.desktop} {
        padding-left: 20%;
      }
    `}
`;
export const Tags = styled.div`
  width: 100%;
`;
export const TagsTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
`;
export const TagsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
export const Tag = styled.div`
  background: rgba(232, 237, 255, 0.2);
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 24px;
  margin: 8px 8px 0 0;
`;

export const Rating = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0;
  img {
    margin-right: 10px;
    width: 22px;
    height: 20px;
    &.part {
      width: 25px;
      height: 25px;
    }
  }
`;
export const Feedback = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
`;
export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  flex-direction: column-reverse;
  gap: 10px;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
  }
`;
export const Copy = styled.div`
  font-size: 12px;
  line-height: 16px;
`;
export const Viewing = styled.div`
  background: rgba(232, 237, 255, 0.06);
  border-radius: 8px;
  padding: 5px 10px;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-top: 16px;
  justify-content: center;
  font-size: 12px;
  color: #6d8dff;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`;
export const EarlyAdopters = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
export const FooterNav = styled.div`
  a {
    color: #fff;
    margin-left: 16px;
    text-decoration: none;
    font-size: 12px;
    line-height: 16px;
  }
`;
