import React from "react";

import Loading from "../../../Plan/PaidTrialsAllSubs/Loading/Loading";

import styles from "./TopComponentStyle.module.css";

const TopComponent = ({
  userName,
  dataComponent,
  orderData,
  subscriptionText,
  renewalPlan,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.welcomeUserText}>
        <span className={styles.userNameText}>{userName}</span>
        <span>{dataComponent.welcomeText}</span>
      </div>
      <div className={styles.subscContainer}>
        <span className={styles.subscContainerText}>
          {dataComponent.subscText}
        </span>
        {orderData && orderData.planExpiration ? (
          <>
            <span className={styles.subscContPlanText}>
              {orderData.varOption === 2
                ? orderData.planExpirationDate === "YEARLY" || orderData.planExpirationDate === "MONTHLY"
                  ? `${orderData.optionPlanName} ${orderData.planName}`
                  : `${orderData.optionPlanName} ${orderData.planName}, 7 day trial`
                : subscriptionText(
                    orderData.planExpirationDate,
                    orderData.planExpiration,
                    orderData.planName,
                    dataComponent.trialText.enable,
                    dataComponent.trialText.text
                  )}
            </span>
            <span className={styles.subscContExpText}>
              {orderData.varOption === 2
                ? null
                : `Renewal: ${
                    orderData.trialDays > 0
                      ? renewalPlan("days", orderData.trialDays)
                      : renewalPlan(
                          orderData.planExpiration,
                          orderData.planExpirationDate
                        )
                  }`}
            </span>
          </>
        ) : orderData && !orderData.planExpiration ? (
          <>
            <span className={styles.subscContPlanText}>
              {orderData.planName}
            </span>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default TopComponent;
