import React from "react";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";
// import NewLogo from "../../../templates/NewLogo/NewLogo";

const LogoModule = ({ variantNumber }) => {
  // return <>{variantNumber === 1 ? <LogoGlobal /> : <NewLogo />}</>;
  return <LogoGlobal />;
};

export default LogoModule;
