import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import { useDownSale } from "../../../../context/DownSaleContext";

import styles from "./SpecialOfferButtonStyle.module.css";

const SpecialOfferButton = () => {
  const { findDataComponent } = useConfig();

  const { setShowHideSpecialOfferPopup } = useDownSale();

  const { timer } = useTimer();

  const data = findDataComponent(2, "NewPaymentButton");

  const handleButtonClick = () => {
    setShowHideSpecialOfferPopup(true);
  };
  return (
    <div className={styles.btnContainer}>
      <button className={styles.paymentButton} onClick={handleButtonClick}>
        <span className={styles.paymentButtonText}>
          {timer > 0 ? data.buttonText[1] : data.buttonText[0]}
        </span>
        <span className={styles.arrow} />
      </button>
    </div>
  );
};

export default SpecialOfferButton;
