import React from "react";

import styles from "../ElementsSliderStyles.module.css";

const VariantB = ({ renderData }) => {
  return (
    <div className={styles.sliderContainer}>
      {renderData.map((step, index) => {
        return (
          <div key={index} className={styles.thirdStepsContainer}>
            <span className={styles.thirdStepsText}>{step}</span>
          </div>
        );
      })}
    </div>
  );
};

export default VariantB;
