import React from "react";

import styles from "./FooterStyle.module.css";

const Footer = ({ data }) => {

  return (
    <div className={styles.container}>
      <span className={styles.missionText}>{data.Mission}</span>
      <div className={styles.bottomContainer}>
        <div className={styles.hrLine} />
        <div className={styles.compInfoContainer}>
          <a href={data.Terms[1]} className={styles.compInfoLink} target="_blank" rel="noopener noreferrer">
            {data.Terms[0]}
          </a>
          <div className={styles.hrVertLine} />
          <a href={data.Cookie[1]} className={styles.compInfoLink} target="_blank" rel="noopener noreferrer">
            {data.Cookie[0]}
          </a>
          <div className={styles.hrVertLine} />
          <a href={data.Privacy[1]} className={styles.compInfoLink} target="_blank" rel="noopener noreferrer">
            {data.Privacy[0]}
          </a>
        </div>
        <span className={styles.compInfoText}>{data.Company}</span>
      </div>
    </div>
  );
};

export default Footer;
