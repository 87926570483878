import React, { useState, useEffect } from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useDownSale } from "../../../../context/DownSaleContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";
import SpecialOfferPaymentButton from "../PaymentButton/SpecialOfferPaymentButton";
import Loader from "../../../templates/Loader/Loader";

import styles from "./SpecialOfferPopupStyle.module.css";

const SpecialOfferPopup = () => {
  const { downSalePrice } = useFirebaseConfig();
  const { findDataComponent } = useConfig();
  const { setShowHideSpecialOfferPopup } = useDownSale();
  const data = findDataComponent(2, "SpecialOfferPopup");
  const [activeIndex, setActiveIndex] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);  

  useEffect(() => {
    const defaultIndex = data.trialPriceOptions.findIndex(
      (element) => element.mostCommon
    );
    if (defaultIndex !== -1) {
      handleButtonClick(defaultIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeIndex >= 0) {
      downSalePrice(activeIndex);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  const handleButtonClick = (elementIndex) => {
    setActiveIndex(elementIndex);
  };

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <div className={styles.shadowContainer}>
      <div className={styles.ScrollContainer}>
        <div className={styles.container}>
          <button
            className={styles.closeButton}
            onClick={() => setShowHideSpecialOfferPopup(false)}
          >
            <img
              src="../img/SpecialOfferCloseImg.png"
              alt="SpecialOfferCloseImg"
              className={styles.closeButtonImg}
            />
          </button>
          <div className={styles.topTextContainer}>
            <span className={styles.topText}>{data.topText}</span>
            <span className={styles.mainText}>{data.mainText}</span>
          </div>
          {!imageLoaded && <Loader />}
          <img
            src="../img/elephantWithHeart.png"
            alt="elephantWithHeart"
            className={styles.elephantWithHeart}
            onLoad={handleImageLoad}
            style={{ display: imageLoaded ? "block" : "none" }}
          />
          <div className={styles.specialPriceContainer}>
            {data.trialPriceOptions.map((element, index) => (
              <button
                key={index}
                className={`${styles.priceElement} ${
                  index === activeIndex ? styles.priceElementActive : ""
                }`}
                onClick={() => handleButtonClick(index)}
              >
                <span className={styles.textElement}>{element.price}</span>
              </button>
            ))}
            <div className={styles.mostCommon}>
              <span className={styles.mostCommonText}>
                {data.mostCommonText}
              </span>
            </div>
          </div>
          <SpecialOfferPaymentButton />
        </div>
      </div>
    </div>
  );
};

export default SpecialOfferPopup;
