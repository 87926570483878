import React from "react";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import VariantA from "./RenderVariant/VariantA";
import VariantB from "./RenderVariant/VariantB";

import styles from "./PleaseNoteStyle.module.css";

const PleaseNote = () => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();

  return (
    <div className={styles.container}>
      {abTestDesignVariant === "A" ? (
        <VariantA />
      ) : (
        <VariantB textData={optionTextData.pleaseNote} />
      )}
    </div>
  );
};

export default PleaseNote;
