import React from "react";
import { useButtonRef } from "../../../context/ButtonRefContext";

import LogoGlobal from "../../templates/Logo/LogoGlobal";
import VideoPlayer from "./VideoPlayer/VideoPlayer";

import styles from "./MainInfoStyle.module.css";

const MainInfo = ({ data }) => {
  const { buttonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <span className={styles.headerMainText}>{data.Header.mainText}</span>
        <span className={styles.headerBottomText}>
          {data.Header.bottomText}
        </span>
      </div>
      <VideoPlayer videoLink={data.linkVideo} />

      <div className={styles.mainContent}>
        <LogoGlobal styleData={{ width: "151px", height: "45px" }} />
        <div className={styles.partTextContainer}>
          {data.MainContent.textParts.map((text, index) => {
            return (
              <div key={index} className={styles.mainPartsText}>
                {text}
              </div>
            );
          })}
        </div>
        <div className={styles.btnContainer}>
          <div className={styles.button} onClick={handleButtonClick}>
            <div className={styles.btnTextContainer}>
              <span className={styles.buttonText}>
                {data.MainContent.buttonText[0]}
              </span>
              <span className={styles.buttonTextDesktop}>
                {data.MainContent.buttonText[1]}
              </span>
            </div>
            <div className={styles.starOneL} />
            <div className={styles.starTwoL} />
            <div className={styles.starThreeL} />
            <div className={styles.starOneR} />
            <div className={styles.starTwoR} />
            <div className={styles.starThreeR} />
          </div>
          <a
            href={
              data.MainContent.underBtnLink + "?utm_source=reserve_redirect"
            }
            className={styles.underBtnText}
            target="_blank"
            rel="noopener noreferrer"
          >
            {data.MainContent.underBtnText}
          </a>
        </div>
        <span className={styles.footerText}>{data.MainContent.footerText}</span>
      </div>
    </div>
  );
};

export default MainInfo;
