import React, { useEffect, useState } from "react";
import ElementsSlider from "../ElementsSlider/ElementsSlider";

import styles from "../WithinMemoryOS.module.css";

const VariantB = ({ variantNumber, data }) => {
  const imgName = [
    "withinBlockComment.png",
    "withinBlockTimer.png",
    "withinBlockStar.png",
    "withinBlockUniversity.png",
    "withinBlockHeart.png",
    "withinBlockJug.png",
    "withinBlockBank.png",
  ];

  const [renderData, setRenderData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (variantNumber !== null && data !== null) {
      setIsLoading(false);
      switch (variantNumber) {
        case 1:
          setRenderData(data.variantA);
          break;
        case 2:
          setRenderData(data.variantB);
          break;
        case 3:
          setRenderData(data.variantC);
          break;
        default:
          console.log("Error");
          break;
      }
    } else {
      setIsLoading(true);
    }
  }, [data, variantNumber]);

  return (
    <>
      <div className={styles.secondContainer}>
        {!isLoading &&
          renderData.topAchive.map((text, index) => {
            return (
              <div key={index} className={styles.secondElement}>
                <img
                  src={`/img/${imgName[index]}`}
                  alt={`img${index}`}
                  className={styles.secondImg}
                />
                {text.length > 1 ? (
                  <>
                    <span className={styles.solidText}>{text[0]} </span>
                    <span
                      className={`${styles.secondStepText} ${styles.stepTextMargin}`}
                    >
                      {text[1]}
                    </span>
                  </>
                ) : (
                  <span className={styles.secondStepText}>{text[0]}</span>
                )}
              </div>
            );
          })}
      </div>
      <div className={styles.hrLine} />
      {!isLoading && <ElementsSlider renderData={renderData.botAchive} />}
    </>
  );
};

export default VariantB;
