import React from "react";

import styles from "./ElementsSliderStyles.module.css";

const ElementsSlider = ({ data }) => {
  return (
    <div className={styles.container}>
      <span className={styles.thirdStepsHeaderText}>
        {data.thirdStepsHeaderText}
      </span>
      <div className={styles.sliderContainer}>
        {data.thirdSteps.map((step, index) => {
          return (
            <div key={index} className={styles.thirdStepsContainer}>
              <span className={styles.thirdStepsText}>{step}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ElementsSlider;
