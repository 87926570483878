import React from "react";

import Price from "../Price/Price";

import styles from "../VariantСStyle.module.css";

const FirstPlanOption = ({
  firstOptionRender,
  selectedOption,
  setSelectedDiscountOption,
}) => {
  const randomId = Math.round(Math.random() * 1000000);
  return (
    <label
      className={`${styles.optionStyle} ${
        selectedOption === 0 ? styles.actives : ""
      }`}
      htmlFor={`${firstOptionRender.productID}${randomId}`}
      key={firstOptionRender.productID}
    >
      {firstOptionRender.mostPopular && (
        <div className={styles.mostPopular}>
          <span className={styles.mostPopularText}>MOST POPULAR</span>
        </div>
      )}
      <div className={styles.optionStyleRadioOne}>
        <input
          type="radio"
          name={firstOptionRender.month || firstOptionRender[0].days}
          id={`${firstOptionRender.productID}${randomId}`}
          value={firstOptionRender.month || firstOptionRender[0].days}
          checked={selectedOption === 0}
          onChange={() => setSelectedDiscountOption(0)}
        />
        <span
          className={`${styles.customCheckboxOne} ${
            selectedOption === 0 ? styles.customCheckboxOneAfter : ""
          }`}
        ></span>
        <div className={styles.optionTextContainer}>
          {Array.isArray(firstOptionRender.month) ? (
            <>
              <span className={styles.optionStyleRadioTextLeft}>
                {firstOptionRender.month[0]}
              </span>
              <span className={styles.optionStyleRadioTextRight}>
                {firstOptionRender.month[1]}
              </span>
            </>
          ) : (
            <span className={styles.optionStyleRadioTextLeft}>
              {firstOptionRender.month}
            </span>
          )}
          {firstOptionRender.saveText !== "" && (
            <div className={styles.partsSaveText}>
              {Array.isArray(firstOptionRender.saveText) ? (
                <>
                  <span className={styles.optionStyleRadioTextBottomLeft}>
                    {firstOptionRender.saveText[0]}
                  </span>
                  <span className={styles.optionStyleRadioTextBottomRight}>
                    {firstOptionRender.saveText[1]}
                  </span>
                </>
              ) : (
                <span className={styles.optionStyleRadioTextBottomRight}>
                  {firstOptionRender.saveText}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <Price
        pricePerDay={firstOptionRender.pricePerDay}
        oldPrice={firstOptionRender.oldPrice}
      />
    </label>
  );
};

export default FirstPlanOption;
