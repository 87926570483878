import React, { useEffect, useState } from "react";
import { useConfig } from "../../../../../context/ConfigDataContext";
import { usePaymentBlock } from "../../../../../context/PaymentBlockContext";
import { useEmailValidator } from "../../../../../context/EmailValidatorContext";

import styles from "./EmailBlockStyle.module.css";

const EmailBlock = () => {
  const { findDataComponent } = useConfig();

  const { setStartPaymentForm, rewriteEmail, setLoader } = usePaymentBlock();

  const data = findDataComponent(1, "EmailBlock");

  const userData = JSON.parse(localStorage.getItem("MemoverseUserData"));

  const [newUserEmail, setNewUserEmail] = useState(userData.userEmail);

  const [isValidEmailFormat, setIsValidEmailFormat] = useState(true);

  const [disableBlock, setDisableBlock] = useState(false);

  const { validEmail } = useEmailValidator();

  useEffect(() => {
    const handleTabKey = (e) => {
      if (disableBlock && e.key === "Tab") {
        e.preventDefault();
      }
    };

    if (disableBlock) {
      window.addEventListener("keydown", handleTabKey);
    } else {
      window.removeEventListener("keydown", handleTabKey);
    }

    return () => {
      window.removeEventListener("keydown", handleTabKey);
    };
  }, [disableBlock]);

  useEffect(() => {
    setIsValidEmailFormat(validEmail(newUserEmail));
    setDisableBlock(!validEmail(newUserEmail));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newUserEmail]);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 50);
    setNewUserEmail(truncatedValue.toLowerCase());
    setIsValidEmailFormat(validEmail(truncatedValue));
  };

  return (
    <div className={styles.emailBlockContainer}>
      <div className={styles.emailBlock}>
        <input
          className={`${styles.inputBlock} ${
            disableBlock ? styles.invalid : ""
          }`}
          type="text"
          placeholder={data.textPlaceholder}
          value={newUserEmail}
          onChange={handleInputChange}
          tabIndex={disableBlock ? -1 : 0}
          maxLength={50}
        />
        <button
          onClick={() => {
            setLoader(true);
            setStartPaymentForm(false);
            rewriteEmail(newUserEmail);
          }}
          className={`${styles.saveBtn} ${disableBlock ? styles.invalid : ""}`}
          disabled={disableBlock}
        >
          {data.textButton}
        </button>
        {disableBlock ? (
          <>
            <div className={styles.disBlockTop} />
            <div className={styles.disBlockBot} />
          </>
        ) : null}
      </div>
      {!isValidEmailFormat && (
        <span className={styles.invalidText}>{data.textInvalid}</span>
      )}
      <span className={styles.considerText}>{data.textConsider}</span>
    </div>
  );
};

export default EmailBlock;
