import React from "react";

import styles from "./ActiveDownsaleStyle.module.css";

const ActiveDownsale = () => {
  return (
    <div className={styles.container}>
      <img
        src="../img/moSalePlanPage.png"
        alt="moSalePlanPage"
        className={styles.saleImg}
      />
      <div className={styles.saleTextContainer}>
        <span className={styles.textGreen}>
          -53% Limited Discount{" "}
          <span className={styles.textWhite}>For You</span>
        </span>
      </div>
    </div>
  );
};
export default ActiveDownsale;
