import React from "react";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";

import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";
import FooterTextContainer from "./FooterTextContainer/FooterTextContainer";

import styles from "./FooterBlockStyle.module.css";
import FooterTextContainerTwo from "./FooterTextContainer/FooterTextContainerTwo";

const FooterBlock = ({ data, footerBlockData }) => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();

  return (
    <div className={styles.container}>
      <GraySafeBlock
        abTestDesignVariant={abTestDesignVariant}
        text={data.headerText}
        textSecond={data.headerTextOffDiscount}
      />
      {abTestDesignVariant === "A" ? (
        <FooterTextContainer data={footerBlockData} />
      ) : (
        <FooterTextContainerTwo
          data={optionTextData.secondPlanOptionBottomText}
        />
      )}
    </div>
  );
};

export default FooterBlock;
