import React from "react";

import styles from "./AssetsContainerStyle.module.css";

const AssetsContainer = ({ data }) => {
  return (
    <div className={styles.container}>
      <div className={styles.jonasContainer}>
        <div className={styles.jonasTextImgCont}>
          <img
            src="./img/cp_Jonas.png"
            alt="Jonas Champion"
            className={styles.jonasImg}
          />
          <span className={styles.jonasText}>{data.textJonas}</span>
        </div>
      </div>
      <img
        src="./img/cp_Medal.png"
        alt="cp_medal"
        className={styles.medalImg}
      />
      <img
        src="./img/cp_Joystick.png"
        alt="cp_Joystick"
        className={styles.joystickImg}
      />
      <img
        src="./img/cp_HeroSectionImage.png"
        alt="cp_HeroSectionImage"
        className={styles.heroSectionImg}
      />

      <div className={styles.mindPalaceCont}>
        <img
          src="./img/cp_MemoryPalace.png"
          alt="cp_MemoryPalace"
          className={styles.memoryPalaceImg}
        />

        <span className={styles.palaceText}>{data.textMindPalace}</span>
      </div>
    </div>
  );
};

export default AssetsContainer;
