import React, { useEffect, useState } from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useRadioInput } from "../../../../context/RadioInputContext";
import { useTimer } from "../../../../context/TimerContext";

import styles from "./PleaseNoteStyle.module.css";


const PleaseNote = () => {
  const { findDataComponent } = useConfig();
  const { textToPlan } = useRadioInput();
  const { showHideElement } = useTimer();

  const data = findDataComponent(1,"PleaseNote");

  const [renderData, setRenderData] = useState();

  const findSplitText = (textData) => {
    const mainTextStyles = (
      <span>
        {textData[0]}{" "}
        <a
          href={data.linkSubscriptionTerms}
          className={styles.subscrText}
          target="blank"
        >
          {data.textLink}
        </a>
        {textData[1]}
      </span>
    );
    setRenderData(mainTextStyles);
  };

  useEffect(() => {
    switch (textToPlan.month || textToPlan.days) {
      case 6:
        if (showHideElement) {
          findSplitText(data.textMainWithDiscount[0]);
        } else {
          findSplitText(data.textMainWithoutDiscount[0]);
        }
        break;
      case 3:
        if (showHideElement) {
          findSplitText(data.textMainWithDiscount[1]);
        } else {
          findSplitText(data.textMainWithoutDiscount[1]);
        }
        break;
      case 1:
        if (showHideElement) {
          findSplitText(data.textMainWithDiscount[2]);
        } else {
          findSplitText(data.textMainWithoutDiscount[2]);
        }
        break;
      default:
        break;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textToPlan, showHideElement]);

  return (
    <div className={styles.container}>
      <span className={styles.mainText}>
        <span className={styles.hightText}>{data.textHight}</span>
        {renderData}
      </span>
    </div>
  );
};

export default PleaseNote;
