import React from "react";
import SecondOptionsPlan from "./OptionsPlan/SecondOptionsPlan";

import { useConfig } from "../../../../context/ConfigDataContext";
import FooterBlock from "./FooterBlock/FooterBlock";

import styles from "./SecondChoosePlan.module.css";
import AssetsContainer from "./AssetsContainer/AssetsContainer";

const SecondChoosePlan = () => {
  const { findDataComponent } = useConfig();
  const data = findDataComponent(2, "SecondChoosePlan");

  return (
    <div className={styles.ChoosePlanContainer}>
      <div className={styles.optionsCont}>
        <div className={styles.HeaderText}>{data.headerText}</div>
        <SecondOptionsPlan />
        <FooterBlock
          data={data.footerBlock}
          footerBlockData={data.textInfoBlock}
        />
      </div>
      <AssetsContainer data={data} />
    </div>
  );
};

export default SecondChoosePlan;
