import React from "react";

import { Component, ComponentTitle } from "../../../styles";
// import ReactPlayer from "react-player/youtube";

import styles from "../ReviewContainer.module.css";
import VideoPlayer from "../../../../templates/VideoPlayer/VideoPlayer";
const VideoContainer = ({ posterImg, linkVideo }) => {
  return (
    <Component $inner style={{ marginTop: "10px" }}>
      <ComponentTitle $icon>
        <div className={styles.svgContainer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M19.7661 13.5162C20.758 12.7487 20.758 11.2512 19.7661 10.4837C16.7685 8.16434 13.4213 6.33619 9.84979 5.06771L9.19732 4.83597C7.94904 4.39263 6.63053 5.23719 6.46154 6.52574C5.98938 10.126 5.98938 13.8739 6.46154 17.4742C6.63053 18.7627 7.94904 19.6073 9.19731 19.1639L9.84979 18.9322C13.4213 17.6637 16.7685 15.8356 19.7661 13.5162Z"
              fill="white"
            />
          </svg>
        </div>
        <span>Video reviews</span>
      </ComponentTitle>
      <div className={styles.youtubePlayer}>
        <VideoPlayer
            videoId={linkVideo}
            thumbnail={posterImg || ""}
          />
      </div>
    </Component>
  );
};

export default VideoContainer;
