import React, { useState, useEffect, useRef } from "react";
import { useButtonRef } from "../../../context/ButtonRefContext";
import LogoGlobal from "../../templates/Logo/LogoGlobal";

import styles from "./HeaderStyle.module.css";

const Header = ({ data }) => {
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const timerIntervalRef = useRef(null);

  useEffect(() => {
    if (!data.timerDate || calculateTimeRemaining().days <= 0) {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
      return;
    }

    timerIntervalRef.current = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timerIntervalRef.current);
      timerIntervalRef.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.timerDate]);

  function calculateTimeRemaining() {
    const endDate = new Date(`${data.timerDate ? data.timerDate : 0}`);
    const now = new Date();
    const timeDifference = Math.max(endDate - now, 0);

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  const formatTime = `${timeRemaining.days}d ${timeRemaining.hours}h ${timeRemaining.minutes}m ${timeRemaining.seconds}s`;
  const { buttonRef } = useButtonRef();

  const handleButtonClick = () => {
    if (buttonRef.current) {
      buttonRef.current.click();
    }
  };

  if (!data.timerDate || timeRemaining.days <= 0) {
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <LogoGlobal />
          <button className={styles.button} onClick={handleButtonClick}>
            <span className={styles.buttonText}>{data.buttonText}</span>
          </button>
        </div>
        <div className={styles.infoContainer}>
          <span className={styles.mainText}>{data.timerInfo}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <LogoGlobal />
        <button className={styles.button} onClick={handleButtonClick}>
          <span className={styles.buttonText}>{data.buttonText}</span>
        </button>
      </div>
      <div className={styles.infoContainer}>
        <span className={styles.topText}>{formatTime}</span>
        <span className={styles.mainText}>{data.timerInfo}</span>
      </div>
    </div>
  );
};

export default Header;
