import React, { useEffect, useState } from "react";
import ElementsSlider from "../ElementsSlider/ElementsSlider";

import styles from "../WithinMemoryOS.module.css";

const VariantB = ({ data }) => {
  const imgName = [
    "withinBlockComment.png",
    "withinBlockTimer.png",
    "withinBlockStar.png",
    "withinBlockUniversity.png",
    "withinBlockHeart.png",
    "withinBlockJug.png",
    "withinBlockBank.png",
  ];

  const [renderData, setRenderData] = useState(null);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data.variantA !== null) {
      setIsLoading(false);
      setRenderData(data.variantA);
    } else {
      setIsLoading(true);
    }
  }, [data]);

  return (
    <>
      <div className={styles.secondContainer}>
        {!isLoading &&
          renderData.topAchive.map((text, index) => {
            return (
              <div key={index} className={styles.secondElement}>
                <img
                  src={`./img/${imgName[index]}`}
                  alt={`img${index}`}
                  className={styles.secondImg}
                />
                {text.length > 1 ? (
                  <>
                    <span className={styles.solidText}>{text[0]} </span>
                    <span
                      className={`${styles.secondStepText} ${styles.stepTextMargin}`}
                    >
                      {text[1]}
                    </span>
                  </>
                ) : (
                  <span className={styles.secondStepText}>{text[0]}</span>
                )}
              </div>
            );
          })}
      </div>
      <div className={styles.hrLine} />
      {!isLoading && <ElementsSlider renderData={renderData.botAchive} />}
    </>
  );
};

export default VariantB;
