import React from "react";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import VariantB from "./RenderVariant/VariantB";

import styles from "./PleaseNoteStyle.module.css";

const PleaseNote = () => {
  const { discountPageData } = useFirebaseConfig();


  return (
    <div className={styles.container}>
      <VariantB textData={discountPageData.pleaseNote} />
    </div>
  );
};

export default PleaseNote;
