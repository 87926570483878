import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useFirebaseConfig } from "./context/FirebaseConfigContext";

import Quiz from "./pages/Quiz";
import LandingB from "./pages/Landing_B/LandingB";
import LandingBTwo from "./pages/Landing_B_new_design/LandingB_two";
import LandingC from "./pages/Landing_C/LandingC";
import DesignSwitcher from "./pages/Plan/DesignSwitcher";
import BlackFriday from "./pages/BlackFriday/BlackFriday";
import RedesignThankYou from "./pages/ThankYou/RedesignThankYou/RedesignThankYou";
import ApplyDiscount from "./pages/Plan/ApplyDiscount/ApplyDiscount";
import DevMode from "./pages/DevMode/DevMode";

import LandingD from "./pages/Landing_D/LandingD";
import NewQuiz from "./pages/NewQuiz";
// import ABtestPlanPageChosePlan from "./pages/Plan/ABtestPlanPageChosePlan/ABtestPlanPageChosePlan";
import ABspecialOfferPlan from "./pages/Plan/ABspecialOfferPlan/ABspecialOfferPlan";

function App() {
  const { landingData, currentDesign } = useFirebaseConfig();

  const LandingComponent = () => {
    const { show_Landing } = landingData;

    if (show_Landing === "A") {
      // return <LandingA />;
      return <LandingB />;
    } else if (show_Landing === "B") {
      if (currentDesign === "A") {
        return <LandingB />;
      } else if (currentDesign === "B" || currentDesign === "C") {
        return <LandingBTwo />;
      }
    } else if (show_Landing === "C") {
      return <LandingC />;
    } else {
      return null;
    }
  };

  return (
    <>
      <BrowserRouter
        history={createBrowserHistory({
          scrollRestoration: "auto",
        })}
      >
        <Routes>
          <Route path="/" element={<LandingComponent />} />

          <Route path="/b" element={<LandingB />} />
          <Route path="/c" element={<LandingC />} />

          <Route path="/quiz/:id" element={<Quiz />} />
          <Route path="/quiz/:quizId/:id" element={<Quiz />} />

          <Route path="/plan" element={<DesignSwitcher />} />

          <Route path="/apply-discount" element={<ApplyDiscount />} />

          <Route path="/thankyou" element={<RedesignThankYou />} />

          <Route path="/reserve" element={<BlackFriday />} />

          <Route path="/lt-access" element={<BlackFriday />} />

          <Route path="/new" element={<LandingD />} />

          <Route path="/new/quiz/:id" element={<NewQuiz />} />

          <Route path="/new/quiz/:quizId/:id" element={<NewQuiz />} />

          {/* <Route path="/new/plan" element={<ABtestPlanPageChosePlan />} /> */}
          <Route path="/new/plan" element={<ABspecialOfferPlan />} />

          <Route path="/new/thankyou" element={<RedesignThankYou />} />

          <Route
            path="/.well-known/apple-developer-merchantid-domain-association.txt"
            component={() => {
              window.location.href =
                "/apple-developer-merchantid-domain-association.txt";
              return null;
            }}
          />

          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <DevMode />
      </BrowserRouter>
      <div className="hidden">{`Build version: ${process.env.REACT_APP_VERSION}`}</div>
    </>
  );
}

export default App;
