import React from "react";

import { useConfig } from "../../../../../../context/ConfigDataContext";
import { useTimer } from "../../../../../../context/TimerContext";
import { useFirebaseConfig } from "../../../../../../context/FirebaseConfigContext";

import styles from "./PlanDetails.module.css";

const PlanDetails = ({ detailsText, selectedOption }) => {
  const { offDiscount } = useFirebaseConfig();
  const { showHideElement } = useTimer();
  const { percentConfig } = useConfig();

  return (
    <div className={styles.PlanDetailsContainer}>
      <div className={styles.PlanDetailsTextContainer}>
        <span className={styles.PlanDetailsMonth}>
          {offDiscount === true ? (
            <span>
              {selectedOption.month} month {selectedOption.fullPlanName}
            </span>
          ) : (
            `Personalized ${selectedOption.fullPlanName}`
          )}
        </span>
        <span className={styles.PlanDetailsMonth}>
          &#36;
          {selectedOption.fullPlanPrice}
        </span>
      </div>
      {showHideElement ? (
        <div className={styles.PlanDetailsTextContainer}>
          <span className={styles.PlanDetailsDiscount}>
            {offDiscount === true ? (
              <span className={styles.sevenDayDiscount}>
                {detailsText.discountText}
              </span>
            ) : (
              `${percentConfig}% Introductory offer discount`
            )}
          </span>
          <span className={styles.PlanDetailsDiscountSavePrice}>
            -&#36;{selectedOption.savingsAmount}
          </span>
        </div>
      ) : (
        false
      )}

      {offDiscount ? null : <div className={styles.hrLine} />}
      <div className={styles.Total}>
        {offDiscount === true ? (
          <>
            <span className={styles.PlanDetailsTotalText}>
              {detailsText.totalText}
            </span>
            <span className={styles.PlanDetailsTotalText}>
              &#36;{selectedOption.priceTotal}
            </span>
          </>
        ) : (
          <>
            <span className={styles.PlanDetailsTotalText}>Per day</span>
            <span className={styles.PlanDetailsTotalText}>
              &#36;{selectedOption.pricePerDay}
            </span>
          </>
        )}
      </div>
      {offDiscount === true ? null : (
        <div className={styles.PlanDetailsTextContainer}>
          <span className={styles.TotalText}>{detailsText.totalText}</span>
          <span className={styles.TotalText}>
            &#36;{selectedOption.priceTotal}
          </span>
        </div>
      )}

      {showHideElement ? (
        offDiscount ? (
          <div className={styles.PlanDetailsTextContainerBottomTrial}>
            <span className={styles.TextBottomTrial}>
              {detailsText.bottomText}
            </span>
          </div>
        ) : (
          <div className={styles.PlanDetailsTextContainerBottom}>
            <span className={styles.TextBottom}>
              You just saved ${selectedOption.savingsAmount} USD (
              {percentConfig}% off)
            </span>
          </div>
        )
      ) : (
        false
      )}
    </div>
  );
};

export default PlanDetails;
