import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";

import VideoPlayer from "./VideoPlayer/VideoPlayer";

// import FeatureSlider from "./FeatureSlider/FeatureSlider";

import styles from "./YourGrandmaster.module.css";
import NewsContainer from "./NewsContainer/NewsContainer";


const YourGrandmaster = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "YourGrandmaster");

  return (
    <div className={styles.YourGrandmasterContainer}>
      <span className={styles.headerText}>{data.headerText}</span>
      <span className={styles.mainText}>{data.mainText}</span>
      <span className={styles.mainText}>{data.footerText}</span>
      <div className={styles.awardsContainer}>
        {data.awards.map((element, index) => {
          return (
            <div key={index} className={styles.awardElement}>
              <img
                src="/img/Layer_cup.png"
                alt="Jonas achievement"
                className={styles.layerCup}
              />
              <span className={styles.awardText}>{element}</span>
            </div>
          );
        })}
      </div>
      <div className={styles.sliderPosition}>
        <NewsContainer featuredText={data.featuredText} />
        {/* <FeatureSlider /> */}
      </div>
      <VideoPlayer linkVideo={data.linkVideo} linkPoster={data.linkPoster} />
      <div className={styles.backgroundImg} />
    </div>
  );
};

export default YourGrandmaster;
