import React, { useEffect, useRef, useState } from "react";
import { useDevice } from "../../../../../context/DeviceCheckContext";

import Player from "react-player";
import Loading from "../../Loading/Loading";

import styles from "./VideoPlayerStyle.module.css";

const VideoPlayer = ({ videoId, thumbnail }) => {
  const { isMacOS } = useDevice();

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 5;

  const [widthCont, setWidth] = useState("");
  const [heightCont, setHeight] = useState("");
  const [radiusCont, setRadius] = useState("");

  const containerRef = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const { offsetWidth, offsetHeight } = containerRef.current;
        setWidth(`${offsetWidth}px`);
        setHeight(`${offsetHeight}px`);
        const { borderRadius } = window.getComputedStyle(containerRef.current);
        setRadius(borderRadius);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const loadVimeoVideo = (e) => {
    e.preventDefault();
    setIsPlaying(true);
  };

  const handleReady = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    if (retryCount < maxRetries) {
      setRetryCount((prevCount) => prevCount + 1);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    } else {
      console.log("Error loading video");
    }
  };

  const playerProps = {
    ref: playerRef,
    // url: `https://vimeo.com/${videoId}`,
    url: `https://player.vimeo.com/video/${videoId}/config`,
    controls: isPlaying ? false : true,
    onPlay: () => setIsPlaying(true),
    onPause: () => setIsPlaying(false),
    onReady: handleReady,
    onError: handleError,
    playsinline: true,
    width: widthCont,
    height: heightCont,
    style: {
      margin: "0 auto",
      borderRadius: radiusCont,
      overflow: "hidden",
      display: isLoading ? "none" : "block",
      cursor: "pointer",
    },
    config: {
      vimeo: {
        playerVars: {
          autopause: 0,
          preload: "auto",
          maxRetries: 3,
          maxRetryDelay: 2000,
        },
        preferredQuality: "auto",
        playback: {
          preload: true,
          autoplay: true,
          muted: true,
        },
      },
    },
  };

  return (
    <div className={styles.ReactPlayer} ref={containerRef}>
      {isLoading && <Loading />}

      {!isMacOS ? (
        <Player {...playerProps} playing />
      ) : (
        <Player
          {...playerProps}
          poster={thumbnail || ""}
          playing={isPlaying}
          onClick={loadVimeoVideo}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
