import styled from "styled-components";
import { mediaQueries } from "../../constants/breakpoints";

export const Page = styled.div`
  height: 100vh;
  border: 1px solid transparent;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  max-width: 900px;
  height: fit-content;
  // margin: 20px auto;
  padding-bottom: 20px;
  font-family: Inter, sans-serif;
  width: 100%;
  flex-grow: 1;
  display: flex;
  @media ${mediaQueries.desktop} {
    flex-grow: 0;
  }
`;

export const Step = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;

export const Column = styled.div`
  width: 90%;
  // margin-bottom: 20px;
  @media ${mediaQueries.desktop} {
    width: 48%;
    margin-bottom: none;
  }
`;

export const Title = styled.div`
margin-top: 10px;
  font-family: Inter, sans-serif;
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  @media ${mediaQueries.desktop} {
    font-size: 36px;
    line-height: 40px;
  }
`;

export const Image = styled.div`
  width: 100%;
  img {
    max-width: 100%;
  }
`;

export const Button = styled.button`
  position: sticky;
  bottom: 20px;
  border: none;
  width: 100%;
  margin: 0 auto;
  margin-top: 25px;
  margin-right: 6px;
  padding: 10px 45px;
  border-radius: 50px;
  background-color: #484dff;
  color: #f7f8fa;
  font-size: 16px;
  line-height: 27px;
  font-weight: 700;
  transition: opacity 0.2s;
  ${(props) =>
    props.$isActive
      ? `
        opacity: 1;
        cursor: pointer;
        :hover {
          opacity: 1;
        }
      `
      : `opacity: 0.2;`}
`;

export const Answers = styled.div`
  ${(props) =>
    props.$hideOnMobile &&
    `
        display: none;
        @media ${mediaQueries.desktop} {
          display: block;
        }
      `}
`;

export const AnswersMulti = styled.div`
  position: relative;
  ${Button} {
    position: sticky;
    bottom: 16px;
  }
`;

export const Answer = styled.button`
  padding: 16px;
  border: 1px solid rgba(153, 161, 186, 0.2);
  border-radius: 8px;
  background: transparent;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;
  font-size: 18px;
  transition: border 0.2s;
  display: flex;
  text-align: left;
  justify-content: space-between;
  user-select: none;
  -webkit-user-select: none;
  color: #fff;
  @media ${mediaQueries.desktop} {
    :hover {
      border: 1px solid rgba(153, 161, 186, 1);
    }
  }
  :active {
    border: 1px solid rgba(153, 161, 186, 1);
  }
`;

export const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  backgorund-size: 100% 100%;
  flex: 0 0 24px;
  margin-left: 16px;
  ${(props) =>
    props.$isSelected
      ? `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='white' d='M19 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.11 0 2-.9 2-2V5c0-1.1-.89-2-2-2zm-9 14l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'%3E%3C/path%3E%3C/svg%3E");`
      : `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='white' d='M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z'%3E%3C/path%3E%3C/svg%3E");`}
`;

export const Copy = styled.div`
  margin-top: 20px;
  font-size: 18px;
`;

export const Banner = styled.div`
  border-width: 1px;
  border-style: solid;
  border-color: rgba(153, 161, 186, 0.2);
  border-radius: 8px;
  margin-top: 30px;
  margin-bottom: 32px;
  padding: 14px 16px 16px;
`;
export const BannerHead = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const BannerName = styled.div`
  font-size: 18px;
  font-weight: bold;
`;
export const BannerRating = styled.div`
  display: flex;
`;
export const BannerCopy = styled.div`
  margin-top: 20px;
  font-size: 16px;
`;
export const Star = styled.div`
  width: 24px;
  height: 24px;
  ${(props) =>
    props.$isActive
      ? `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='rgb(255, 136, 51)' d='M9.92153 4.1594C10.5708 2.5426 10.8954 1.7342 11.3592 1.49535C11.7614 1.28821 12.239 1.28821 12.6412 1.49535C13.105 1.7342 13.4296 2.5426 14.0788 4.1594L15.6209 7.99956L19.7497 8.27951C21.488 8.39737 22.3571 8.45631 22.7276 8.82358C23.0489 9.1421 23.1965 9.59626 23.1238 10.0428C23.0399 10.5577 22.3714 11.1162 21.0344 12.2333L17.8587 14.8866L18.8683 18.8998C19.2934 20.5895 19.5059 21.4343 19.2711 21.9001C19.0674 22.3041 18.6811 22.5848 18.2339 22.6536C17.7184 22.733 16.9806 22.2698 15.505 21.3434L12.0002 19.1431L8.49542 21.3434C7.01982 22.2698 6.28202 22.733 5.76643 22.6536C5.31927 22.5848 4.93294 22.3041 4.72929 21.9001C4.49448 21.4343 4.70701 20.5895 5.13208 18.8998L6.14168 14.8866L2.96601 12.2333C1.62897 11.1162 0.960454 10.5577 0.876615 10.0428C0.803903 9.59626 0.951468 9.1421 1.27277 8.82358C1.64324 8.45631 2.51239 8.39737 4.25069 8.27951L8.37943 7.99956L9.92153 4.1594Z'%3E%3C/path%3E%3C/svg%3E");`
      : `background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath fill='white' d='M9.92153 4.1594C10.5708 2.5426 10.8954 1.7342 11.3592 1.49535C11.7614 1.28821 12.239 1.28821 12.6412 1.49535C13.105 1.7342 13.4296 2.5426 14.0788 4.1594L15.6209 7.99956L19.7497 8.27951C21.488 8.39737 22.3571 8.45631 22.7276 8.82358C23.0489 9.1421 23.1965 9.59626 23.1238 10.0428C23.0399 10.5577 22.3714 11.1162 21.0344 12.2333L17.8587 14.8866L18.8683 18.8998C19.2934 20.5895 19.5059 21.4343 19.2711 21.9001C19.0674 22.3041 18.6811 22.5848 18.2339 22.6536C17.7184 22.733 16.9806 22.2698 15.505 21.3434L12.0002 19.1431L8.49542 21.3434C7.01982 22.2698 6.28202 22.733 5.76643 22.6536C5.31927 22.5848 4.93294 22.3041 4.72929 21.9001C4.49448 21.4343 4.70701 20.5895 5.13208 18.8998L6.14168 14.8866L2.96601 12.2333C1.62897 11.1162 0.960454 10.5577 0.876615 10.0428C0.803903 9.59626 0.951468 9.1421 1.27277 8.82358C1.64324 8.45631 2.51239 8.39737 4.25069 8.27951L8.37943 7.99956L9.92153 4.1594Z'%3E%3C/path%3E%3C/svg%3E");`}
`;

export const Input = styled.input`
  padding: 16px;
  border: 1px solid rgba(153, 161, 186, 0.2);
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
  font-size: 18px;
  transition: border 0.2s;
  outline: none;
  background: transparent;
  color: #fff;
  :focus {
    border: 1px solid rgba(153, 161, 186, 1);
  }
`;

export const Summary = styled.div`
box-sizing: border-box;
  padding: 16px;
  border: 1px solid rgba(153, 161, 186, 0.2);
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  ${Button} {
    position: sticky;
    bottom: 16px;
  }
`;

export const SummaryItem = styled.div`
  margin-bottom: 30px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const SummaryTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
`;

export const SummaryField = styled.div`
  padding: 16px;
  border: 1px solid rgba(153, 161, 186, 0.2);
  background: rgba(255, 255, 255, 0.05);
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  // flex-direction: column-reverse;
  margin: 0 auto;
  @media ${mediaQueries.desktop} {
    width: 80%;
    flex-direction: column;
  }
`;

export const HeaderInner = styled.div`
  width: 90%;
  margin: 5px auto 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${mediaQueries.desktop} {
    margin: 0 auto 20px;
  }
`;

export const ProgressBar = styled.div`
  position: relative;
  overflow: hidden;
  display: block;
  height: 4px;
  flex: 0 0 4px;
  border-radius: 8px;
  background-color: rgb(239, 239, 241, 0.3);
  width: 90%;
  margin: 0 auto 0;
  ::before {
    content: "";
    height: 4px;
    position: absolute;
    background-color: rgb(0, 187, 119);
    left: 0;
    top: 0;
    transition: width 0.4s;
    ${(props) => `width: ${props.$progress}%;`}
  }
  @media ${mediaQueries.desktop} {
    margin: 0 auto 20px;
  }
`;

export const BackButton = styled.button`
  border: none;
  background-color: #484dff;
  color: #f7f8fa;
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  padding: 5px 15px 5px 10px;
  border-radius: 15px;
  opacity: 0.9;
  cursor: pointer;
  display: flex;
  svg {
    width: 16px;
    height: 16px;
    margin-right: 5px;
  }
  :hover {
    opacity: 1;
  }
`;

export const Logo = styled.img`
  width: 120px;
  height: 40px;
  @media ${mediaQueries.desktop} {
    width: 180px;
    height: 58px;
  }
`;

export const Counts = styled.div`
  font-size: 16px;
  color: #fff;
  min-width: 80px;
  text-align: right;
`;

export const Plan = styled.div`
  padding: 0 20px;
  position: relative;
  @media ${mediaQueries.desktop} {
    padding: 0;
  }
`;
export const PlanColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${mediaQueries.desktop} {
    width: 48%;
    flex: 0 0 48%;
  }
  ${Button} {
    display: none;
    width: 60%;
    @media ${mediaQueries.desktop} {
      display: block;
    }
  }
`;
export const PlanInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
  ${Column} {
    width: 100%;
  }
`;
export const Card = styled.div`
  width: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(153, 161, 186, 0.2);
  border-radius: 8px;
  overflow: hidden;
`;
export const PlanInnerBottom = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 60px 0 30px;
  flex-direction: column;
  ${Title} {
    min-width: 30%;
  }
  ${Card} {
    margin-bottom: 20px;
    @media ${mediaQueries.desktop} {
      margin-left: 30px;
      margin-bottom: 0;
    }
  }
  @media ${mediaQueries.desktop} {
    flex-direction: row;
  }
`;
export const SubTitle = styled.div`
  margin-bottom: 20px;
`;
export const CardImage = styled.img`
  width: 100%;
`;
export const CardTitle = styled.div`
  padding: 14px 16px 5px;
  font-size: 20px;
  font-weight: bold;
`;
export const CardRating = styled.div`
  padding: 0 16px 0;
  display: flex;
`;
export const CardDesription = styled.div`
  padding: 25px 16px 16px;
`;
export const StickyContainer = styled.div`
  position: sticky;
  bottom: 16px;
  @media ${mediaQueries.desktop} {
    display: none;
  }
`;
export const MobileAnswers = styled.div`
  border: 1px solid rgba(153, 161, 186, 0.2);
  border-radius: 8px;
  overflow: hidden;
`;
export const MobileAnswer = styled.button`
  display: block;
  float: left;
  width: 20%;
  border: none;
  background: transparent;
  height: 70px;
  line-height: 70px;
  border-right: 1px solid rgba(153, 161, 186, 0.2);
  cursor: pointer;
  text-align: center;
  padding: 0;
  span {
    position: relative;
  }
  :last-child {
    border-right: none;
  }
  @media ${mediaQueries.desktop} {
    display: none;
  }
  :hover {
    background: rgba(153, 161, 186, 0.1);
  }
  font-size: ${(props) => (props.large ? "32px" : "24px")};
`;

export const Note = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #999;
  margin-top: 16px;
  a {
    color: #999;
    :hover {
      text-decoration: none;
    }
  }
`;
