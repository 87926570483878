import React, { useEffect, useRef } from "react";
import { useScroll } from "../../../../context/ScrollContext";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";

import OptionsPlan from "./OptionsPlan/OptionsPlan";

import FooterBlock from "./FooterBlock/FooterBlock";

import ImgPlanBlock from "./ImgPlanBlock/ImgPlanBlock";

import styles from "./ChoosePlan.module.css";

const ChoosePlan = () => {
  const choosePlanRef = useRef(null);

  const { findDataComponent } = useConfig();
  const { scrollRefOne } = useScroll();
  const { timer } = useTimer();

  const data = findDataComponent(2, "ChoosePlan");

  useEffect(() => {
    scrollRefOne.current = choosePlanRef.current;
  }, [scrollRefOne]);

  return (
    <div className={styles.bigContainer}>
      <ImgPlanBlock />

      <div className={styles.ChoosePlanContainer} ref={choosePlanRef}>
        <div className={styles.HeaderText}>
          {timer > 0 ? data.headerText : data.headerTextOffTimer}
        </div>
        <OptionsPlan />
        <FooterBlock data={data.footerBlock} footerData={data.textInfoBlock} />
      </div>
    </div>
  );
};

export default ChoosePlan;
