import React from "react";

import styles from "../ElementsSliderStyles.module.css";

const VariantA = ({ variantNumber, data }) => {
  return (
    <div className={styles.sliderContainer}>
      {variantNumber === 1
        ? data.thirdSteps.map((step, index) => {
            return (
              <div key={index} className={styles.thirdStepsContainer}>
                <span className={styles.thirdStepsText}>{step}</span>
              </div>
            );
          })
        : data.varBThirdSteps.map((step, index) => {
            return (
              <div key={index} className={styles.thirdStepsContainer}>
                <span className={styles.thirdStepsText}>{step}</span>
              </div>
            );
          })}
    </div>
  );
};

export default VariantA;
