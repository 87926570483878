import React, { useEffect, useState } from "react";

import { useConfig } from "../../../../context/ConfigDataContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";
import { useVWOContext } from "../../../../context/vwoContext";

import styles from "./MoreEfficientlyStyle.module.css";

const MoreEfficiently = () => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();
  const { variantNumber } = useVWOContext();

  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "MoreEfficiently");

  const [text, setText] = useState("");

  useEffect(() => {
    const textData = () => {
      if (abTestDesignVariant === "A") {
        return data.imgText;
      } else if (abTestDesignVariant === "B" || abTestDesignVariant === "C") {
        switch (variantNumber) {
          case 1:
            return optionTextData.moreEffAndThankyouAchivment.variantA;
          case 2:
            return optionTextData.moreEffAndThankyouAchivment.variantB;
          case 3:
            return optionTextData.moreEffAndThankyouAchivment.variantC;
          default:
            return "";
        }
      }
    };

    setText(textData());
  }, [abTestDesignVariant, variantNumber, optionTextData, data]);

  return (
    <div className={styles.container}>
      <div className={styles.imgsContainer}>
        <img
          src="./img/iphone_full.png"
          alt="iphoneFull"
          className={styles.iphoneFull}
        />
        <img
          src="./img/iphone_shadow.png"
          alt="iphoneShadow"
          className={styles.iphoneShadow}
        />
        <div className={styles.imgTextCont}>
          <img
            src="./img/gamepad.png"
            alt="gamepad"
            className={styles.gamepad}
          />
          <span className={styles.imgText}>{text}</span>
        </div>
      </div>
      <div className={styles.textCont}>
        <span className={styles.headerText}>{data.headerText}</span>
        <span className={styles.mainText}>{data.mainText}</span>
      </div>
    </div>
  );
};

export default MoreEfficiently;
