import React from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../context/ConfigDataContext";
import LogoGlobal from "../templates/Logo/LogoGlobal";

import styles from "./LandingC.module.css";
import { useEvent } from "../../context/EventContext";

const LandingC = () => {
  const { findDataComponent } = useConfig();
  const { analyticsEvent } = useEvent();

  const dataComponent = findDataComponent(1, "LandingC");

  const specialHighlightedText = dataComponent.mainTextCenter;
  const centerTextParts = specialHighlightedText.split(
    dataComponent.textSpecialStyle
  );

  const mainTextWithSpecialStyle = (
    <span>
      {centerTextParts[0]}
      {
        <span className={styles.mainSpecialText}>
          {dataComponent.textSpecialStyle}
        </span>
      }
      {centerTextParts[1]}
    </span>
  );

  const handleGetStarted = () => {
    analyticsEvent("getStartedClicked", { page: "LandingC" });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <LogoGlobal />
        </div>

        <div className={styles.container}>
          <div className={styles.headerText}>{dataComponent.headerText}</div>
          <div className={styles.lowHeadText}>{dataComponent.lowHeadText}</div>
          <div className={styles.mainText}>
            <div className={styles.mainTextStyle}>
              {dataComponent.mainTextTop}
            </div>
            <div className={styles.mainTextStyle}>
              {mainTextWithSpecialStyle}
            </div>
            <div className={styles.mainTextStyle}>
              {dataComponent.mainTextBottom}
            </div>
          </div>
        </div>
        <Link to="/quiz/1" className={styles.link}>
          <button onClick={handleGetStarted} className={styles.ContainerButton}>
            {dataComponent.buttonText}
            <div className={styles.ButtonArrowContainer}>
              <span className={styles.ButtonArrow}>&rarr;</span>
            </div>
          </button>
        </Link>
        <div className={styles.LandingFooter}>
          <div className={styles.FooterPolicyGroup}>
            <span className={styles.FooterContainerTextLink}>
              <a
                href="https://memoryos.com/privacy-policy"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            <span className={styles.FooterContainerTextLink}>
              <a
                href="https://memoryos.com/terms"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </span>
            <span className={styles.FooterContainerTextLink}>
              {" "}
              <a
                href="https://memoryos.com/terms"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Subscription Policy
              </a>
            </span>
          </div>
          <div className={styles.FooterCopyright}>
            <span className={styles.FooterContainerText}>
              © Copyright {new Date().getFullYear()} Encoder Inc., Delaware, the
              USA
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingC;
