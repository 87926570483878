import React from "react";

import styles from "../ChoosePlan.module.css";

const HeaderVariantA = ({ variantNumber, data, timer }) => {
  return (
    <div className={styles.HeaderText}>
      {variantNumber === 2
        ? data.headerTextVarB
        : variantNumber === 1 && timer > 0
        ? data.headerText
        : data.headerTextOffTimer}
    </div>
  );
};

export default HeaderVariantA;
