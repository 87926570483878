import React from "react";
import { useVWOContext } from "../../../../../context/vwoContext";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";
import { useConfig } from "../../../../../context/ConfigDataContext";

import styles from "./ElementsSliderStyles.module.css";
import VariantA from "./RenderVariants/VariantA";
import VariantB from "./RenderVariants/VariantB";

const ElementsSlider = ({ renderData }) => {
  const { abTestDesignVariant } = useFirebaseConfig();

  const { variantNumber } = useVWOContext();

  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "WithinMemoryOS");


  return (
    <div className={styles.container}>
      <span className={styles.thirdStepsHeaderText}>
        {data.thirdStepsHeaderText}
      </span>
      {abTestDesignVariant === "A" ? (
        <VariantA variantNumber={variantNumber} data={data} />
      ) : (
        <VariantB renderData={renderData} />
      )}
    </div>
  );
};

export default ElementsSlider;
