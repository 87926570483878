import React, { useRef, useState } from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import styles from "./DidYouKnow.module.css";

const DidYouKnow = () => {
  const { findDataComponent } = useConfig();
  const data = findDataComponent(2, "DidYouKnow");
  const [isExpanded, setIsExpanded] = useState(false);

  const textContainerRef = useRef(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);

    return !isExpanded && textContainerRef.current ? null : scrollUp();
  };

  const scrollUp = () => {
    textContainerRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={styles.container} ref={textContainerRef}>
      <div className={styles.headerContainer}>
        <span className={styles.headerMainText}>{data.headerMainText}</span>
        <span className={styles.headerLowText}>{data.headerLowText}</span>
      </div>
      <div className={styles.mainTextContainer}>
        <span className={styles.mainText}>{data.mainText}</span>
      </div>
      <div className={styles.openingTextContainer}>
        <span className={styles.openingText}>
          {isExpanded ? (
            <p>
              {data.openingText.map((textElem, index) => (
                <span
                  className={`${styles.textElemParagraph} ${
                    index === data.openingText.length - 1
                      ? styles.lastTextElem
                      : ""
                  }`}
                  key={index}
                >
                  {textElem}
                </span>
              ))}{" "}
              <button onClick={toggleExpand} className={styles.seeLessButton}>
                <span className={styles.seeMoreLessText}>
                  {data.expButtonText[0]}
                </span>
              </button>
            </p>
          ) : (
            <p>
              {data.openingText[0].slice(0, data.truncatedSymbols)}{" "}
              <button onClick={toggleExpand} className={styles.seeMoreButton}>
                <span className={styles.seeMoreLessText}>
                  {data.expButtonText[1]}
                </span>
              </button>
            </p>
          )}
        </span>
      </div>
    </div>
  );
};

export default DidYouKnow;
