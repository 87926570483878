import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useDownSale } from "../../../../context/DownSaleContext";

import styles from "./DownSalePopupStyle.module.css";

const DownSalePopup = () => {
  const { findDataComponent } = useConfig();
  const { setShowHideDownSalePopup } = useDownSale();

  const data = findDataComponent(2, "DownSalePopup");

  return (
    <div
      className={styles.shadowContainer}
      onClick={() => setShowHideDownSalePopup(false)}
    >
      <div className={styles.ScrollContainer}>
        <div className={styles.container}>
          <div className={styles.topTextContainer}>
            <span className={styles.greenTextTop}>
              {data.topText[0]}{" "}
              <span className={styles.mainTextTop}>{data.topText[1]}</span>
            </span>
          </div>
          <div className={styles.mainTextContainer}>
            <span className={styles.grayTextMain}>
              {data.mainText[0]}{" "}
              <span className={styles.whiteTextMain}>{data.mainText[1]}</span>
            </span>
          </div>
          <img
            src={data.imgLink ? data.imgLink : "../img/planImgMO.png"}
            alt="Mo Downsale"
            className={styles.moImgDownsale}
          />
          <button
            onClick={() => setShowHideDownSalePopup(false)}
            className={styles.button}
          >
            <span className={styles.buttonText}>{data.buttonText}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownSalePopup;
