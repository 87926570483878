import React from "react";
import { useFirebaseConfig } from "../../../../../../context/FirebaseConfigContext";

import Price from "../Price/Price";

import styles from "../VariantBStyle.module.css";

const SecondPlanOption = ({
  secondOptionRender,
  selectedOption,
  handleOptionChangeWithToggle,
}) => {
  const { abTestDesignVariant } = useFirebaseConfig();

  const randomId = Math.round(Math.random() * 1000000);
  return (
    <label
      className={`${styles.optionStyle} ${
        selectedOption === 1 ? styles.actives : ""
      }`}
      htmlFor={`${secondOptionRender.productID}${randomId}`}
      key={secondOptionRender.productID}
    >
      {secondOptionRender.mostPopular && (
        <div className={styles.mostPopular}>
          <span className={styles.mostPopularText}>MOST POPULAR</span>
        </div>
      )}
      <div className={styles.optionStyleRadioOne}>
        <input
          type="radio"
          name={secondOptionRender.month || secondOptionRender.days}
          id={`${secondOptionRender.productID}${randomId}`}
          value={secondOptionRender.month || secondOptionRender.days}
          checked={selectedOption === 1}
          onChange={() => handleOptionChangeWithToggle(1)}
        />
        <span
          className={`${styles.customCheckboxOne} ${
            selectedOption === 1 ? styles.customCheckboxOneAfter : ""
          }`}
        ></span>
        {abTestDesignVariant === "A" ? (
          <div className={styles.optionTextContainer}>
            <span className={styles.optionStyleRadioText}>
              {secondOptionRender.month}
            </span>
            <span className={styles.optionStyleRadioTextBottom}>
              {secondOptionRender.saveText}
            </span>
          </div>
        ) : (
          <div className={styles.optionTextContainer}>
            <span className={styles.optionStyleRadioTextLeft}>
              {secondOptionRender.month}
            </span>
            <span className={styles.optionStyleRadioTextBottom}>
              {secondOptionRender.saveText}
            </span>
          </div>
        )}
      </div>
      <Price
        pricePerDay={secondOptionRender.pricePerDay}
        oldPrice={secondOptionRender.oldPrice}
      />
    </label>
  );
};

export default SecondPlanOption;
