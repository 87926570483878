import React from "react";

import ElementsSlider from "../ElementsSlider/ElementsSlider";

import styles from "../WithinMemoryOS.module.css";

const VariantA = ({ variantNumber, data }) => {
  const imgName = [
    "withinBlockComment.png",
    "withinBlockTimer.png",
    "withinBlockStar.png",
    "withinBlockUniversity.png",
    "withinBlockHeart.png",
    "withinBlockJug.png",
    "withinBlockBank.png",
  ];
  const bImgName = [
    "withinBlockComment.png",
    "withinBlockTimer.png",
    "withinBlockStar.png",
    "withinBlockUniversity.png",
    "withinBlockHeart.png",
    "withinBlockJug.png",
    "withinBlockBank.png",
  ];
  return (
    <>
      <div className={styles.secondContainer}>
        {variantNumber === 1 &&
          data.secondSteps.map((text, index) => {
            return (
              <div key={index} className={styles.secondElement}>
                <img
                  src={`./img/${imgName[index]}`}
                  alt={`img${index}`}
                  className={styles.secondImg}
                />
                {text.length > 1 ? (
                  <>
                    <span className={styles.solidText}>{text[0]} </span>
                    <span
                      className={`${styles.secondStepText} ${styles.stepTextMargin}`}
                    >
                      {text[1]}
                    </span>
                  </>
                ) : (
                  <span className={styles.secondStepText}>{text[0]}</span>
                )}
              </div>
            );
          })}
        {variantNumber === 2 &&
          data.bSecondSteps.map((text, index) => {
            return (
              <div key={index} className={styles.secondElement}>
                <img
                  src={`./img/${bImgName[index]}`}
                  alt={`img${index}`}
                  className={styles.secondImg}
                />
                {text.length > 1 ? (
                  <>
                    <span className={styles.solidText}>{text[0]} </span>
                    <span
                      className={`${styles.secondStepText} ${styles.stepTextMargin}`}
                    >
                      {text[1]}
                    </span>
                  </>
                ) : (
                  <span className={styles.secondStepText}>{text[0]}</span>
                )}
              </div>
            );
          })}
      </div>
      <div className={styles.hrLine} />
      <ElementsSlider data={data} />
    </>
  );
};

export default VariantA;
