import React, { useEffect, useState } from "react";
import MarketButtons from "./MarketButtons/MarketButtons";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import styles from "./BottomComponentStyle.module.css";

const BottomComponent = ({ dataComponent, variantOpt }) => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();

  const [text, setText] = useState("");

  useEffect(() => {
    const textData = () => {
      if (abTestDesignVariant === "A") {
        return dataComponent.gameText;
      } else if (abTestDesignVariant === "B" || abTestDesignVariant === "C") {
        switch (variantOpt) {
          case 1:
            return optionTextData.moreEffAndThankyouAchivment.variantA;
          case 2:
            return optionTextData.moreEffAndThankyouAchivment.variantB;
          case 3:
            return optionTextData.moreEffAndThankyouAchivment.variantC;
          default:
            return "";
        }
      }
    };

    setText(textData());
  }, [abTestDesignVariant, variantOpt, optionTextData, dataComponent]);
  return (
    <div className={styles.container}>
      <div className={styles.imgTextContainer}>
        <img
          src="/img/iphone_shadow.png"
          alt="iphoneShadow"
          className={styles.iphoneShadow}
        />
        <img
          src="/img/iphone_full.png"
          alt="Bottom img thankyou page"
          className={styles.iphoneFull}
        />
        <div className={styles.gamepadContainer}>
          <img
            src="/img/gamepad.png"
            alt="Gamepad"
            className={styles.gamepadImg}
          />
          <span className={styles.gameText}>{text}</span>
        </div>
      </div>
      <div className={styles.buttonQRContainer}>
        <p className={styles.headerBtnText}>{dataComponent.headerBtnText}</p>
        <p className={styles.headerQRBtnText}>{dataComponent.scanQR_Text}</p>
        <div className={styles.buttonQR}>
          <img
            src="/img/qr-frame-ty.png"
            alt="QR-memoryOS"
            className={styles.qrImg}
          />
          <MarketButtons />
        </div>
      </div>
    </div>
  );
};

export default BottomComponent;
