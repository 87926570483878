import React from "react";

import { useABtest } from "../../../../../../context/ABTestContext";

import Price from "../Price/Price";

import styles from "./OptionsStyle.module.css";

const FirstPlanOption = ({
  firstOptionRender,
  selectedOption,
  handleOptionChangeWithToggle,
}) => {
  const { isActive } = useABtest();

  const randomId = Math.round(Math.random() * 1000000);

  return (
    <label
      className={`${styles.elementContainer} ${
        selectedOption === 0 ? styles.elementContainerActive : ""
      }`}
      htmlFor={`${firstOptionRender.productID}${randomId}`}
      key={firstOptionRender.productID}
    >
      {firstOptionRender.mostPopular && (
        <div className={styles.mostPopular}>
          <span className={styles.mostPopularText}>MOST POPULAR</span>
        </div>
      )}
      <div className={styles.optionContainer}>
        <input
          type="radio"
          name={firstOptionRender.month || firstOptionRender.days}
          id={`${firstOptionRender.productID}${randomId}`}
          value={firstOptionRender.month || firstOptionRender.days}
          checked={selectedOption === 0}
          onChange={() => handleOptionChangeWithToggle(0)}
          style={{
            display: "none",
          }}
        />
        <div className={styles.optionHeaderContainer}>
          {isActive === false ? (
            <>
              {Array.isArray(firstOptionRender.month) ? (
                <>
                  <span className={styles.optionHeaderText}>
                    {firstOptionRender.month[0]}
                  </span>
                  <span className={styles.optionHeaderText}>
                    {firstOptionRender.month[1]}
                  </span>
                </>
              ) : (
                <span className={styles.optionHeaderText}>
                  {firstOptionRender.month}
                </span>
              )}
            </>
          ) : (
            <span className={styles.optionHeaderText}>
              {firstOptionRender.month}
            </span>
          )}
        </div>

        {firstOptionRender.saveText !== "" && (
          <div className={styles.partsSaveText}>
            {Array.isArray(firstOptionRender.saveText) ? (
              <div className={styles.saveTextPartsContainer}>
                <div className={styles.saveTextPartsContainerNormal}>
                  {firstOptionRender.saveText.length >= 4 ? (
                    <span className={styles.partTextNormal}>
                      {firstOptionRender.saveText[0]}
                    </span>
                  ) : (
                    <span className={styles.partTextCrossed}>
                      {firstOptionRender.saveText[0]}
                    </span>
                  )}{" "}
                  {firstOptionRender.saveText.length >= 4 ? (
                    <span className={styles.partTextCrossed}>
                      {firstOptionRender.saveText[1]}
                    </span>
                  ) : (
                    <span className={styles.partTextNormal}>
                      {firstOptionRender.saveText[1]}
                    </span>
                  )}{" "}
                  {firstOptionRender.saveText.length >= 4 && (
                    <span className={styles.partTextNormal}>
                      {firstOptionRender.saveText[2]}
                    </span>
                  )}
                </div>
                {firstOptionRender.saveText.length >= 4 ? (
                  <span
                    className={`${styles.partTextGreen} ${
                      selectedOption === 0 ? styles.partTextGreenActive : ""
                    }`}
                  >
                    {firstOptionRender.saveText[3]}
                  </span>
                ) : (
                  <span
                    className={`${styles.partTextGreen} ${
                      selectedOption === 0 ? styles.partTextGreenActive : ""
                    }`}
                  >
                    {firstOptionRender.saveText[2]}
                  </span>
                )}
              </div>
            ) : (
              <span className={styles.partTextNormal}>
                {firstOptionRender.saveText}
              </span>
            )}
          </div>
        )}
      </div>
      <Price
        pricePerDay={firstOptionRender.pricePerDay}
        oldPrice={firstOptionRender.oldPricePerDay}
      />
    </label>
  );
};

export default FirstPlanOption;
