import React, { useState } from "react";
import { useEvent } from "../../../../context/EventContext";
import { useEmailValidator } from "../../../../context/EmailValidatorContext";

import styles from "./email.module.css";

const EmailStep = ({ id, step, handleNextStep }) => {
  const { analyticsEvent, setSnaptrUserData } = useEvent();

  const [newUserEmail, setNewUserEmail] = useState("");
  const [isValidEmailFormat, setIsValidEmailFormat] = useState(true);

  const { validEmail } = useEmailValidator();

  const [pasted, setPasted] = useState(false);

  const handleInputChange = (e) => {
    const { value } = e.target;
    const truncatedValue = value.slice(0, 50);

    if (!pasted) {
      setNewUserEmail(truncatedValue.toLowerCase());
      setIsValidEmailFormat(validEmail(truncatedValue));
    }

    setPasted(false);
  };
  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData("text");
    const truncatedValue = pastedValue.slice(0, 50);
    setNewUserEmail(truncatedValue.toLowerCase());
    setIsValidEmailFormat(validEmail(truncatedValue));
    setPasted(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isValidEmailFormat) {
      const storedUserData =
        JSON.parse(localStorage.getItem("MemoverseUserData")) || {};
      storedUserData.userEmail = newUserEmail;
      localStorage.setItem("MemoverseUserData", JSON.stringify(storedUserData));
      setSnaptrUserData(storedUserData);
      const userName = localStorage.getItem("MemoverseUserName");
      const gtagEventData = {
        ...{ event_user_name: userName },
        ...{ event_user_email: newUserEmail },
      };

      // firebase event: lead_form_submit
      if (Object.keys(gtagEventData).length > 0) {
        analyticsEvent("leadFormSubmit", gtagEventData);
        analyticsEvent("conversion");
      }

      handleNextStep({ id, formValues: { Email: newUserEmail }, type: "form" });
    }
  };
  return (
    <div className={styles.emailStep}>
      <div className={styles.columnContainer}>
        <div className={styles.titleContainer}>{step.title}</div>
        <div className={styles.imgContainer}>
          <img src={step.image} alt="" className={styles.imgStyle} />
        </div>
        {step.copy
          ? step.copy.map((copy, index) => (
              <div key={`${id}-${index}`} className={styles.copyConteiner}>
                {copy}
              </div>
            ))
          : null}
      </div>
      <div className={styles.columnContainer}>
        <form onSubmit={handleSubmit}>
          {step.inputs.map((input, index) => (
            <div key={`input-${id}-${index}`}>
              <input
                type="text"
                placeholder={input.placeholder}
                name={input.name}
                required
                value={newUserEmail}
                onChange={handleInputChange}
                onPaste={handlePaste}
                className={
                  !isValidEmailFormat ? styles.customInput : styles.customInput
                }
              />
              {/* {!isValidEmailFormat && (
                <span className={styles.invalidText}>
                  Invalid email format.
                </span>
              )} */}
            </div>
          ))}
          <button
            className={
              isValidEmailFormat
                ? styles.buttonStyle
                : styles.buttonStyleDisable
            }
            type="submit"
          >
            Continue
          </button>
        </form>
        <div className={styles.noteContainer}>
          By continuing you indicate that you've read and agree to our
          <br />
          <a href="https://memoryos.com/terms" rel="noreferrer" target="_blank">
            Terms Conditions
          </a>{" "}
          &{" "}
          <a
            href="https://memoryos.com/privacy-policy"
            rel="noreferrer"
            target="_blank"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

export default EmailStep;

// import React, { useState } from "react";
// import { useEvent } from "../../../../context/EventContext";
// import { useEmailValidator } from "../../../../context/EmailValidatorContext";

// import styles from "./email.module.css";

// const EmailStep = ({ id, step, handleNextStep }) => {
//   const { analyticsEvent, setSnaptrUserData } = useEvent();

//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [isValidEmailFormat, setIsValidEmailFormat] = useState(true);
//   const [isChecking, setIsChecking] = useState(false);

//   const { validEmail } = useEmailValidator();

//   const [pasted, setPasted] = useState(false);

//   const handleInputChange = (e) => {
//     const { value } = e.target;
//     const truncatedValue = value.slice(0, 50);

//     if (!pasted) {
//       setNewUserEmail(truncatedValue.toLowerCase());
//     }

//     setPasted(false);
//   };

//   const handleBlur = () => {
//     setIsChecking(true);
//     setIsValidEmailFormat(validEmail(newUserEmail));
//     setIsChecking(false);
//   };

//   const handlePaste = (e) => {
//     const pastedValue = e.clipboardData.getData("text");
//     const truncatedValue = pastedValue.slice(0, 50);
//     setNewUserEmail(truncatedValue.toLowerCase());
//     setIsValidEmailFormat(validEmail(truncatedValue));
//     setPasted(true);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (isValidEmailFormat) {
//       const storedUserData =
//         JSON.parse(localStorage.getItem("MemoverseUserData")) || {};
//       storedUserData.userEmail = newUserEmail;
//       localStorage.setItem("MemoverseUserData", JSON.stringify(storedUserData));
//       setSnaptrUserData(storedUserData);
//       const userName = localStorage.getItem("MemoverseUserName");
//       const gtagEventData = {
//         ...{ event_user_name: userName },
//         ...{ event_user_email: newUserEmail },
//       };

//       // firebase event: lead_form_submit
//       if (Object.keys(gtagEventData).length > 0) {
//         analyticsEvent("leadFormSubmit", gtagEventData);
//         analyticsEvent("conversion");
//       }

//       handleNextStep({ id, formValues: { Email: newUserEmail }, type: "form" });
//     }
//   };

//   return (
//     <div className={styles.emailStep}>
//       <div className={styles.columnContainer}>
//         <div className={styles.titleContainer}>{step.title}</div>
//         <div className={styles.imgContainer}>
//           <img src={step.image} alt="" className={styles.imgStyle} />
//         </div>
//         {step.copy
//           ? step.copy.map((copy, index) => (
//               <div key={`${id}-${index}`} className={styles.copyConteiner}>
//                 {copy}
//               </div>
//             ))
//           : null}
//       </div>
//       <div className={styles.columnContainer}>
//         <form onSubmit={handleSubmit}>
//           {step.inputs.map((input, index) => (
//             <div key={`input-${id}-${index}`}>
//               <input
//                 type="text"
//                 placeholder={input.placeholder}
//                 name={input.name}
//                 required
//                 value={newUserEmail}
//                 onChange={handleInputChange}
//                 onPaste={handlePaste}
//                 onBlur={handleBlur}
//                 className={
//                   !isValidEmailFormat ? styles.customInput : styles.customInput
//                 }
//               />
//               {!isValidEmailFormat && (
//                 <span className={styles.invalidText}>
//                   Invalid email format.
//                 </span>
//               )}
//             </div>
//           ))}
//           <button
//             className={
//               !isChecking && isValidEmailFormat
//                 ? styles.buttonStyle
//                 : styles.buttonStyleDisable
//             }
//             type="submit"
//           >
//             Continue
//           </button>
//         </form>
//         <div className={styles.noteContainer}>
//           By continuing you indicate that you've read and agree to our
//           <br />
//           <a href="https://memoryos.com/terms" rel="noreferrer" target="_blank">
//             Terms Conditions
//           </a>{" "}
//           &{" "}
//           <a
//             href="https://memoryos.com/privacy-policy"
//             rel="noreferrer"
//             target="_blank"
//           >
//             Privacy Policy
//           </a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EmailStep;
