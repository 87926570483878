import React from "react";

import styles from "./Price.module.css";

const Price = ({ pricePerDay, oldPrice }) => {
  return (
    <div
      className={`${
        oldPrice
          ? styles.optionStylePriceContainer
          : styles.optionStylePriceContainerLow
      }`}
    >
      <div className={styles.optionStyleDollar}>&#36;</div>
      <div className={styles.optionStyleFirstNumber}>
        {pricePerDay.charAt(0)}
      </div>
      <div
        className={`${
          oldPrice
            ? styles.optionStyle_AfterDot_text
            : styles.optionStyle_AfterDot_textLow
        }`}
      >
        <div className={styles.optionStyle_AfterDotTopContainer}>
          <span className={styles.optionStyleElseNumber}>
            {pricePerDay.split(".")[1].slice(0, 2)}
          </span>
          <span className={styles.optionStyleText}>per day</span>
        </div>
        {oldPrice && <span className={styles.oldPrice}>{oldPrice}</span>}
      </div>
    </div>
  );
};

export default Price;
