import React, { createContext, useContext, useEffect, useState } from "react";
import { useFirebaseConfig } from "./FirebaseConfigContext";

const DownSaleContext = createContext();

export const useDownSale = () => useContext(DownSaleContext);

export const DownSaleProvider = ({ children }) => {
  const { downSalePrice, setDownSaleActive } = useFirebaseConfig();

  const [showHideDownSalePopup, setShowHideDownSalePopup] = useState(false);

  const [showHideSpecialOfferPopup, setShowHideSpecialOfferPopup] =
    useState(false);

  useEffect(() => {
    if (showHideDownSalePopup) {
      localStorage.setItem("MemoverseDownSale", true);
      setDownSaleActive(true);
      downSalePrice();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showHideDownSalePopup]);

  const contextValue = {
    showHideDownSalePopup,
    setShowHideDownSalePopup,
    showHideSpecialOfferPopup,
    setShowHideSpecialOfferPopup,
  };

  return (
    <DownSaleContext.Provider value={contextValue}>
      {children}
    </DownSaleContext.Provider>
  );
};
