import React, { useEffect } from "react";
import { useFirebaseConfig } from "../../../context/FirebaseConfigContext";
import { RadioInputProvider } from "../../../context/RadioInputContext";
import { TimerProvider } from "../../../context/TimerContext";
import { useModal } from "../../../context/ModalContext";
import { useControlPaymentPage } from "../../../context/ControlPaymentPageContext";
import { useErrorHandler } from "../../../context/ErrorHandlerContext";
import PageComponent from "../templates/PageComponent/PageComponent";
import ContainerComponent from "../templates/ContainerComponent/ContainerComponent";
import TopDiscount from "./TopDiscount/TopDiscount";
import InfoTitle from "./InfoTitle/InfoTitle";
import YourGrandmaster from "./YourGrandmaster/YourGrandmaster";
import NotableBenefits from "./NotableBenefits/NotableBenefits";
import BrainEfficiently from "./BrainEfficiently/BrainEfficiently";
import AnswerQuestions from "./AnswerQuestions/AnswerQuestions";
import ReviewContainer from "./ReviewContainer/ReviewContainer";
import FooterContainer from "./FooterContainer/FooterContainer";
import PrizesContainer from "./PrizesContainer/PrizesContainer";
import ViewingPageCounter from "./ViewingPageCounter/ViewingPageCounter";
import MoneyBackGuarantee from "./MoneyBackGuarantee/MoneyBackGuarantee";
import WithinMemoryOS from "./WithinMemoryOS/WithinMemoryOS";
import ImgModule from "./ImgModule/ImgModule";
import SpecialDiscountModal from "./SpecialDiscountModal/SpecialDiscountModal";
import DownsaleFlow from "./DownsaleFlow/DownsaleFlow";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import SecondChoosePlan from "./ChoosePlanSecond/SecondChoosePlan";
import JoinTheRest from "./JoinTheRest/JoinTheRest";
import PleaseNote from "./PleaseNote/PleaseNote";
import DidYouKnow from "./DidYouKnow/DidYouKnow";
import WhatIsMemoryOS from "./WhatIsMemoryOS/WhatIsMemoryOS";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import PaymentBlock from "./PaymentBlock/PaymentBlock";

const PlanSub = () => {
  const { showError } = useErrorHandler();
  const { planPageData } = useFirebaseConfig();
  const { showModal } = useModal();
  const { checkQuiz } = useControlPaymentPage();
  useEffect(() => {
    checkQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getComponentByName = (name) => {
    switch (name) {
      case "TopDiscount":
        return <TopDiscount />;
      case "DownsaleFlow":
        return <DownsaleFlow />;
      case "InfoTitle":
        return <InfoTitle />;
      case "ImgModule":
        return <ImgModule />;
      case "ChoosePlan":
        return <ChoosePlan />;
      case "PrizesContainer":
        return <PrizesContainer />;
      case "YourGrandmaster":
        return <YourGrandmaster />;
      case "NotableBenefits":
        return <NotableBenefits />;
      case "BrainEfficiently":
        return <BrainEfficiently />;
      case "DidYouKnow":
        return <DidYouKnow />;
      case "ReviewContainer":
        return <ReviewContainer />;
      case "AnswerQuestions":
        return <AnswerQuestions />;
      case "WithinMemoryOS":
        return <WithinMemoryOS />;
      case "SecondChoosePlan":
        return <SecondChoosePlan />;
      case "MoneyBackGuarantee":
        return <MoneyBackGuarantee />;
      case "ViewingPageCounter":
        return <ViewingPageCounter />;
      case "JoinTheRest":
        return <JoinTheRest />;
      case "PleaseNote":
        return <PleaseNote />;

      case "WhatIsMemoryOS":
        return <WhatIsMemoryOS />;
      default:
        return null;
    }
  };
  const backgroundColor = "linear-gradient(180deg, #3e3a54 0%, #120b3b 100%)";
  const styleOpt = { width: "736px" };
  return (
    <TimerProvider>
      <PageComponent bgColor={backgroundColor}>
        {showModal ? <SpecialDiscountModal /> : false}
        <RadioInputProvider>
          <ContainerComponent styleOption={styleOpt}>
            {showError ? <ErrorHandler /> : null}
            {planPageData
              .sort((a, b) => a.position - b.position)
              .map((item, index) => (
                <React.Fragment key={index}>
                  {item.showHide && getComponentByName(item.component)}
                </React.Fragment>
              ))}
            <PaymentBlock />
          </ContainerComponent>
          <FooterContainer />
        </RadioInputProvider>
      </PageComponent>
    </TimerProvider>
  );
};

export default PlanSub;
