import React, { useState } from "react";

import VideoPlayer from "./VideoPlayer/VideoPlayer";

import Loading from "../Loading/Loading";

import { Step, Column, Title, Button } from "../../styles";
import styles from "./StaticStepStyles.module.css";

const StaticStep = ({ id, step, handleNextStep }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleImageLoad = () => {
    setIsImageLoading(false);
  };

  const handleImageError = () => {
    setIsImageLoading(false);
  };

  return (
    <Step>
      {step.image || step.video ? (
        <>
          <Column>
            {step.image && (
              <div className={styles.imageContainer}>
                {isImageLoading && <Loading />}
                <img
                  className={styles.image}
                  src={step.image}
                  alt={step.title}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  style={{ display: isImageLoading ? "none" : "block" }}
                />
              </div>
            )}

            {step.video ? (
              <div className={styles.videoContainer}>
                <VideoPlayer
                  videoId={step.video}
                  thumbnail={step.thumbnail || ""}
                  autoPlayVideo={step.autoPlayVideo}
                />
              </div>
            ) : null}
          </Column>
          <Column>
            <Title>{step.title}</Title>

            {step.copy.map((copy, index) => (
              <span key={`${id}-${index}`} className={styles.copyText}>
                {copy}
              </span>
            ))}

            <Button
              $isActive={true}
              onClick={() => handleNextStep({ id, type: "static" })}
            >
              Continue
            </Button>
          </Column>
        </>
      ) : (
        <div className={styles.noImgStyle}>
          <Column>
            <Title>{step.title}</Title>
            {step.copy.map((copy, index) => (
              <span key={`${id}-${index}`} className={styles.copyText}>
                {copy}
              </span>
            ))}
            <Button
              $isActive={true}
              onClick={() => handleNextStep({ id, type: "static" })}
            >
              Continue
            </Button>
          </Column>
        </div>
      )}
    </Step>
  );
};

export default StaticStep;
