import React from "react";
import ReactDOM from "react-dom/client";
import * as amplitude from "@amplitude/analytics-browser";
import "./index.css";
import { ConfigDataProvider } from "./context/ConfigDataContext";
import { ScrollProvider } from "./context/ScrollContext";
import { ModalProvider } from "./context/ModalContext";
import { FirebaseConfigProvider } from "./context/FirebaseConfigContext";
import { ControlPaymentPageProvider } from "./context/ControlPaymentPageContext";
import { EventProvider } from "./context/EventContext";
import { UrlProvider } from "./context/urlContext";
import { ErrorHandlerProvider } from "./context/ErrorHandlerContext";
import { BlackFridayProvider } from "./context/BFContext";
import { PaymentProvider } from "./context/PaymentBlockContext";
import { ButtonRefProvider } from "./context/ButtonRefContext";
import { EmailValidatorProvider } from "./context/EmailValidatorContext";
import { DeviceProvider } from "./context/DeviceCheckContext";
import { VWOProvider } from "./context/vwoContext";
import { DevModeProvider } from "./context/DevMode";
import { PaymentFormProvider } from "./context/PaymentFormContext";
import { EnvProvider } from "./context/EnvironmentContext";
import { ABTestProvider } from "./context/ABTestContext";
import { DownSaleProvider } from "./context/DownSaleContext";

// import CookieBot from "react-cookiebot";
import App from "./App";
import { CoockieBotProvider } from "./context/CoockieBotContext";

amplitude.init("ee6a72438081ce5583b67e41c270dbd", {
  defaultTracking: false,
});

const Index = () => {
  // const domainGroupId = "5ea7936a-9bf0-47d5-8b8d-439720c989c1";

  return (
    <>
      {/* <CookieBot domainGroupId={domainGroupId} /> */}
      <DevModeProvider>
        <ErrorHandlerProvider>
          <EnvProvider>
            <DeviceProvider>
              <UrlProvider>
                <FirebaseConfigProvider>
                  <VWOProvider>
                    <ConfigDataProvider>
                      <ControlPaymentPageProvider>
                        <ScrollProvider>
                          <ModalProvider>
                            <EventProvider>
                              <ButtonRefProvider>
                                <BlackFridayProvider>
                                  <PaymentProvider>
                                    <EmailValidatorProvider>
                                      <PaymentFormProvider>
                                        <ABTestProvider>
                                          <DownSaleProvider>
                                            <CoockieBotProvider>
                                              <App />
                                            </CoockieBotProvider>
                                          </DownSaleProvider>
                                        </ABTestProvider>
                                      </PaymentFormProvider>
                                    </EmailValidatorProvider>
                                  </PaymentProvider>
                                </BlackFridayProvider>
                              </ButtonRefProvider>
                            </EventProvider>
                          </ModalProvider>
                        </ScrollProvider>
                      </ControlPaymentPageProvider>
                    </ConfigDataProvider>
                  </VWOProvider>
                </FirebaseConfigProvider>
              </UrlProvider>
            </DeviceProvider>
          </EnvProvider>
        </ErrorHandlerProvider>
      </DevModeProvider>
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
