import React, { createContext, useContext, useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
import {
  getRemoteConfig,
  fetchAndActivate,
  getAll,
} from "firebase/remote-config";

import { useEnvContext } from "./EnvironmentContext";

import Loading from "../pages/Plan/PaidTrialsAllSubs/Loading/Loading";

const FirebaseConfigContext = createContext();
export const useFirebaseConfig = () => useContext(FirebaseConfigContext);

export const FirebaseConfigProvider = ({ children }) => {
  const { isProduction } = useEnvContext();

  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState({});
  const [landingData, setLandingData] = useState({});
  const [appData, setAppData] = useState({});
  const [quizData, setQuiz] = useState({});
  const [feedbackData, setFeedbackData] = useState({});
  const [planPageData, setPlanPageData] = useState({});
  const [newPlanPage, setNewPlanPage] = useState({});
  const [newPageReviews, setNewPageReviews] = useState([]);
  const [newPageVideoReviews, setVideoReviews] = useState([]);
  const [fbEvent, setFbEvent] = useState(false);
  const [paymentButtons, setPaymentButtons] = useState({});
  const [offDiscount, setOffDicount] = useState({});
  const [apiGeoUrl, setApiGeoUrl] = useState("");
  const [currentDesign, setCurrentDesign] = useState("");
  const [bfPaymentPlan, setBFPaymentPlan] = useState({});
  const [redirectUrl, setRedirectUrl] = useState("");
  const [emailProviders, setEmailProviders] = useState({});
  const [optionTextData, setOptionTextData] = useState({});
  const [specialOptionTextData, setSpecialOptionTextData] = useState({});
  const [abTestPriceConfig, setABtestPriceConfig] = useState({});
  const [abTestDesignVariant, setABTestDesignVariant] = useState(null);
  const [abTestCampaignName, setABtestCampaignName] = useState(null);
  const [abTestStartCampaignDate, setABtestStartCampaignDate] = useState(null);
  const [abTestPriceVariants, setABtestPriceVariants] = useState([]);
  const [defaultPriceVariant, setDefaultPriceVariant] = useState(null);
  const [defaultPriceOptions, setDefaultPriceOptions] = useState({});
  const [emailsToDisableAnalyticsEvent, setEmailsToDisableAnalyticsEvent] =
    useState([]);

  const [isConfigReady, setIsConfigReady] = useState(false);

  const [prodTextData, setProdTextData] = useState({});
  const [stageTextData, setStageTextData] = useState({});

  const [varBPriceConfig, setVarBPriceConfig] = useState([]);

  const [downSaleActive, setDownSaleActive] = useState(false);

  const [useDefault, setUseDefault] = useState(false);

  const [discountPageData, setDiscountPageData] = useState([]);

  const firebaseConfig = {
    apiKey: "AIzaSyDDEVWxCxP8u_hvobFFusUHuOgkDuGCk6s",
    authDomain: "memoryos-web-funnel.firebaseapp.com",
    projectId: "memoryos-web-funnel",
    storageBucket: "memoryos-web-funnel.appspot.com",
    messagingSenderId: "1056744550522",
    appId: "1:1056744550522:web:f51c7114e8af84f7760bc3",
    measurementId: "G-QWT83PZ7FZ",
  };

  const app = initializeApp(firebaseConfig);
  const remoteConfig = getRemoteConfig(app);

  firebase.initializeApp(firebaseConfig);
  const analyticsFirebase = firebase.analytics();

  remoteConfig.settings.minimumFetchIntervalMillis = 100;

  useEffect(() => {
    fetchAndActivate(remoteConfig)
      .then(() => {
        const data = getAll(remoteConfig);

        const dataConfig = JSON.parse(data.AppConfig._value);

        const applyDiscountConfig = JSON.parse(data.ApplyDiscountPage._value);
        setDiscountPageData(applyDiscountConfig);

        const abTestDataConfig = JSON.parse(data.AB_test_config._value);

        const firebaseTextOptionData = JSON.parse(data.OptionTextConfig._value);
        const stagingFirebaseTextOptionData = JSON.parse(
          data.StagingOptionTextConfig._value
        );
        setProdTextData(firebaseTextOptionData);
        setStageTextData(stagingFirebaseTextOptionData);

        setDefaultPriceOptions(abTestDataConfig.defaultPriceVariant);

        if (isProduction) {
          setABtestPriceConfig(abTestDataConfig.priceVariantConfig);
          setABTestDesignVariant(abTestDataConfig.variantABTest);
          setOptionTextData(firebaseTextOptionData);
          setDefaultPriceVariant(abTestDataConfig.defaultPriceVariantConfig);
        } else {
          setABtestPriceConfig(abTestDataConfig.stagingPriceVariantConfig);
          setABTestDesignVariant(abTestDataConfig.stagingVariantABTest);
          setOptionTextData(stagingFirebaseTextOptionData);
          setDefaultPriceVariant(abTestDataConfig.defaultPriceVariantConfig);
        }

        const abTestConfig = JSON.parse(data.AB_Test_Prices_Variants._value);
        setABtestPriceVariants(abTestConfig);

        setEmailsToDisableAnalyticsEvent(
          dataConfig.teamEmailsToDisableAnalyticsEvents
        );

        setApiGeoUrl(dataConfig.appConfig.REACT_APP_GEO_API);

        setABtestCampaignName(
          dataConfig.appConfig.REACT_APP_A_B_TEST_CAMPAIGN_NAME
        );
        setABtestStartCampaignDate(
          dataConfig.appConfig.REACT_APP_START_TEST_CAMPAIGN_DATE
        );

        setAppData(dataConfig.appConfig);
        setFbEvent(dataConfig.fb_purchase_event);
        setLandingData(dataConfig.landingDefaultConfig);
        setPlanPageData(dataConfig.componentsPositionView);
        setPaymentButtons(dataConfig.payment_buttons);
        setCurrentDesign(dataConfig.useDesign);
        setRedirectUrl(dataConfig.reservePageRedirectURL);
        setEmailProviders(dataConfig.emailServices);

        const pageDataConfig = JSON.parse(data.New_Plan_Page._value);
        setNewPlanPage(pageDataConfig.newPlanPage);
        setNewPageReviews(pageDataConfig.reviews);
        setVideoReviews(pageDataConfig.videoReviews);

        const bfPlan = JSON.parse(data.BF_Expert_Plan._value);
        setBFPaymentPlan(bfPlan[0]);

        const feedbackConfig = JSON.parse(data.Feedback._value);
        setFeedbackData(feedbackConfig);

        switch (dataConfig.usePlan.setPlan) {
          case "A":
            const ExpertPlan = JSON.parse(data.Expert_Payment_Plan._value);
            setConfig(ExpertPlan[0]);
            setOffDicount(false);
            break;
          case "B":
            const NewExpertPlan = JSON.parse(data.Expert_Plan_7d_trial._value);
            setConfig(NewExpertPlan[0]);
            setOffDicount(true);
            break;
          case "C":
            const NewExpertTrialPlan = JSON.parse(
              data.Expert_Plan_7d_trial_0_USD._value
            );
            setConfig(NewExpertTrialPlan[0]);
            setOffDicount(true);
            break;
          default:
            console.log("Error: no plan data!");
            break;
        }
        if (isProduction) {
          const quizName = dataConfig.useQuiz;
          const qiuzDataFirebase = JSON.parse(data[quizName]._value);
          setQuiz(qiuzDataFirebase);
        } else {
          const qiuzDataFirebase = JSON.parse(data.Quiz_Staging._value);
          setQuiz(qiuzDataFirebase);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
        setIsConfigReady(true);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteConfig]);

  useEffect(() => {
    if (prodTextData) {
      setSpecialOptionTextData(prodTextData.dwnSaleData);
    }
  }, [prodTextData]);

  const setABTestPrice = (option) => {
    const number = parseInt(option);

    const newPath = window.location.pathname;

    if (newPath !== "/new/plan") {
      if (useDefault === true) {
        if (number !== null && number !== undefined) {
          const [startDefA, endDefA] = defaultPriceOptions.A[number];
          const selectedDefVariantsA = abTestPriceVariants.slice(
            startDefA,
            endDefA
          );
          setVarBPriceConfig(selectedDefVariantsA);
        } else {
          console.log(
            `Variant "${number}" in "${JSON.stringify(
              abTestPriceVariants
            )}" not found.`
          );
        }
      } else {
        if (number !== null && number !== undefined) {
          console.log(`Current prise option: ${number}`);

          switch (abTestDesignVariant) {
            case "A":
              if (number === 1) {
                setConfig(abTestPriceVariants[number]);
                setOffDicount(true);
              } else if (number === 2) {
                const [startA, endA] = abTestPriceConfig.A[2];
                const selectedVariantsA = abTestPriceVariants.slice(
                  startA,
                  endA
                );
                setVarBPriceConfig(selectedVariantsA);
              }
              break;
            case "B":
              const [startB, endB] = abTestPriceConfig.B[number];
              const selectedVariantsB = abTestPriceVariants.slice(startB, endB);
              setVarBPriceConfig(selectedVariantsB);
              break;
            case "C":
              const [startC, endC] = abTestPriceConfig.C[number];
              const selectedVariantsC = abTestPriceVariants.slice(startC, endC);
              setVarBPriceConfig(selectedVariantsC);
              break;
            default:
              console.log("Error to chose price variant");
              return;
          }
        } else {
          console.log(
            `Variant "${number}" in "${JSON.stringify(
              abTestPriceVariants
            )}" not found.`
          );
        }
      }
    }
  };

  const swapTextData = (production, activeDownsale) => {
    const data = production ? prodTextData : stageTextData;

    setOptionTextData(activeDownsale ? data.dwnSaleData : data);
  };

  // eslint-disable-next-line no-unused-vars
  // const logProductID = (variants) => {
  //   return (
  //     console.log(
  //       `ID-6mth: ${variants[0].monthsToChoose[0].priceOptions[0].productID}`
  //     ),
  //     console.log(
  //       `ID-6mthTrial: ${variants[0].monthsToChoose[1].priceOptions[0].productID}`
  //     ),
  //     console.log(
  //       `ID-Mnth: ${variants[1].monthsToChoose[0].priceOptions[0].productID}`
  //     )
  //   );
  // };

  const downSalePrice = (index) => {
    const savedValue = localStorage.getItem("MemoverseDownSale");
    setDownSaleActive(savedValue ? JSON.parse(savedValue) : false);

    if (!downSaleActive) {
      if (index === undefined) {
        const [startD, endD] = abTestPriceConfig.D[1];
        const selectedVariantsD = abTestPriceVariants.slice(startD, endD);
        setVarBPriceConfig(selectedVariantsD);
      } else if (index !== null && index !== undefined) {
        switch (index) {
          case 0:
            const indexZero = abTestPriceVariants.slice(28, 30);
            setVarBPriceConfig(indexZero);
            break;
          case 1:
            const indexOne = abTestPriceVariants.slice(30, 32);
            setVarBPriceConfig(indexOne);
            break;
          case 2:
            const indexTwo = abTestPriceVariants.slice(32, 34);
            setVarBPriceConfig(indexTwo);
            break;
          case 3:
            const indexThree = abTestPriceVariants.slice(34, 36);
            setVarBPriceConfig(indexThree);
            break;

          default:
            break;
        }
      }
    } else if (downSaleActive) {
      if (index === undefined) {
        const [startD, endD] = abTestPriceConfig.D[2];
        const selectedVariantsD = abTestPriceVariants.slice(startD, endD);
        setVarBPriceConfig(selectedVariantsD);

        // logProductID(selectedVariantsD);
      } else if (index !== null && index !== undefined) {
        switch (index) {
          case 0:
            const indexZero = abTestPriceVariants.slice(36, 38);
            setVarBPriceConfig(indexZero);

            break;
          case 1:
            const indexOne = abTestPriceVariants.slice(38, 40);
            setVarBPriceConfig(indexOne);

            break;
          case 2:
            const indexTwo = abTestPriceVariants.slice(40, 42);
            setVarBPriceConfig(indexTwo);

            break;
          case 3:
            const indexThree = abTestPriceVariants.slice(42, 44);
            setVarBPriceConfig(indexThree);

            break;

          default:
            break;
        }
      }
    }
  };

  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  useEffect(() => {
    if (!isConfigReady) return;

    const handlePathChange = () => {
      const newPath = window.location.pathname;
      setCurrentPath(newPath);

      if (newPath === "/new/plan") {
        downSalePrice();
        swapTextData(isProduction, downSaleActive);
      }
    };

    handlePathChange();

    window.addEventListener("popstate", handlePathChange);

    return () => {
      window.removeEventListener("popstate", handlePathChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isConfigReady,
    isProduction,
    downSaleActive,
    currentPath,
    // varBPriceConfig,
  ]);

  if (loading) {
    return <Loading />;
  }

  const contextValue = {
    discountPageData,
    optionTextData,
    apiGeoUrl,
    abTestDesignVariant,
    abTestCampaignName,
    abTestStartCampaignDate,
    setABTestPrice,
    varBPriceConfig,
    defaultPriceVariant,
    analyticsFirebase,
    paymentButtons,
    quizData,
    config,
    appData,
    feedbackData,
    planPageData,
    newPlanPage,
    newPageReviews,
    newPageVideoReviews,
    landingData,
    fbEvent,
    offDiscount,
    currentDesign,
    bfPaymentPlan,
    redirectUrl,
    emailProviders,
    emailsToDisableAnalyticsEvent,
    setUseDefault,
    downSalePrice,
    downSaleActive,
    setDownSaleActive,
    swapTextData,
    setCurrentPath,
    specialOptionTextData,
  };

  return (
    <FirebaseConfigContext.Provider value={contextValue}>
      {children}
    </FirebaseConfigContext.Provider>
  );
};
