import React from "react";
import { useDiscount } from "../../../../../../context/ApplyDiscountContext";

import styles from "../PaymentFormStyle.module.css";

const HRVariantB = () => {
  const { selectedDiscountOption } = useDiscount();

  return (
    <div className={styles.secureContainer}>
      {selectedDiscountOption === 0 ? (
        <div className={styles.secureHrTwo} />
      ) : (
        <div className={styles.secureHr} />
      )}
      <div className={styles.secureImg} />

      <span className={styles.secureText}>
        {selectedDiscountOption === 0
          ? "No payment now. Secured by:"
          : "Secured by:"}
      </span>
      {selectedDiscountOption === 0 ? (
        <div className={styles.secureHrTwo} />
      ) : (
        <div className={styles.secureHr} />
      )}
    </div>
  );
};

export default HRVariantB;
