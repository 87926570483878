import React, { useEffect } from "react";
import { useABtest } from "../../../../../context/ABTestContext";
import { useTimer } from "../../../../../context/TimerContext";

import PaymentButton from "../../PaymentButton/PaymentButton";
import FirstPlanOption from "./PlanOption/FirstPlanOption";
import SecondPlanOption from "./PlanOption/SecondPlanOption";
import ToggleButton from "../ToggleButton/ToggleButton";

import Loading from "../../Loading/Loading";
import styles from "./VariantBsecondStyle.module.css";

const VariantBsecond = () => {
  const { showHideElement } = useTimer();

  const {
    isActive,
    setIsActive,
    firstOptionRender,
    secondOptionRender,
    selectedABOption,
    setSelectedOption,
    handleOptionChangeWithToggle,
  } = useABtest();

  const handleToggle = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (isActive) {
      setSelectedOption(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className={styles.PlanContainer}>
      <div className={styles.OptionsPlanContainer}>
        {firstOptionRender.length === 0 ? (
          <Loading />
        ) : (
          <>
            <FirstPlanOption
              firstOptionRender={
                !isActive ? firstOptionRender[0] : firstOptionRender[1]
              }
              selectedOption={selectedABOption}
              handleOptionChangeWithToggle={handleOptionChangeWithToggle}
            />
            <SecondPlanOption
              secondOptionRender={secondOptionRender[0]}
              selectedOption={selectedABOption}
              handleOptionChangeWithToggle={handleOptionChangeWithToggle}
            />
          </>
        )}
      </div>
      {showHideElement && <ToggleButton handleToggle={handleToggle} />}
      <PaymentButton />
    </div>
  );
};

export default VariantBsecond;
