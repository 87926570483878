import React, { createContext, useContext, useState, useEffect } from "react";

const DeviceContext = createContext();

export const useDevice = () => useContext(DeviceContext);

const detectDevice = () => {
  const userAgent = window.navigator.userAgent;
  return /(Mac|iPhone|iPad|iPod)/.test(userAgent);
};

export const DeviceProvider = ({ children }) => {
  const [isMacOS, setIsMacOS] = useState(detectDevice());

  useEffect(() => {
    setIsMacOS(detectDevice());
  }, []);

  const contextValue = {
    isMacOS,
  };

  return (
    <DeviceContext.Provider value={contextValue}>
      {children}
    </DeviceContext.Provider>
  );
};