import React from "react";
import { useFirebaseConfig } from "../../../../../../context/FirebaseConfigContext";

import Price from "../Price/Price";

import styles from "./OptionsStyle.module.css";

const SecondPlanOption = ({
  secondOptionRender,
  selectedOption,
  handleOptionChangeWithToggle,
}) => {
  const { downSaleActive } = useFirebaseConfig();

  const randomId = Math.round(Math.random() * 1000000);

  return (
    <label
      className={`${styles.elementContainerSecond} ${
        selectedOption === 1 ? styles.elementContainerSecondActive : ""
      }`}
      style={{
        height: downSaleActive !== true ? "76px" : "100px",
      }}
      htmlFor={`${secondOptionRender.productID}${randomId}`}
      key={secondOptionRender.productID}
    >
      {secondOptionRender.mostPopular && (
        <div className={styles.mostPopular}>
          <span className={styles.mostPopularText}>MOST POPULAR</span>
        </div>
      )}
      <div
        className={styles.optionContainerSecond}
        style={
          {
            // height: downSaleActive !== true ? "76px" : "100px",
          }
        }
      >
        <input
          type="radio"
          name={secondOptionRender.month || secondOptionRender.days}
          id={`${secondOptionRender.productID}${randomId}`}
          value={secondOptionRender.month || secondOptionRender.days}
          checked={selectedOption === 1}
          onChange={() => handleOptionChangeWithToggle(1)}
          style={{
            width: 0,
            height: 0,
            padding: 0,
            margin: 0,
            display: "none",
          }}
        />

        <div
          // className={styles.optionSecondPlanContainer}
          className={`${
            Array.isArray(secondOptionRender.saveText)
              ? styles.optionHeaderContainer
              : styles.optionSecondPlanContainer
          }`}
        >
          <span className={styles.optionHeaderText}>
            {secondOptionRender.month}
          </span>
          {secondOptionRender.saveText !== "" && (
            <div>
              {Array.isArray(secondOptionRender.saveText) ? (
                <div className={styles.saveTextPartsContainer}>
                  <div className={styles.saveTextPartsContainerNormal}>
                    <span className={styles.partTextCrossed}>
                      {secondOptionRender.saveText[0]}
                    </span>
                    <span className={styles.partTextNormal}>
                      {secondOptionRender.saveText[1]}
                    </span>

                    {secondOptionRender.saveText.length >= 4 && (
                      <span className={styles.partTextNormal}>
                        {secondOptionRender.saveText[2]}
                      </span>
                    )}
                  </div>
                  <span
                    className={`${styles.partTextGreen} ${
                      selectedOption === 1 ? styles.partTextGreenActive : ""
                    }`}
                  >
                    {secondOptionRender.saveText[2]}
                  </span>
                </div>
              ) : (
                <span className={styles.partTextNormal}>
                  {secondOptionRender.saveText}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      <Price
        pricePerDay={secondOptionRender.pricePerDay}
        oldPrice={secondOptionRender.oldPricePerDay}
      />
    </label>
  );
};

export default SecondPlanOption;
