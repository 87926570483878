import React from "react";
import SafeIconSVG from "./assets/SafeIcon";
import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";
import FooterTextContainer from "../../ChoosePlanSecond/FooterBlock/FooterTextContainer/FooterTextContainer";

import styles from "./FooterBlockStyle.module.css";

const FooterBlock = ({ data, footerData }) => {

  return (
    <div className={styles.container}>
      <GraySafeBlock
        text={data.headerText}
        textSecond={data.headerTextOffDiscount}
      />

      <div className={styles.FooterSafe}>
        <div className={styles.FooterSafeIcon}>
          <SafeIconSVG />
        </div>
        <span className={styles.FooterSafeText}>{data.footerText}</span>
      </div>
      <FooterTextContainer data={footerData} />
    </div>
  );
};

export default FooterBlock;
