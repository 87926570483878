import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";

import RatingContainer from "./RatingContainer/RatingContainer";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

import styles from "./InfoTitle.module.css";

const InfoTitle = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "TopDoubleComponent");

  return (
    <div className={styles.InfoTitleContainer}>
      {data.components[0].showHideLogo && (
        <div className={styles.InfoTitleLogo}>
          <LogoGlobal />
        </div>
      )}

      <div className={styles.InfoTitleContainerHeader}>
        {data.components[0].textHeader}
      </div>
      <RatingContainer />
      <div className={styles.InfoTitleContainerRatingText}>
        {data.components[0].textMain}
      </div>
    </div>
  );
};

export default InfoTitle;
