import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useVWOContext } from "../../../../context/vwoContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import VariantB from "./DesignRender/VariantB";
import VariantA from "./DesignRender/VariantA";

import styles from "./WithinMemoryOS.module.css";

const WithinMemoryOS = () => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();
  const { findDataComponent } = useConfig();
  const { variantNumber } = useVWOContext();

  const data = findDataComponent(2, "WithinMemoryOS");

  return (
    <div className={styles.container}>
      <div className={styles.headText}>{data.headerText}</div>

      <div className={styles.firstContainer}>
        {data.steps.map((step, index) => {
          return (
            <div key={index} className={styles.firstStepElement}>
              <div className={styles.WithinMemoryOSCheck} />
              <div className={styles.WithinMemoryOSText}>{step}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.hrLine} />
      {abTestDesignVariant === "A" ? (
        <VariantA variantNumber={variantNumber} data={data} />
      ) : (
        <VariantB
          variantNumber={variantNumber}
          data={optionTextData.planAchivment}
        />
      )}
    </div>
  );
};

export default WithinMemoryOS;
