import React, { useEffect } from "react";
import { useFirebaseConfig } from "../../../context/FirebaseConfigContext";
import { TimerProvider } from "../../../context/TimerContext";
import { useControlPaymentPage } from "../../../context/ControlPaymentPageContext";
import { useErrorHandler } from "../../../context/ErrorHandlerContext";
import { useEvent } from "../../../context/EventContext";
import { ApplyDiscountProvider } from "../../../context/ApplyDiscountContext";

import PageComponent from "../templates/PageComponent/PageComponent";
import ContainerComponent from "../templates/ContainerComponent/ContainerComponent";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import WhatIsMemoryOS from "../ABtestPlanPage/WhatIsMemoryOS/WhatIsMemoryOS";
import YourGrandmaster from "../ABtestPlanPage/YourGrandmaster/YourGrandmaster";
import DidYouKnow from "../ABtestPlanPage/DidYouKnow/DidYouKnow";
import AnswerQuestions from "../ABtestPlanPage/AnswerQuestions/AnswerQuestions";
import FooterContainer from "../ABtestPlanPage/FooterContainer/FooterContainer";
import PrizesContainer from "../ABtestPlanPage/PrizesContainer/PrizesContainer";
import ViewingPageCounter from "../ABtestPlanPage/ViewingPageCounter/ViewingPageCounter";
import JoinTheRest from "../ABtestPlanPage/JoinTheRest/JoinTheRest";
import MoneyBackGuarantee from "../ABtestPlanPage/MoneyBackGuarantee/MoneyBackGuarantee";
import ReviewContainer from "../ABtestPlanPage/ReviewContainer/ReviewContainer";
import InfoImgBlock from "../ABtestPlanPage/InfoImgBlock/InfoImgBlock";

import TopDiscount from "./TopDiscount/TopDiscount";
import TopDoubleComponent from "./TopDoubleComponent/TopDoubleComponent";
import WithinMemoryOS from "./WithinMemoryOS/WithinMemoryOS";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import SecondChoosePlan from "./ChoosePlanSecond/SecondChoosePlan";
import PleaseNote from "./PleaseNote/PleaseNote";
import PaymentBlock from "./PaymentBlock/PaymentBlock";
import MoreEfficiently from "./MoreEfficiently/MoreEfficiently";

const ApplyDiscount = () => {
  const { showError } = useErrorHandler();
  const { newPlanPage } = useFirebaseConfig();

  const { analyticsEvent } = useEvent();

  const { checkQuiz } = useControlPaymentPage();
  useEffect(() => {
    checkQuiz();
    analyticsEvent("addToCart");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getComponentByName = (name) => {
    switch (name) {
      case "TopDiscount":
        return <TopDiscount />;
      case "TopDoubleComponent":
        return <TopDoubleComponent />;
      case "ChoosePlan":
        return <ChoosePlan />;
      case "PrizesContainer":
        return <PrizesContainer />;
      case "WhatIsMemoryOS":
        return <WhatIsMemoryOS />;
      case "WithinMemoryOS":
        return <WithinMemoryOS />;
      case "YourGrandmaster":
        return <YourGrandmaster />;
      case "InfoImgBlock":
        return <InfoImgBlock />;
      case "MoreEfficiently":
        return <MoreEfficiently />;
      case "DidYouKnow":
        return <DidYouKnow />;
      case "ReviewContainer":
        return <ReviewContainer />;
      case "AnswerQuestions":
        return <AnswerQuestions />;
      case "SecondChoosePlan":
        return <SecondChoosePlan />;
      case "MoneyBackGuarantee":
        return <MoneyBackGuarantee />;
      case "ViewingPageCounter":
        return <ViewingPageCounter />;
      case "JoinTheRest":
        return <JoinTheRest />;
      case "PleaseNote":
        return <PleaseNote />;

      default:
        return null;
    }
  };
  const backgroundColor = "none";
  const styleOpt = { width: "100%" };
  return (
    <TimerProvider>
      <PageComponent bgColor={backgroundColor}>
        <ApplyDiscountProvider>
          <ContainerComponent styleOption={styleOpt}>
            {showError ? <ErrorHandler /> : null}
            {newPlanPage
              .sort((a, b) => a.position - b.position)
              .map((item, index) => (
                <React.Fragment key={index}>
                  {item.showHide && getComponentByName(item.component)}
                </React.Fragment>
              ))}
            <PaymentBlock />
          </ContainerComponent>
          <FooterContainer />
        </ApplyDiscountProvider>
      </PageComponent>
    </TimerProvider>
  );
};

export default ApplyDiscount;
