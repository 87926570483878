import React from "react";

import styles from "./PlanDetails.module.css";

const PlanDetails = ({ selectedOption }) => {
  return (
    <div className={styles.container}>
      <div className={styles.priceInfoCont}>
        <div className={styles.optionContainer}>
          <span className={styles.optionInfo}>{selectedOption.planName}</span>
          <span className={styles.optionInfo}>
          &#36;{selectedOption.fullPlanPrice}.00
          </span>
        </div>
        <div className={styles.optionContainer}>
          <span
            className={styles.optionInfo}
            style={{
              color: "rgba(109, 141, 255, 1)",
            }}
          >
            {selectedOption.infoText[0]}
          </span>
          <span className={styles.optionInfo} style={{
              color: "red",
            }}>
            {selectedOption.savingsAmount}
          </span>
        </div>
        <div className={styles.optionContainer}>
          <span className={styles.optionInfo}>
            {selectedOption.infoText[1]}
          </span>
          <span className={styles.optionInfo}>
            {selectedOption.dueTimeAmount}
          </span>
        </div>
      </div>
      <div className={styles.totalContainer}>
        <span className={styles.totalText}>{selectedOption.infoText[2]}</span>
        <span className={styles.totalText}>{selectedOption.priceTotal}</span>
      </div>
      
    </div>
  );
};

export default PlanDetails;
