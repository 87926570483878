import React from "react";

import styles from "./SliderRatingStyle.module.css"

const SliderRating = ({ rate }) => {
  const ratings = Array.from({ length: rate }, (_, index) => (
    <img
      key={index}
      src="./img/rating_star.png"
      alt="rating_star"
      className={styles.star}
    />
  ));

  return (
    <div className={styles.starContainer}>
      {ratings.map((rating, index) => (
        <div key={index}>{rating}</div>
      ))}
    </div>
  );
};

export default SliderRating;
