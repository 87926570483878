import React from "react";

import styles from "./AgeStepStyle.module.css";

const AgeStep = ({ id, step, handleNextStep }) => {
  const answerImg = [
    "../img/quizAge1.png",
    "../img/quizAge2.png",
    "../img/quizAge3.png",
    "../img/quizAge4.png",
  ];

  return (
    <div className={styles.container}>
      <span className={styles.title}>{step.title}</span>
      <span className={styles.subTitle}>{step.subTitle}</span>
      <div className={styles.answerButtonsContainer}>
        {step.answers.map((answer, index) => (
          <button
            key={`${id}-${index}`}
            onClick={() =>
              handleNextStep({ id, answer, answerID: index, type: "ageStep" })
            }
            className={styles.answerButton}
          >
            <img
              src={answer.img ? answer.img : answerImg[index]}
              alt=""
              className={styles.buttonImg}
            />
            <div className={styles.textContainer}>
              <span className={styles.answerText}>{answer.text}</span>
              <img
                src="../img/arrowNarrowRight.png"
                alt="arrow-right"
                className={styles.arrowRight}
              />
            </div>
            <div className={styles.blueLine}/>
          </button>
        ))}
      </div>
    </div>
  );
};

export default AgeStep;
