import React from "react";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import { useConfig } from "../../../../context/ConfigDataContext";

import RatingContainer from "./RatingContainer/RatingContainer";
import StoreSlider from "./StoreSlider/StoreSlider";

import VideoStoreSlider from "./VideoSlider/VideoStoreSlider";

import styles from "./ReviewContainer.module.css";

const ReviewContainer = () => {
  const { newPageReviews, newPageVideoReviews } = useFirebaseConfig();

  const { findDataComponent } = useConfig();

  const data = findDataComponent(2, "ReviewContainer");

  return (
    <div className={styles.container}>
      <div className={styles.headerCont}>
        <span className={styles.headerText}>{data.headerText}</span>
        <div className={styles.mainCont}>
          <div className={styles.ratingPosition}>
            <RatingContainer />
          </div>
          <span className={styles.mainText}>{data.mainText}</span>
        </div>
      </div>
      <StoreSlider dataReview={newPageReviews} delayProps={data.delayProps} />
      <VideoStoreSlider
        videoText={data.videoText}
        videoReview={newPageVideoReviews}
        delayProps={data.delayProps}
      />
    </div>
  );
};

export default ReviewContainer;
