import React, { useEffect, useRef, useState } from "react";
import Player from "react-player";

import styles from "./VideoPlayerStyles.module.css";

const VideoPlayer = ({ linkVideo, linkPoster }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const containerRef = useRef(null);

  const [widthCont, setWidth] = useState("");
  const [heightCont, setHeight] = useState("");
  const [radiusCont, setRadius] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        setWidth(`${containerWidth}px`);
        setHeight(`${containerHeight}px`);
        const containerStyles = window.getComputedStyle(containerRef.current);
        const borderRadius = containerStyles.getPropertyValue("border-radius");
        setRadius(borderRadius);
      }
    };

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  const loadVimeoVideo = (e) => {
    e.preventDefault();
    setIsPlaying(true);
  };

  return (
    <div className={styles.container} ref={containerRef}>
      <Player
        url={linkVideo}
        controls={isPlaying ? false : true}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        light={linkPoster || ""}
        playing={isPlaying}
        fluid="true"
        width={`${widthCont}`}
        height={`${heightCont}`}
        style={{
          margin: "0 auto",
          borderRadius: `${radiusCont}`,
          overflow: "hidden",
          display: "block",
          cursor: "pointer",
        }}
        onClick={loadVimeoVideo}
      />
    </div>
  );
};

export default VideoPlayer;
