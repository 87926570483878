import React, { useEffect, useState } from "react";
import styles from "../ChoosePlan.module.css";
import { useABtest } from "../../../../../context/ABTestContext";

const HeaderVariantB = ({ data, timer, variantNumber }) => {
  const { isActive } = useABtest();
  const [renderText, setRenderText] = useState("");

  useEffect(() => {
    if (data && data.textWithTimer && data.textWithoutTimer) {
      const index = variantNumber - 1;
      const textWithTimer = data.textWithTimer[index];
      const textWithoutTimer = data.textWithoutTimer[index];

      if (timer > 0 && textWithTimer) {
        setRenderText(isActive ? textWithTimer[1] : textWithTimer[0]);
      } else if (textWithoutTimer) {
        setRenderText(textWithoutTimer);
      }
    }
  }, [isActive, timer, variantNumber, data]);

  return <div className={styles.HeaderText}>{renderText}</div>;
};

export default HeaderVariantB;
