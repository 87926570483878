import React from "react";

const SafeIconSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0374 4.38647C10.7068 4.18742 10.2933 4.18742 9.96268 4.38648L9.53242 4.64555C8.3814 5.33864 7.08921 5.76395 5.75155 5.88997L5.48048 5.91551C5.37346 5.92559 5.29169 6.01544 5.29169 6.12293V7.49283C5.29169 9.58245 6.14004 11.5825 7.64242 13.0349L10.3552 15.6574C10.436 15.7355 10.5641 15.7355 10.6448 15.6574L13.3576 13.0349C14.86 11.5825 15.7084 9.58245 15.7084 7.49283V6.12293C15.7084 6.01544 15.6266 5.92559 15.5196 5.91551L15.2485 5.88997C13.9108 5.76395 12.6186 5.33864 11.4676 4.64555L11.0374 4.38647ZM9.31786 3.31563C10.0451 2.8777 10.9549 2.8777 11.6822 3.31563L12.1124 3.5747C13.1028 4.17108 14.2147 4.53704 15.3657 4.64548L15.6368 4.67102C16.3859 4.7416 16.9584 5.3705 16.9584 6.12293V7.49283C16.9584 9.9213 15.9724 12.2457 14.2264 13.9336L11.5136 16.5561C10.9484 17.1026 10.0517 17.1026 9.48642 16.5561L6.77362 13.9336C5.02761 12.2457 4.04169 9.92131 4.04169 7.49283V6.12293C4.04169 5.3705 4.61413 4.7416 5.36323 4.67102L5.6343 4.64548C6.78531 4.53704 7.89719 4.17108 8.88761 3.5747L9.31786 3.31563Z"
        fill="#4BEEB3"
      />
    </svg>
  );
};

export default SafeIconSVG;
