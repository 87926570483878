import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Payment from "@solidgate/react-sdk";

import { useErrorHandler } from "../../../../../context/ErrorHandlerContext";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";
import { useEvent } from "../../../../../context/EventContext";
import { usePaymentBlock } from "../../../../../context/PaymentBlockContext";
import { useConfig } from "../../../../../context/ConfigDataContext";
import { useVWOContext } from "../../../../../context/vwoContext";
import { usePaymentForm } from "../../../../../context/PaymentFormContext";
import { useDownSale } from "../../../../../context/DownSaleContext";

import PlanDetails from "./PlanDetails/PlanDetails";
import EmailBlock from "./EmailBlock/EmailBlock";
import PaymentSystems from "./PaymentSystems/PaymentSystems";

import styles from "./PaymentFormStyle.module.css";
// import HRVariantA from "./HRLineVariants/HRVariantA";
// import HRVariantB from "./HRLineVariants/HRVariantB";
import Loader from "../../../../templates/Loader/Loader";
import { useTimer } from "../../../../../context/TimerContext";
import HRContainer from "./HRLineVariants/HRContainer";
import BottomDetails from "./BottomDetails/BottomDetails";

const PaymentForm = () => {
  const { setErrorHandler } = useErrorHandler();
  const { timer } = useTimer();
  const {
    appData,
    paymentButtons,
    offDiscount,
    // abTestDesignVariant,
    downSaleActive,
  } = useFirebaseConfig();
  const { analyticsEvent } = useEvent();
  const { findDataComponent } = useConfig();
  const { variantNumber } = useVWOContext();
  const { setStartPaymentForm, setOrderLocalData } = usePaymentBlock();
  const {
    currentOrder,
    merchantDataSet,
    navigateObserver,
    scriptUrl,
    startOrderStatusCheck,
    prepareOrder,
    isLoading,
  } = usePaymentForm();
  const { setShowHideDownSalePopup } = useDownSale();

  const data = findDataComponent(2, "PaymentForm");

  const paypalBtnRef = useRef(null);
  const appleContainerRef = useRef(null);
  const googleContainerRef = useRef(null);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [formLoading, setFormLoading] = useState(true);

  useEffect(() => {
    const prepareOrderAsync = async () => {
      analyticsEvent("pageView");
      if (currentOrder !== undefined) {
        const updatedSelOption = {
          ...currentOrder,
          varOpt: variantNumber,
        };
        setOrderLocalData(updatedSelOption);
        await prepareOrder();
        setLoading(false);
      }
    };

    prepareOrderAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scriptUrl && paypalBtnRef.current) {
      paypalBtnRef.current.addEventListener(
        "order-started-processing",
        function (e) {
          console.log("order-started-processing", e);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-processed",
        function (e) {
          console.log("order-processed", e);
          startOrderStatusCheck();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "order-already-processed",
        function (e) {
          console.log("order-already-processed", e);
          startOrderStatusCheck();
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-ready",
        function (e) {
          console.log("button-ready", e);
          setFormLoading(false);
        },
        false
      );
      paypalBtnRef.current.addEventListener(
        "button-error",
        function (e) {
          console.log("button-error", e);
          setErrorHandler(e);
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptUrl]);

  const handleOnSuccess = (e) => {
    console.log("handleOnSuccess: ", { e });
    analyticsEvent("addPaymentInfo");

    if (paypalBtnRef.current) {
      paypalBtnRef.current.style.display = "none";
    }
    startOrderStatusCheck();
  };

  const googlePayButtonParams = {
    enabled: paymentButtons.google_pay,
    color: "black",
  };
  const applePayButtonParams = {
    enabled: paymentButtons.apple_pay,
  };

  const customFormStyles = {
    template: "flat",
    submit_button: {
      "background-color": "#02BEB2;",
    },
  };
  const formParams = {
    formTypeClass: "flat",
    submitButtonText: "Continue",
  };

  useEffect(() => {
    if (navigateObserver) {
      navigate("/new/thankyou");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateObserver]);

  if (loading) {
    return <Loader />;
  }

  const closePaymentForm = () => {
    setStartPaymentForm(false);
    if (timer > 0) {
      if (downSaleActive !== true) {
        setShowHideDownSalePopup(true);
      }
    }
  };

  return (
    <div className={styles.ShadowPopup}>
      {isLoading && <Loader />}
      {formLoading ? <Loader /> : null}
      <div className={styles.ScrollContainer}>
        <div className={styles.NewPaymentform}>
          {/* <div className={styles.testHRLine} /> */}
          {/* <div className={styles.testHRLineVert}/> */}
          <div className={styles.OrderDetailsContainer}>
            <span className={styles.OrderDetails}>{data.headerText}</span>
            <button
              className={styles.closeOrderButton}
              onClick={() => {
                closePaymentForm();
              }}
            >
              <img
                src="../img/paymentFormCloseButton.png"
                alt="paymentFormCloseButton"
                className={styles.paymentFormCloseButton}
              />
            </button>
          </div>
          <PlanDetails data={data.PlanDetails} selectedOption={currentOrder} />
          <div
            className={styles.appleContainerBtn}
            ref={appleContainerRef}
          ></div>
          
          <div
            className={styles.googleContainerBtn}
            ref={googleContainerRef}
          ></div>
          <div className={styles.googlePayPalContainer}>
            {parseInt(appData.REACT_APP_IS_PAYPAL_ORDER) && scriptUrl ? (
              <div
                id="paypal-button"
                ref={paypalBtnRef}
                style={{ marginTop: "10px" }}
              />
            ) : null}
          </div>
          <div className={styles.hrLine} />
          <EmailBlock />
          <div className={styles.textOrPayWithCard}>
            <span>{data.payWithCardText}</span>
          </div>
          {offDiscount ? null : <PaymentSystems />}

          <div className={styles.CardPayContainer}>
            <Payment
              merchantData={merchantDataSet}
              googlePayButtonParams={googlePayButtonParams}
              applePayButtonParams={applePayButtonParams}
              googlePayContainerRef={googleContainerRef}
              applePayContainerRef={appleContainerRef}
              styles={customFormStyles}
              formParams={formParams}
              onSuccess={handleOnSuccess}
              // onMounted={handleMounted}
            />
          </div>

          {/* data.secureText */}
          <HRContainer data={data.secureText} />
          {/* {abTestDesignVariant === "A" ? <HRVariantA /> : <HRVariantB />} */}
          <PaymentSystems />
          <BottomDetails
            data={data.bottomDetailsText}
            selectedOption={currentOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentForm;
