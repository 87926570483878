import { createContext, useContext, useEffect, useMemo } from "react";

const EnvContext = createContext();

export const useEnvContext = () => {
  return useContext(EnvContext);
};

export const EnvProvider = ({ children }) => {
  const isProduction = useMemo(() => {
    const currentURL = window.location.href;
    const productionDomain = "start.memoryos.com";
    const stagingDomain = "staging.start.memoryos.com";

    return (
      currentURL.includes(productionDomain) &&
      !currentURL.includes(stagingDomain)
    );
  }, []);

  useEffect(() => {
    if (isProduction) {
      console.log("Memoverse production");
    } else {
      console.log("Memoverse staging");
    }
  }, [isProduction]);

  const contextValue = {
    isProduction,
  };

  return (
    <EnvContext.Provider value={contextValue}>{children}</EnvContext.Provider>
  );
};
