import React from "react";

import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";

import styles from "./FooterBlockStyle.module.css";
import FooterTextContainerTwo from "./FooterTextContainer/FooterTextContainerTwo";

const FooterBlock = () => {
  return (
    <div className={styles.container}>
      <GraySafeBlock />
      <FooterTextContainerTwo />
    </div>
  );
};

export default FooterBlock;
