import React from "react";

import GraySafeIconSVG from "../assets/GraySafeIcon";
import PaymentSystems from "../assets/PaymentSystems";

import styles from "../FooterBlockStyle.module.css";
import { useTimer } from "../../../../../../context/TimerContext";

const GraySafeBlock = ({ text, textSecond }) => {
  const { timer } = useTimer();
  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.hrLine} />

        <div className={styles.iconTextContainer}>
          <GraySafeIconSVG />
          <span className={styles.headerText}>
            {timer > 0 ? text : textSecond}
          </span>
        </div>
        <div className={styles.hrLine} />
      </div>
      <PaymentSystems />
    </div>
  );
};

export default GraySafeBlock;
