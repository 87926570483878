import React, { useEffect, useState } from "react";
import { format, addMonths, addYears, addDays } from "date-fns";

import Loading from "../../../Plan/PaidTrialsAllSubs/Loading/Loading";

import styles from "../TopComponent/TopComponentStyle.module.css";

const calculateEndDate = (planName) => {
  const currentDate = new Date();

  if (typeof planName === "string") {
    if (planName === "YEARLY") {
      return format(addYears(currentDate, 1), "MMMM dd, yyyy");
    } else if (planName === "MONTHLY") {
      return format(addMonths(currentDate, 1), "MMMM dd, yyyy");
    } else if (planName === "6 MONTHS") {
      return format(addMonths(currentDate, 6), "MMMM dd, yyyy");
    } else if (planName === "7 DAY FREE TRIAL" || planName === "7 DAY TRIAL") {
      return format(addDays(currentDate, 7), "MMMM dd, yyyy");
    } else {
      const matches = planName.match(/^(\d+)\s+(MONTHS|DAYS)$/i);
      if (matches) {
        const value = parseInt(matches[1]);
        const unit = matches[2].toUpperCase();
        if (unit === "MONTHS") {
          return format(addMonths(currentDate, value), "MMMM dd, yyyy");
        } else if (unit === "DAYS") {
          return format(addDays(currentDate, value), "MMMM dd, yyyy");
        }
      }
    }
  } else if (Array.isArray(planName)) {
    const planText = planName[0];
    const matches = planText.match(/^(\d+)\s+(MONTHS|DAYS)$/i);
    if (matches) {
      const value = parseInt(matches[1]);
      const unit = matches[2].toUpperCase();
      if (unit === "MONTHS") {
        return format(addMonths(currentDate, value), "MMMM dd, yyyy");
      } else if (unit === "DAYS") {
        return format(addDays(currentDate, value), "MMMM dd, yyyy");
      }
    }
  }

  return null;
};

const TopComponentVariantB = ({ userName, dataComponent, orderData }) => {
  const [trial, setTrial] = useState(false);
  const [specialTrial, setSpecialTrial] = useState(false);
  const [renewalPlan, setRenewalPlan] = useState();

  useEffect(() => {
    if (orderData) {
      const { total, planExpirationDate } = orderData;
      setTrial(parseFloat(total) === 0);
      setSpecialTrial(planExpirationDate === "7 DAY TRIAL");
      setRenewalPlan(calculateEndDate(planExpirationDate));
    }
  }, [orderData]);

  if (!orderData || !orderData.planExpiration) {
    return <Loading />;
  }

  const { optionPlanName, planName } = orderData;

  return (
    <div className={styles.container}>
      <div className={styles.welcomeUserText}>
        <span className={styles.userNameText}>{userName}</span>
        <span>{dataComponent.welcomeText}</span>
      </div>
      <div className={styles.subscContainer}>
        <span className={styles.subscContainerText}>
          {dataComponent.subscText}
        </span>
        <span className={styles.subscContPlanText}>
          {optionPlanName} {planName}
          {trial && ", 7 day trial"}
          {specialTrial && ", 7 day trial"}
        </span>
        {renewalPlan !== null && (
          <span className={styles.subscContExpText}>
            Renewal: {renewalPlan}
          </span>
        )}
      </div>
    </div>
  );
};

export default TopComponentVariantB;
