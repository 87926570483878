import React from "react";

import styles from "./JonasStepStyle.module.css";

const JonasStep = ({ id, step, handleNextStep }) => {
  return (
    <div className={styles.container}>
      <img
        src="../img/quizJonas.png"
        alt="quizJonas"
        className={styles.imgJonas}
      />
      <span className={styles.title}>{step.title}</span>
      <span className={styles.subTitle}>{step.subTitle}</span>
      <div className={styles.hr} />
      <div className={styles.mainContainer}>
        {step.mainText.map((element, index) => {
          return (
            <div key={index} className={styles.element}>
              <img
                src="../img/quizCup.png"
                alt="quizCup"
                className={styles.elementImg}
              />
              <span className={styles.elementText}>{element}</span>
            </div>
          );
        })}
      </div>
      <button
        onClick={() => handleNextStep({ id, type: "jonas" })}
        className={styles.button}
      >
        <span className={styles.buttonText}>Continue</span>
      </button>
    </div>
  );
};

export default JonasStep;
