import React, { useEffect, useRef } from "react";
import { useScroll } from "../../../../context/ScrollContext";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";
import { useTimer } from "../../../../context/TimerContext";


import OptionsPlan from "./OptionsPlan/OptionsPlan";
import FooterBlock from "./FooterBlock/FooterBlock";

import styles from "./ChoosePlan.module.css";


const ChoosePlan = () => {
  const choosePlanRef = useRef(null);
  const { offDiscount } = useFirebaseConfig();
  const { findDataComponent } = useConfig();
  const { scrollRefOne } = useScroll();
  const { timer } = useTimer();

  const data = findDataComponent(1, "ChoosePlan");

  useEffect(() => {
    scrollRefOne.current = choosePlanRef.current;
  }, [scrollRefOne]);

  return (
    <div className={styles.ChoosePlanContainer} ref={choosePlanRef}>
      <div className={styles.HeaderText}>
        {timer > 0
          ? offDiscount
            ? data.headerTextTrialPlan
            : data.headerText
          : data.headerTextTrialPlanOffTimer}
      </div>
      <OptionsPlan />
      <FooterBlock data={data.footerBlock} />
    </div>
  );
};

export default ChoosePlan;
