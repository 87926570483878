import React, { createContext, useContext, useEffect, useState } from "react";

const DevMode = createContext();

export const useDevMode = () => useContext(DevMode);

export const DevModeProvider = ({ children }) => {
  const [devModeValue, setDevModeValue] = useState(false);

  useEffect(() => {
    const storedDevModeValue = localStorage.getItem("systemUPD");
    if (storedDevModeValue === null) {
      localStorage.setItem("systemUPD", "false");
      setDevModeValue(false);
    } else {
      setDevModeValue(storedDevModeValue === "true");
    }
  }, []);

  const contextValue = {
    devModeValue,
  };

  return <DevMode.Provider value={contextValue}>{children}</DevMode.Provider>;
};
