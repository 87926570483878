import React from "react";
import { useABtest } from "../../../../../../context/ABTestContext";


import styles from "../PaymentFormStyle.module.css";

const formatPlanName = (planName) => {
  const lowerCase = planName.toLowerCase();
  if (lowerCase === "monthly") return "month";
  const parts = lowerCase.match(/(\d+)\s*(.+)/);
  return parts
    ? `${parts[1]}-${parts[2].replace("months", "months")}`
    : lowerCase;
};

const TrialText = ({ data, selectedOption }) => (
  <span className={styles.textWithTrial}>
    {data.withTrialPeriod[0]} ${selectedOption.priceTotal}{" "}
    {data.withTrialPeriod[1]} ${selectedOption.fullPlanPrice}{" "}
    {data.withTrialPeriod[2]} {formatPlanName(selectedOption.optionPlanName)}{" "}
    {data.withTrialPeriod[3]}
  </span>
);

const NonTrialText = ({ data, selectedOption }) => (
  <span className={styles.textWithoutTrial}>
    {data.withoutTrialPeriod[0]} ${selectedOption.priceTotal}{" "}
    {data.withoutTrialPeriod[1]} {formatPlanName(selectedOption.optionPlanName)}
    . {data.withoutTrialPeriod[2]}
  </span>
);

const BottomDetails = ({ data, selectedOption }) => {
  const { isActive } = useABtest();

  return (
    <div className={styles.bottomDetailsContainer}>
      <div className={styles.textContainer}>
        {isActive ? (
          <TrialText data={data} selectedOption={selectedOption} />
        ) : (
          <NonTrialText data={data} selectedOption={selectedOption} />
        )}
      </div>
    </div>
  );
};

export default BottomDetails;
