import React, { useEffect, useState } from "react";

import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useVWOContext } from "../../../../context/vwoContext";
import { useTimer } from "../../../../context/TimerContext";

import FooterBlock from "./FooterBlock/FooterBlock";

import AssetsContainer from "./AssetsContainer/AssetsContainer";
import HeaderVariantB from "../ChoosePlan/HeaderRender/HeaderVariantB";
import VariantCsecond from "../ABTestPlanContainer/VariantCsecond/VariantCsecond";

import Loading from "../../ABtestPlanPage/Loading/Loading";

import styles from "./SecondChoosePlan.module.css";


const SecondChoosePlan = () => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();

  const { findDataComponent } = useConfig();
  const { variantNumber } = useVWOContext();
  const { timer } = useTimer();

  const data = findDataComponent(2, "SecondChoosePlan");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (variantNumber !== null) {
      setIsLoading(false);
    }
  }, [variantNumber]);

  return (
    <div className={styles.ChoosePlanContainer}>
      <div className={styles.optionsCont}>
        {abTestDesignVariant === "A" ? (
          <div className={styles.HeaderText}>{data.headerText}</div>
        ) : (
          <HeaderVariantB
            variantNumber={variantNumber}
            data={optionTextData.secondPlanOptionHeaderText}
            timer={timer}
          />
        )}
        {isLoading ? <Loading /> : <VariantCsecond />}
        <FooterBlock
          data={data.footerBlock}
          footerBlockData={data.secondTextInfoBlock}
        />
      </div>
      <AssetsContainer data={data} />
    </div>
  );
};

export default SecondChoosePlan;
