import React from "react";

import CustomBackButton from "../../components/CustomBackButton/CustomBackButton";

import { BackButton } from "../../styles";

const ButtonModule = ({
  currentStep,
  steps,
  handleBackStep,
  variantNumber,
}) => {
  const renderButton = [
    "",
    <BackButton onClick={handleBackStep}>
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#fff"
          d="M7.79991 11L12.6999 6.1C13.0865 5.7134 13.0865 5.0866 12.6999 4.7C12.3133 4.3134 11.6865 4.3134 11.2999 4.7L4.70702 11.2929C4.31649 11.6834 4.31649 12.3166 4.70702 12.7071L11.2999 19.3C11.6865 19.6866 12.3133 19.6866 12.6999 19.3C13.0865 18.9134 13.0865 18.2866 12.6999 17.9L7.79991 13H18.9999C19.5522 13 19.9999 12.5523 19.9999 12C19.9999 11.4477 19.5522 11 18.9999 11H7.79991Z"
        />
      </svg>
      Back
    </BackButton>,
    <CustomBackButton handleBackStep={handleBackStep} />,
  ];
  return (
    <>
      {currentStep !== 0 &&
        steps.length &&
        steps[currentStep].showBackButton &&
        renderButton[variantNumber]}
    </>
  );
};

export default ButtonModule;
