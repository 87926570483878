import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";
import DashesSVG from "./templates/DashesSVG";

import styles from "./WithinMemoryOS.module.css";

const WithinMemoryOS = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1,"WithinMemoryOS");

  const numberOfDashes = 7;
  return (
    <div className={styles.WithinMemoryOSContainer}>
      <div className={styles.WithinMemoryOSHeaderText}>{data.headerText}</div>

      <div className={styles.WithinMemoryOSMain}>
        <div className={styles.WithinMemoryOSBrain}>
          <span
            style={{
              marginRight: "5px",
            }}
          >
            🧠
          </span>
          {Array.from({ length: numberOfDashes }, (_, index) => (
            <DashesSVG key={index} />
          ))}
        </div>
        {data.steps.map((step, index) => {
          return (
            <div key={index} className={styles.WithinMemoryOSTextContainer}>
              <div className={styles.WithinMemoryOSCheck} />
              <div className={styles.WithinMemoryOSText}>{step}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.secondContainer}>
        {data.secondSteps.map((step, index) => {
          return (
            <div key={index} className={styles.secondStepText}>
              <span className={styles.secondStepTextEmoj}>{step[0]}</span>
              <span className={styles.secondStepTextMargin}>{step[1]}</span>
            </div>
          );
        })}
      </div>
      <div className={styles.thirdContainer}>
        <span className={styles.thirdStepsHeaderText}>
          {data.thirdStepsHeaderText}
        </span>
        {data.thirdSteps.map((step, index) => {
          return (
            <div key={index} className={styles.thirdStepsContainer}>
              <span className={styles.thirdStepsText}>{step}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WithinMemoryOS;
