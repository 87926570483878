import React from "react";
import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./BrainEfficiently.module.css";

const BrainEfficiently = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1,"BrainEfficiently");
  return (
    <div className={styles.BrainEfficientlyContainer}>
      <div className={styles.BrainEfficientlyImage}>
      </div>
      <div className={styles.BrainEfficientlyText}>
        <span className={styles.BrainEfficientlyTextWhite}>
          {data.headerText_top}
        </span>
        <span className={styles.BrainEfficientlyTextGreen}>
          {data.headerText_bot}
        </span>
      </div>
      <div className={styles.BrainEfficientlyTextFooter}>{data.mainText}</div>
    </div>
  );
};

export default BrainEfficiently;
