import React from "react";
import { useModal } from "../../../../context/ModalContext";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import { ReactComponent as FrameHurt } from "./assets/FrameHurt.svg";

import styles from "./SpecialDiscountModal.module.css";
import LogoGlobal from "../../../templates/Logo/LogoGlobal";

const SpecialDiscountModal = () => {
  const { showHideElement } = useTimer();
  const { setShowModal } = useModal();
  const { setSpecialDiscount, findDataComponent } = useConfig();

  const data = findDataComponent("SpecialDiscountModal");

  const getBiggestDiscount = (modal) => {
    setSpecialDiscount(true);
    modal(false);
  };

  return (
    <>
      {showHideElement ? (
        <div className={styles.SpecialDiscountBackground}>
          <div className={styles.SpecialDiscountContainer}>
            <LogoGlobal />
            <div className={styles.SpecialDiscountHeader}>
              <div className={styles.SpecialDiscountBigText}>
                {data.headerText}
              </div>
              <div className={styles.SpecialDiscountSmallText}>
                {data.mainText[0]}{" "}
                <span className={styles.SpecialDiscountSmallTextPart}>
                  {data.mainText[1]}
                </span>{" "}
                {data.mainText[2]}
              </div>
            </div>
            <div className={styles.SpecialDiscountMain}>
              <div className={styles.SpecialDiscountHurtImg}>
                <FrameHurt />
              </div>
              <div className={styles.SpecialDiscountMainText}>
                {data.averageText}
              </div>
              <div className={styles.SpecialDiscountUsersImg} />
            </div>

            <div className={styles.SpecialDiscountFooterText}>
              <span>
                {data.bottomText[0]}{" "}
                <span className={styles.SpecialDiscountSmallTextPart}>
                  {data.bottomText[1]}
                </span>{" "}
                {data.bottomText[2]}{" "}
                <span className={styles.SpecialDiscountSmallTextPart}>
                  {data.bottomText[3]}
                </span>
              </span>
            </div>
            <div className={styles.SpecialDiscountFooter}>
              <button
                className={styles.SpecialDiscountButton}
                onClick={() => getBiggestDiscount(setShowModal)}
              >
                {data.buttonText}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SpecialDiscountModal;
