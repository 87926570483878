import React, { useEffect, useRef, useState } from "react";

import { useScroll } from "../../../../context/ScrollContext";
import { useConfig } from "../../../../context/ConfigDataContext";
import { useTimer } from "../../../../context/TimerContext";
import { useVWOContext } from "../../../../context/vwoContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

import VariantB from "../ABTestPlanContainer/VariantB/VariantB";
import OptionsPlan from "./OptionsPlan/OptionsPlan";
import FooterBlock from "./FooterBlock/FooterBlock";
import ImgPlanBlock from "./ImgPlanBlock/ImgPlanBlock";
import Loading from "../Loading/Loading";

import styles from "./ChoosePlan.module.css";
import HeaderVariantA from "./HeaderRender/HeaderVariantA";
import HeaderVariantB from "./HeaderRender/HeaderVariantB";
import VariantС from "../ABTestPlanContainer/VariantC/VariantС";

const ChoosePlan = () => {
  const { abTestDesignVariant, optionTextData } = useFirebaseConfig();
  const choosePlanRef = useRef(null);
  const { findDataComponent } = useConfig();
  const { scrollRefOne } = useScroll();
  const { timer } = useTimer();
  const { variantNumber } = useVWOContext();

  const data = findDataComponent(2, "ChoosePlan");

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    scrollRefOne.current = choosePlanRef.current;
  }, [scrollRefOne]);

  useEffect(() => {
    if (variantNumber !== null) {
      setIsLoading(false);
    }
  }, [variantNumber]);

  return (
    <div className={styles.bigContainer}>
      <ImgPlanBlock />
      <div className={styles.ChoosePlanContainer} ref={choosePlanRef}>
        {abTestDesignVariant === "A" ? (
          <HeaderVariantA
            variantNumber={variantNumber}
            data={data}
            timer={timer}
          />
        ) : (
          <HeaderVariantB
            variantNumber={variantNumber}
            data={optionTextData.firstPlanOptionHeaderText}
            timer={timer}
          />
        )}

        {abTestDesignVariant === "A" ? (
          isLoading ? (
            <Loading />
          ) : variantNumber === 1 ? (
            <OptionsPlan />
          ) : (
            <VariantB />
          )
        ) : abTestDesignVariant === "B" ? (
          isLoading ? (
            <Loading />
          ) : (
            <VariantB />
          )
        ) : isLoading ? (
          <Loading />
        ) : (
          <VariantС />
        )}

        <FooterBlock
          data={data.footerBlock}
          footerData={data.secondTextInfoBlock}
        />
      </div>
    </div>
  );
};

export default ChoosePlan;
