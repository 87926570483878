import React from "react";
import { Prizes } from "../../styles";


import styles from './PrizesStyle.module.css'
const PrizesContainer = () => {
  return (
    <div className={styles.prizesContainer}>
      {/* <div className={styles.prizesFrameOne}/>
      <div className={styles.prizesFrameTwo}/> */}
      <Prizes>
        <img src={"/img/prize3.svg"} alt="Kickstarter" />
        <img src={"/img/prize1.svg"} alt="Techcrunch" />
        <img src={"/img/prize2.svg"} alt="Google Brand Accelerator" />
        <img src={"/img/prize4.svg"} alt="Memory Champion" />
      </Prizes>
    </div>
  );
};
export default PrizesContainer;
