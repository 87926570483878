import React, { useEffect, useState } from "react";
import { useDevMode } from "../../context/DevMode";
import { useVWOContext } from "../../context/vwoContext";

import styles from "./DevModeStyle.module.css";

const DevMode = () => {
  const { devMode, setDevMode } = useVWOContext();
  const { devModeValue } = useDevMode();

  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    setShowButton(devModeValue);
  }, [devModeValue]);

  const handleDevModeClick = () => {
    if (devMode === false) {
      setDevMode(true);
    } else if (devMode === true) {
      setDevMode(false);
    }
  };

  return (
    <>
      {showButton && (
        <button className={styles.devMode} onClick={handleDevModeClick}>
          DevMode
        </button>
      )}
    </>
  );
};

export default DevMode;
