import React, { createContext, useContext, useState, useEffect } from "react";

const UrlContext = createContext();

export const useUrlContext = () => {
  return useContext(UrlContext);
};

export const UrlProvider = ({ children }) => {
  const [queryParams, setQueryParams] = useState({});

  const getCurrentPath = () => {
    const path = window.location.href;
    return path;
  };
  // /?utm_source=source_test&utm_medium=test_placement&utm_campaign=USAADV&utm_content=streetsign&ScCid=7b3a7917-a82a-47e8-9728-e1b3b045abb2&payload=T2xla3NhbmRyfm/ICi5tdXJhbkBtZW1vcnlvcy5jb20=
  useEffect(() => {
    const storedParams =
      JSON.parse(localStorage.getItem("MemoverseQueryParams")) || {};
    const params = getQueryParameters();
    let updatedParams = { ...params };
    if (Object.keys(params).length > 0) {
      setQueryParams(updatedParams);
      localStorage.setItem(
        "MemoverseQueryParams",
        JSON.stringify(updatedParams)
      );
    } else if (Object.keys(storedParams).length > 0) {
      setQueryParams(storedParams);
    }
  }, []);

  const getQueryParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const dataUrl = {};

    for (const [key, value] of urlParams.entries()) {
      dataUrl[key] = value;
    }

    return dataUrl;
  };

  const contextValue = {
    queryParams,
    getCurrentPath,
  };

  return (
    <UrlContext.Provider value={contextValue}>{children}</UrlContext.Provider>
  );
};
