import React, { createContext, useContext, useEffect, useState } from "react";

import { useFirebaseConfig } from "./FirebaseConfigContext";
import { postData } from "../utils/fetch";
import { useUrlContext } from "./urlContext";
import { useVWOContext } from "./vwoContext";

const ConfigDataContext = createContext();

export const useConfig = () => useContext(ConfigDataContext);

export const ConfigDataProvider = ({ children }) => {
  const { appData, config, planPageData, newPlanPage, quizData } =
    useFirebaseConfig();
  const { queryParams, getCurrentPath } = useUrlContext();
  const { variantNumber } = useVWOContext();

  const configCopy = deepCopy(config);

  const [hasSubscr, setHasSubscr] = useState(false);

  const [deletePadding, setDeletePadding] = useState(false);

  const [clearQuizData, setClearQuizData] = useState();

  const [newQuiz, setNewQuiz] = useState(false);

  useEffect(() => {
    const currentQuiz =
      variantNumber === 1
        ? quizData.quizA
        : variantNumber === 2
        ? quizData.quizB
        : quizData.quizC;

    // const currentQuiz =
    //   variantNumber === 1
    //     ? quizData.quizA
    //     : variantNumber === 2
    //     ? quizData.quizD
    //     : quizData.quizD;

    const filteredSteps = currentQuiz.steps.filter(
      (step) => step.showHide === true
    );
    const filteredQuizData = {
      ...currentQuiz,
      steps: filteredSteps,
    };
    setClearQuizData(filteredQuizData);
  }, [quizData, variantNumber]);

  const [newQuizData, setNewQuizData] = useState();

  useEffect(() => {
    const filteredSteps = quizData.quizD.steps.filter(
      (step) => step.showHide === true
    );
    const filteredQuizData = {
      ...quizData.quizD,
      steps: filteredSteps,
    };
    setNewQuizData(filteredQuizData);
  }, [quizData]);

  const findDataComponent = (indexData, componentName) => {
    switch (indexData) {
      case 1:
        return planPageData.find(
          (component) => component.component === componentName
        );
      case 2:
        return newPlanPage.find(
          (component) => component.component === componentName
        );

      default:
        console.log(`Error find component ${componentName}`);
        break;
    }
  };

  const [percentConfig, setPercentConfig] = useState(
    configCopy.discountPercent || null
  );
  const [specialDiscount, setSpecialDiscount] = useState(
    localStorage.getItem("MemoverseUpgradeDiscount")
      ? localStorage.getItem("MemoverseUpgradeDiscount")
      : false
  );

  const biggestPercent = (specialPercent) => {
    setPercentConfig(specialPercent);
  };

  useEffect(() => {
    if (specialDiscount) {
      localStorage.setItem("MemoverseUpgradeDiscount", true);
      biggestPercent(configCopy.specialDiscountPercent);
    }
  }, [configCopy.specialDiscountPercent, specialDiscount]);

  function deepCopy(obj) {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }
    const copy = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        copy[key] = deepCopy(obj[key]);
      }
    }
    return copy;
  }
  const findUserData = () => {
    const userData = JSON.parse(localStorage.getItem("MemoverseUserData"));
    return userData;
  };
  const sendQuizRequest = async () => {
    const userData = JSON.parse(localStorage.getItem("MemoverseUserData"));

    const questionsHash = localStorage.getItem(`MemoverseQuestionsHash`);
    const steps = newQuiz === false ? clearQuizData.steps : newQuizData.steps;
    const quizId = "";

    const quizDataAnswer = JSON.parse(
      localStorage.getItem(`MemoverseQuizData${quizId}`)
    );

    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    }
    const checkSendData = {
      questionsHash: questionsHash,
      origin: appData.REACT_APP_ORIGIN,
      lastUpdate: JSON.parse(
        localStorage.getItem(`MemoverseQuizLastUpdate${quizId}`)
      ),
      source: queryParams.utm_source || queryParams.source,
      placement:
        queryParams.utm_medium || queryParams.medium || queryParams.placement,
      campaign: queryParams.utm_campaign || queryParams.campaign,
      content: queryParams.utm_content || queryParams.content,
      pageUrl: await getCurrentPath(),
    };
    function removeUndefinedOrNullFields(obj) {
      const newObj = {};
      for (const key in obj) {
        if (obj[key] !== undefined && obj[key] !== null) {
          newObj[key] = obj[key];
        }
      }
      return newObj;
    }
    const cleanedData = removeUndefinedOrNullFields(checkSendData);
    const saveDataRequest = postData(
      `${appData.REACT_APP_API_ENDPOINT}/quiz/submit`,
      {
        system: cleanedData,
        user: {
          email: userData.userEmail,
          name: userData.userName,
          fbc: getCookie("_fbc"),
          fbp: getCookie("_fbp"),
          snapUuidC1: getCookie("_scid"),
          snapClickId: queryParams.ScCid,
        },
        answers: quizDataAnswer,
        questions: steps,
      },
      true
    );
    saveDataRequest.onload = () => {
      // eslint-disable-next-line no-unused-vars
      const response = JSON.parse(saveDataRequest.response);
      // console.log(response.current);
    };
    saveDataRequest.onerror = () => {
      console.log("** An error occurred during the transaction");
      console.log(saveDataRequest.statusText);
    };
  };

  const contextValue = {
    clearQuizData,
    hasSubscr,
    setHasSubscr,
    specialDiscount,
    percentConfig,
    setSpecialDiscount,
    findDataComponent,
    configCopy,
    sendQuizRequest,
    findUserData,
    deletePadding,
    setDeletePadding,
    newQuizData,
    setNewQuiz,
  };
  return (
    <ConfigDataContext.Provider value={contextValue}>
      {children}
    </ConfigDataContext.Provider>
  );
};
