import React from "react";
import { useDiscount } from "../../../../../../../context/ApplyDiscountContext";

import PaymentSystems from "../../assets/PaymentSystems";
import GraySafeIconSVG from "../../assets/GraySafeIcon";

import styles from "../../FooterBlockStyle.module.css";

const VariantB = () => {
  const { selectedDiscountOption } = useDiscount();

  return (
    <div>
      <div className={styles.headerContainer}>
        {selectedDiscountOption === 0 ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLine} />
        )}
        <div className={styles.iconTextContainer}>
          <GraySafeIconSVG />
          <span className={styles.headerText}>
            {selectedDiscountOption === 0
              ? "No payment now. Secured by:"
              : "Secured by:"}
          </span>
        </div>
        {selectedDiscountOption === 0 ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLine} />
        )}
      </div>
      <PaymentSystems />
    </div>
  );
};

export default VariantB;
