import React from "react";
import { useBlackFriday } from "../../../../../context/BFContext";
import { usePaymentBlock } from "../../../../../context/PaymentBlockContext";

import styles from "./CheckBoxStyles.module.css";

const CheckBox = ({ text }) => {
  const { option } = useBlackFriday();

  const { setLoader, changeOption } = usePaymentBlock();

  return (
    <div className={styles.checkBoxContainer}>
      <button
        onClick={() => {
          setLoader(true);
          changeOption();
        }}
        className={styles.button}
      >
        <div className={styles.circleBorder}>
          {option === 2 ? <div className={styles.circle} /> : null}
        </div>
        <span className={styles.text}>{text}</span>
      </button>
    </div>
  );
};

export default CheckBox;
