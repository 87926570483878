import React from "react";
import styles from "../ChoosePlan.module.css";
import { useFirebaseConfig } from "../../../../../context/FirebaseConfigContext";

const HeaderVariantB = () => {
  const { discountPageData } = useFirebaseConfig();
  return (
    <div className={styles.HeaderText}>
      {discountPageData.firstPlanOptionHeaderText}
    </div>
  );
};

export default HeaderVariantB;
