import React from "react";

import { useVWOContext } from "../../../../../../../context/vwoContext";
import { useABtest } from "../../../../../../../context/ABTestContext";
import { useTimer } from "../../../../../../../context/TimerContext";

import PaymentSystems from "../../assets/PaymentSystems";
import GraySafeIconSVG from "../../assets/GraySafeIcon";

import styles from "../../FooterBlockStyle.module.css";

const VariantA = ({ text, textSecond }) => {
  const { variantNumber } = useVWOContext();
  const { isActive } = useABtest();
  const { timer } = useTimer();
  return (
    <div>
      <div className={styles.headerContainer}>
        {variantNumber === 2 && !isActive ? (
          <div className={styles.hrLine} />
        ) : variantNumber === 1 ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLineTwo} />
        )}
        <div className={styles.iconTextContainer}>
          <GraySafeIconSVG />
          <span className={styles.headerText}>
            {variantNumber === 2 && !isActive
              ? textSecond
              : variantNumber === 2 && isActive
              ? text
              : variantNumber === 1 && timer > 0
              ? text
              : textSecond}
          </span>
        </div>
        {variantNumber === 2 && !isActive ? (
          <div className={styles.hrLine} />
        ) : variantNumber === 1 ? (
          <div className={styles.hrLineTwo} />
        ) : (
          <div className={styles.hrLineTwo} />
        )}
      </div>
      <PaymentSystems />
    </div>
  );
};

export default VariantA;
