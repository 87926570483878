import React, { createContext, useContext, useEffect, useState } from "react";
import { useFirebaseConfig } from "./FirebaseConfigContext";
import { useVWOContext } from "./vwoContext";
import { usePaymentForm } from "./PaymentFormContext";

const ABTestContext = createContext();

export const useABtest = () => useContext(ABTestContext);

export const ABTestProvider = ({ children }) => {
  const { varBPriceConfig, abTestDesignVariant } = useFirebaseConfig();
  const { variantNumber } = useVWOContext();

  const { setCurrentOrder } = usePaymentForm();

  const [textABToPlan, setABTextToPlan] = useState({});

  const [isActive, setIsActive] = useState(false);

  const [firstOptionRender, setFirstOptionRender] = useState([]);

  const [secondOptionRender, setSecondOptionRender] = useState([]);
  const [selectedABOption, setSelectedOption] = useState(0);

  const [optionToPay, setOptionToPay] = useState({});

  const [index] = useState(0);

  useEffect(() => {
    if (varBPriceConfig.length === 0) {
      return;
    }

    setFirstOptionRender(getOptionsByIndex(index, varBPriceConfig[0]));
    setSecondOptionRender(getOptionsByIndex(index, varBPriceConfig[1]));
    if (firstOptionRender.length !== 0) {
      setABTextToPlan(firstOptionRender[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [varBPriceConfig]);

  function getOptionsByIndex(index, data) {
    const monthsToChoose = data.monthsToChoose;
    return monthsToChoose.map((element) => ({
      fullPlanName: data.planName,
      ...(element.days && { days: element.days }),
      ...(element.month && { month: element.month }),
      optionPlanName: element.optionPlanName,
      trialDays: data.trial_days,
      mostPopular: element.mostPopular,
      fullPlanPrice: element.fullPlanPrice,
      ...element.priceOptions[index],
    }));
  }

  useEffect(() => {
    if (selectedABOption === 0) {
      if (!isActive) {
        setABTextToPlan(firstOptionRender[0]);
        setOptionToPay(firstOptionRender[0]);
      } else {
        setABTextToPlan(firstOptionRender[1]);
        setOptionToPay(firstOptionRender[1]);
      }
    } else if (selectedABOption === 1) {
      setABTextToPlan(secondOptionRender[0]);
      setOptionToPay(secondOptionRender[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedABOption, isActive, firstOptionRender, secondOptionRender]);

  const handleOptionChangeWithToggle = (index) => {
    setSelectedOption(index);

    if (isActive && index === 1) {
      setIsActive(false);
    }
  };
  useEffect(() => {
    const currentPath = window.location.pathname;

    if (currentPath === "/apply-discount") {
      return;
    }
    if (abTestDesignVariant === "B" || abTestDesignVariant === "C") {
      setCurrentOrder(optionToPay);
    } else if (abTestDesignVariant === "A") {
      if (variantNumber === 2) {
        setCurrentOrder(optionToPay);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionToPay, abTestDesignVariant]);

  const contextValue = {
    textABToPlan,
    isActive,
    setIsActive,
    firstOptionRender,
    secondOptionRender,
    selectedABOption,
    setSelectedOption,
    optionToPay,
    handleOptionChangeWithToggle,
  };

  return (
    <ABTestContext.Provider value={contextValue}>
      {children}
    </ABTestContext.Provider>
  );
};
