import React from "react";
import SecondOptionsPlan from "./OptionsPlan/SecondOptionsPlan";

import { useConfig } from "../../../../context/ConfigDataContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";
import FooterBlock from "../ChoosePlan/FooterBlock/FooterBlock";

import styles from "./SecondChoosePlan.module.css";


const SecondChoosePlan = () => {
  const { offDiscount } = useFirebaseConfig();

  const { findDataComponent } = useConfig();
  const data = findDataComponent(1, "SecondChoosePlan");

  const dataChoosePlan = findDataComponent(1, "ChoosePlan");
  const secondChoosePlan = true;

  return (
    <div className={styles.ChoosePlanContainer}>
      <div className={styles.HeaderText}>
        {offDiscount ? data.headerTextTrialPlan : data.headerText}
      </div>
      <SecondOptionsPlan />
      <FooterBlock data={dataChoosePlan.footerBlock} secondChoosePlan={secondChoosePlan} />
    </div>
  );
};

export default SecondChoosePlan;
