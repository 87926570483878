import React from "react";
import { useDiscount } from "../../../../../context/ApplyDiscountContext";

import PaymentButton from "../../PaymentButton/PaymentButton";
import FirstPlanOption from "./PlanOption/FirstPlanOption";
import SecondPlanOption from "./PlanOption/SecondPlanOption";

import Loading from "../../Loading/Loading";
import styles from "./VariantCsecondStyle.module.css";

const VariantCsecond = () => {
  const {
    firstDiscountRender,
    secondDiscountRender,
    selectedDiscountOption,
    setSelectedDiscountOption,
  } = useDiscount();

  return (
    <div className={styles.PlanContainer}>
      <div className={styles.OptionsPlanContainer}>
        {firstDiscountRender.length === 0 ? (
          <Loading />
        ) : (
          <>
            <FirstPlanOption
              firstOptionRender={firstDiscountRender[0]}
              selectedOption={selectedDiscountOption}
              setSelectedDiscountOption={setSelectedDiscountOption}
            />
            <SecondPlanOption
              secondOptionRender={secondDiscountRender[0]}
              selectedOption={selectedDiscountOption}
              setSelectedDiscountOption={setSelectedDiscountOption}
            />
          </>
        )}
      </div>
      <PaymentButton />
    </div>
  );
};

export default VariantCsecond;
