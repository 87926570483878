import React, { useState } from "react";

import styles from "./StepThreeStyle.module.css";

const StepThree = ({ showHideParagraph, dataComponent }) => {
  const [userEmail] = useState(
    JSON.parse(localStorage.getItem("MemoverseUserData"))?.userEmail || ""
  );

  return (
    <div className={styles.container}>
      <p className={styles.stepNumber}>{dataComponent.stepNumber}</p>
      <p className={styles.stepHeader}>
        <span className={styles.stepHeaderMedia}>
          {dataComponent.headerText}
        </span>
      </p>
      <div className={styles.textParts}>
        <span>
          {dataComponent.textParts.slice(0, 2).map((element, index) => (
            <span key={index}>
              <span
                style={
                  index === 0
                    ? { color: "rgb(211, 211, 211)" }
                    : { color: "#E8EDFF", fontWeight: 700 }
                }
                className={styles.plainText}
              >
                {element}
              </span>{" "}
            </span>
          ))}
        </span>
        <span className={styles.plainText}>
          {dataComponent.textParts[2]} {userEmail}
          {dataComponent.textParts[3]}
        </span>
        <span className={styles.plainText}>{dataComponent.textParts[4]}</span>
        {showHideParagraph && (
          <span className={styles.plainText}>{dataComponent.textParts[5]}</span>
        )}
      </div>
    </div>
  );
};

export default StepThree;
