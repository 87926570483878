import React, { useEffect } from "react";
import { useFirebaseConfig } from "../../../context/FirebaseConfigContext";
import { RadioInputProvider } from "../../../context/RadioInputContext";
import { TimerProvider } from "../../../context/TimerContext";
import { useModal } from "../../../context/ModalContext";
import { useControlPaymentPage } from "../../../context/ControlPaymentPageContext";
import { useErrorHandler } from "../../../context/ErrorHandlerContext";
import { useEvent } from "../../../context/EventContext";

import PageComponent from "../templates/PageComponent/PageComponent";
import ContainerComponent from "../templates/ContainerComponent/ContainerComponent";
import TopDiscount from "./TopDiscount/TopDiscount";
import YourGrandmaster from "./YourGrandmaster/YourGrandmaster";
import AnswerQuestions from "./AnswerQuestions/AnswerQuestions";
import ReviewContainer from "./ReviewContainer/ReviewContainer";
import FooterContainer from "./FooterContainer/FooterContainer";
import PrizesContainer from "./PrizesContainer/PrizesContainer";
import ViewingPageCounter from "./ViewingPageCounter/ViewingPageCounter";
import MoneyBackGuarantee from "./MoneyBackGuarantee/MoneyBackGuarantee";
import WithinMemoryOS from "./WithinMemoryOS/WithinMemoryOS";
import SpecialDiscountModal from "./SpecialDiscountModal/SpecialDiscountModal";
import ChoosePlan from "./ChoosePlan/ChoosePlan";
import SecondChoosePlan from "./ChoosePlanSecond/SecondChoosePlan";
import JoinTheRest from "./JoinTheRest/JoinTheRest";
import PleaseNote from "./PleaseNote/PleaseNote";
import DidYouKnow from "./DidYouKnow/DidYouKnow";
import WhatIsMemoryOS from "./WhatIsMemoryOS/WhatIsMemoryOS";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import TopDoubleComponent from "./TopDoubleComponent/TopDoubleComponent";
import InfoImgBlock from "./InfoImgBlock/InfoImgBlock";
import MoreEfficiently from "./MoreEfficiently/MoreEfficiently";
import PaymentBlock from "./PaymentBlock/PaymentBlock";
import Loading from "./Loading/Loading";

const ABtestPlanPage = () => {
  const { showError } = useErrorHandler();
  const { newPlanPage, optionTextData } = useFirebaseConfig();
  const { analyticsEvent } = useEvent();

  const { showModal } = useModal();
  const { checkQuiz } = useControlPaymentPage();
  useEffect(() => {
    checkQuiz();
    analyticsEvent("addToCart");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (Object.keys(optionTextData).length === 0) {
    return <Loading />;
  }

  const getComponentByName = (name) => {
    switch (name) {
      case "TopDiscount":
        return <TopDiscount />;
      case "TopDoubleComponent":
        return <TopDoubleComponent />;
      case "ChoosePlan":
        return <ChoosePlan />;
      case "PrizesContainer":
        return <PrizesContainer />;
      case "WhatIsMemoryOS":
        return <WhatIsMemoryOS />;
      case "WithinMemoryOS":
        return <WithinMemoryOS />;
      case "YourGrandmaster":
        return <YourGrandmaster />;
      case "InfoImgBlock":
        return <InfoImgBlock />;
      case "MoreEfficiently":
        return <MoreEfficiently />;
      case "DidYouKnow":
        return <DidYouKnow />;
      case "ReviewContainer":
        return <ReviewContainer />;
      case "AnswerQuestions":
        return <AnswerQuestions />;
      case "SecondChoosePlan":
        return <SecondChoosePlan />;
      case "MoneyBackGuarantee":
        return <MoneyBackGuarantee />;
      case "ViewingPageCounter":
        return <ViewingPageCounter />;
      case "JoinTheRest":
        return <JoinTheRest />;
      case "PleaseNote":
        return <PleaseNote />;

      default:
        return null;
    }
  };
  const backgroundColor = "none";
  const styleOpt = { width: "100%" };
  return (
    <TimerProvider>
      <PageComponent bgColor={backgroundColor}>
        {showModal ? <SpecialDiscountModal /> : false}
        <RadioInputProvider>
          <ContainerComponent styleOption={styleOpt}>
            {showError ? <ErrorHandler /> : null}
            {newPlanPage
              .sort((a, b) => a.position - b.position)
              .map((item, index) => (
                <React.Fragment key={index}>
                  {item.showHide && getComponentByName(item.component)}
                </React.Fragment>
              ))}
            <PaymentBlock />
          </ContainerComponent>
          <FooterContainer />
        </RadioInputProvider>
      </PageComponent>
    </TimerProvider>
  );
};

export default ABtestPlanPage;
