import React from "react";

import styles from "./ReviewStepStyle.module.css";

const ReviewStep = ({ id, step, handleNextStep }) => {
  const createTextReview = (data) => {
    return (
      <div className={styles.reviewContainer}>
        {data.map((element, index) => {
          return (
            <span
              key={index}
              style={{ color: `${element.color}` }}
              className={styles.review}
            >
              {element.text}
            </span>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.reviewBlock}>
        <span className={styles.reviewTitle}>{step.reviewTittle}</span>
        <div className={styles.rateContainer}>
          {Array.from({ length: step.reviewRate }).map((_, index) => {
            return (
              <img
                key={index}
                className={styles.reviewStar}
                src="../img/reviewStar.png"
                alt="reviewStar"
              />
            );
          })}
        </div>
        {createTextReview(step.review)}
        <div className={styles.reviewerInfo}>
          <span className={styles.reviewer}>{step.reviewer}</span>
          <span className={styles.reviewer}>{step.reviewDate}</span>
        </div>
      </div>
      <button
        className={styles.buttonStyle}
        onClick={() => handleNextStep({ id, type: "review" })}
      >
        <span className={styles.buttonText}>{step.buttonText}</span>
      </button>
    </div>
  );
};

export default ReviewStep;
