import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useConfig } from "../../context/ConfigDataContext";
import { Component, Prizes } from "../Landing_A/styles";
import styles from "./LandingB.module.css";
import LogoGlobal from "../templates/Logo/LogoGlobal";
import { useEvent } from "../../context/EventContext";
import { Loader } from "../Plan/styles";

const LandingB = () => {
  const { findDataComponent } = useConfig();
  const { analyticsEvent } = useEvent();
  const [dataComponent, setDataComponent] = useState(null);

  useEffect(() => {
    const fetchDataComponent = async () => {
      const data = await findDataComponent(1, "LandingB");
      setDataComponent(data);
    };

    fetchDataComponent();
  }, [findDataComponent]);

  if (!dataComponent) {
    return <Loader />;
  }

  const specialHighlightedText = dataComponent.highlightedText || "";
  const headerTextParts = specialHighlightedText.split(
    dataComponent.textSpecialStyle || ""
  );

  const mainTextWithSpecialStyle = (
    <span>
      {headerTextParts[0]}
      {
        <span className={styles.mainSpecialText}>
          {dataComponent.textSpecialStyle}
        </span>
      }
      {headerTextParts[1]}
      <br />
      <br />
      {dataComponent.mainText}
    </span>
  );

  const handleGetStarted = () => {
    analyticsEvent("getStartedClicked", { page: "LandingB" });
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          <LogoGlobal />
        </div>
        <div className={styles.container}>
          <div className={styles.headerText}>{dataComponent.headerText}</div>
          <div className={styles.lowHeadText}>{dataComponent.lowHeadText}</div>
          <div className={styles.mainText}>{mainTextWithSpecialStyle}</div>
        </div>
        <Link to="/quiz/1" className={styles.link}>
          <button onClick={handleGetStarted} className={styles.ContainerButton}>
            {dataComponent.buttonText}
            <div className={styles.ButtonArrowContainer}>
              <span className={styles.ButtonArrow}>&rarr;</span>
            </div>
          </button>
        </Link>
        <Component $dark>
          <Prizes>
            <img src={"/img/prize3.svg"} alt="Kickstarter" />
            <img src={"/img/prize1.svg"} alt="Techcrunch" />
            <img src={"/img/prize2.svg"} alt="Google Brand Accelerator" />
            <img src={"/img/prize4.svg"} alt="Memory Champion" />
          </Prizes>
        </Component>
        <div className={styles.LandingFooter}>
          <div className={styles.FooterPolicyGroup}>
            <span className={styles.FooterContainerTextLink}>
              <a
                href="https://memoryos.com/privacy-policy"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </span>
            <span className={styles.FooterContainerTextLink}>
              <a
                href="https://memoryos.com/terms"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </a>
            </span>
            <span className={styles.FooterContainerTextLink}>
              {" "}
              <a
                href="https://memoryos.com/terms"
                className={styles.FooterContainerText}
                target="_blank"
                rel="noopener noreferrer"
              >
                Subscription Policy
              </a>
            </span>
          </div>
          <div className={styles.FooterCopyright}>
            <span className={styles.FooterContainerText}>
              © Copyright {new Date().getFullYear()} Encoder Inc., Delaware, the
              USA
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingB;
