import React from "react";

import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";
import FooterTextContainer from "./FooterTextContainer/FooterTextContainer";

import styles from "./FooterBlockStyle.module.css";

const FooterBlock = ({ data, footerBlockData }) => {

  return (
    <div className={styles.container}>
      <GraySafeBlock
        text={data.headerText}
        textSecond={data.headerTextOffDiscount}
      />
      <FooterTextContainer data={footerBlockData} />
    </div>
  );
};

export default FooterBlock;
