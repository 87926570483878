import React from "react";

import InfoTitle from "../../ABtestPlanPage/InfoTitle/InfoTitle";
import ImgModule from "../ImgModule/ImgModule";

import styles from "./TopDoubleComponentStyle.module.css";

const TopDoubleComponent = () => {
  return (
    <div className={styles.doubleComponentStyle}>
      <InfoTitle />
      <ImgModule />
    </div>
  );
};

export default TopDoubleComponent;
