import React from "react";

const DashesSVG = () => {
  return (
      <svg
        width="16"
        height="4"
        viewBox="0 0 16 4"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="16" height="4" rx="2" fill="#4BEEB3" />
      </svg>
  );
};

export default DashesSVG;
