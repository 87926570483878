import React from "react";
import SafeIconSVG from "./assets/SafeIcon";
import styles from "./FooterBlockStyle.module.css";
import FooterTextContainer from "./FooterTextContainer/FooterTextContainer";
import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";

const FooterBlock = ({ data, secondChoosePlan }) => {
  return (
    <div className={styles.container}>
        
      <GraySafeBlock text={data.headerText} textSecond={data.headerTextOffDiscount} />
      <FooterTextContainer data={data.textInfoBlock} />

      {secondChoosePlan ? null : (
        <div className={styles.FooterSafe}>
          <div className={styles.FooterSafeIcon}>
            <SafeIconSVG />
          </div>
          <span className={styles.FooterSafeText}>{data.footerText}</span>
        </div>
      )}
    </div>
  );
};

export default FooterBlock;
