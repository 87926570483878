import React from "react";

import SafeIconSVG from "./assets/SafeIcon";
import GraySafeBlock from "./GraySafeBlock/GraySafeBlock";
import FooterTextContainerTwo from "../FooterTextContainer/FooterTextContainerTwo";

import styles from "./FooterBlockStyle.module.css";

const FooterBlock = () => {
  return (
    <div className={styles.container}>
      <GraySafeBlock />

      <div className={styles.FooterSafe}>
        <div className={styles.FooterSafeIcon}>
          <SafeIconSVG />
        </div>
        <span className={styles.FooterSafeText}>
          30-day money-back guarantee
        </span>
      </div>
      <FooterTextContainerTwo />
    </div>
  );
};

export default FooterBlock;
