import React from "react";
import Timer from "../Timer/Timer";
import { useScroll } from "../../../../context/ScrollContext";
import { useTimer } from "../../../../context/TimerContext";
import { useConfig } from "../../../../context/ConfigDataContext";

import LogoGlobal from "../../../templates/Logo/LogoGlobal";
import styles from "./TopDiscount.module.css";

const TopDiscount = () => {
  const { findDataComponent, statusPaymentForm } = useConfig();
  const { showHideElement } = useTimer();
  const { scrollToComponent } = useScroll();

  const dataComponent = findDataComponent(2, "TopDiscount");

  const handleButtonClick = () => {
    setTimeout(() => {
      scrollToComponent();
    }, 50);
  };

  return (
    <>
      {!statusPaymentForm ? (
        showHideElement ? (
          <div className={styles.containerDiscount}>
            <div className={styles.topLogo}>
              <LogoGlobal />
            </div>
            <div className={styles.topContent}>
              <div className={styles.containerDiscountInfo}>
                <div className={styles.containerDiscountTitle}>
                  {dataComponent.text}
                </div>
                <div className={styles.containerDiscountTimer}>
                  <Timer />
                </div>
              </div>
              <button
                className={styles.containerDiscountButton}
                onClick={handleButtonClick}
              >
                <span className={styles.containerDiscountButtonText}>
                  {dataComponent.buttonText}
                </span>
              </button>
            </div>
          </div>
        ) : null
      ) : null}
    </>
  );
};

export default TopDiscount;
