import React, { useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import SwiperCore, { Autoplay, Pagination } from "swiper";

import styles from "./HeaderSliderStyle.module.css";

SwiperCore.use([Autoplay, Pagination]);

const HeaderSlider = ({ slideData, delayProps }) => {
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.autoplay.start();
    }
  }, []);

  return (
    <Swiper
      ref={swiperRef}
      spaceBetween={30}
      autoplay={{
        delay: delayProps,
        disableOnInteraction: false,
        pauseOnMouseEnter: false,
      }}
      loop={true}
      allowTouchMove={false}
      className={styles.SwiperContainer}
    >
      {slideData.map((element, index) => {
        return (
          <SwiperSlide className={styles.textContainer} key={index}>
            <div className={styles.text}>{element}</div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default HeaderSlider;
