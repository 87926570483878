import React from "react";

import styles from "./CustomBackButtonStyle.module.css";

const CustomBackButton = ({ handleBackStep }) => {
  return (
    <button className={styles.container} onClick={handleBackStep}>
      <div className={styles.imgArrow} />
      <span className={styles.textBack}>Back</span>
    </button>
  );
};

export default CustomBackButton;
