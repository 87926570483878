import React from "react";
import RatingContainer from "./RatingContainer/RatingContainer";

import { useConfig } from "../../../../context/ConfigDataContext";

import styles from "./InfoTitle.module.css";
import { useTimer } from "../../../../context/TimerContext";
import { useFirebaseConfig } from "../../../../context/FirebaseConfigContext";

const InfoTitle = () => {
  const { offDiscount } = useFirebaseConfig();
  const { specialDiscount, findDataComponent } = useConfig();
  const { timer } = useTimer();

  const data = findDataComponent(1, "InfoTitle");

  return (
    <>
      {!specialDiscount && !offDiscount ? (
        <div className={styles.InfoTitleContainer}>
          <div className={styles.InfoTitleContainerHeader}>
            {data.textHeader}
          </div>
          <div className={styles.InfoTitleContainerRating}>
            <RatingContainer />
            <div className={styles.InfoTitleContainerRatingText}>
              {data.textMain}
            </div>
          </div>
        </div>
      ) : specialDiscount && timer < 0 && !offDiscount ? (
        <div className={styles.InfoTitleContainer}>
          <div className={styles.InfoTitleContainerHeader}>
            {data.textHeader}
          </div>
          <div className={styles.InfoTitleContainerRating}>
            <RatingContainer />
            <div className={styles.InfoTitleContainerRatingText}>
              {data.textMain}
            </div>
          </div>
        </div>
      ) : offDiscount && timer > 0 ? (
        <div className={styles.InfoTitleContainer}>
          <div className={styles.InfoTitleContainerHeader}>
            {data.textHeaderTrialPlan}
          </div>
          <div className={styles.InfoTitleContainerRating}>
            <RatingContainer />
            <div className={styles.InfoTitleContainerRatingText}>
              {data.textMain}
            </div>
          </div>
        </div>
      ) : offDiscount && timer <= 0 ? (
        <div className={styles.InfoTitleContainer}>
          <div className={styles.InfoTitleContainerHeader}>
            {data.textHeaderTrialPlanOffTimer}
          </div>
          <div className={styles.InfoTitleContainerRating}>
            <RatingContainer />
            <div className={styles.InfoTitleContainerRatingText}>
              {data.textMain}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default InfoTitle;
