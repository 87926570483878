import React from "react";
import { useFirebaseConfig } from "../../../../../../context/FirebaseConfigContext";
import { useABtest } from "../../../../../../context/ABTestContext";
import { useVWOContext } from "../../../../../../context/vwoContext";

import Price from "../Price/Price";

import styles from "../VariantСStyle.module.css";

const FirstPlanOption = ({
  firstOptionRender,
  selectedOption,
  handleOptionChangeWithToggle,
}) => {
  const { abTestDesignVariant } = useFirebaseConfig();

  const { variantNumber } = useVWOContext();

  const { isActive } = useABtest();

  const randomId = Math.round(Math.random() * 1000000);
  return (
    <label
      className={`${styles.optionStyle} ${
        selectedOption === 0 ? styles.actives : ""
      }`}
      htmlFor={`${firstOptionRender.productID}${randomId}`}
      key={firstOptionRender.productID}
    >
      {firstOptionRender.mostPopular && (
        <div className={styles.mostPopular}>
          <span className={styles.mostPopularText}>MOST POPULAR</span>
        </div>
      )}
      <div className={styles.optionStyleRadioOne}>
        <input
          type="radio"
          name={firstOptionRender.month || firstOptionRender.days}
          id={`${firstOptionRender.productID}${randomId}`}
          value={firstOptionRender.month || firstOptionRender.days}
          checked={selectedOption === 0}
          onChange={() => handleOptionChangeWithToggle(0)}
        />
        <span
          className={`${styles.customCheckboxOne} ${
            selectedOption === 0 ? styles.customCheckboxOneAfter : ""
          }`}
        ></span>
        {abTestDesignVariant === "A" ? (
          <div className={styles.optionTextContainer}>
            <span className={styles.optionStyleRadioText}>
              {firstOptionRender.month}
            </span>
            <span className={styles.optionStyleRadioTextBottom}>
              {firstOptionRender.saveText}
            </span>
          </div>
        ) : (
          <div className={styles.optionTextContainer}>
            {isActive === false ? (
              <div>
                {Array.isArray(firstOptionRender.month) ? (
                  <>
                    <span className={styles.optionStyleRadioTextLeft}>
                      {firstOptionRender.month[0]}
                    </span>
                    <span className={styles.optionStyleRadioTextRight}>
                      {firstOptionRender.month[1]}
                    </span>
                  </>
                ) : (
                  <span className={styles.optionStyleRadioTextLeft}>
                    {firstOptionRender.month}
                  </span>
                )}
              </div>
            ) : (
              <span className={styles.optionStyleRadioTextLeft}>
                {firstOptionRender.month}
              </span>
            )}
            {variantNumber === 3
              ? firstOptionRender.saveText !== "" && (
                  <div className={styles.partsSaveText}>
                    {Array.isArray(firstOptionRender.saveText) ? (
                      <>
                        <span className={styles.optionStyleRadioTextBottomLeft}>
                          {firstOptionRender.saveText[0]}
                        </span>
                        <span className={styles.optionStyleRadioTextBottomLeft}>
                          {firstOptionRender.saveText[1]}
                        </span>
                      </>
                    ) : (
                      <span className={styles.optionStyleRadioTextBottomRight}>
                        {firstOptionRender.saveText}
                      </span>
                    )}
                  </div>
                )
              : firstOptionRender.saveText !== "" && (
                  <div className={styles.partsSaveText}>
                    {Array.isArray(firstOptionRender.saveText) ? (
                      <>
                        <span className={styles.optionStyleRadioTextBottomLeft}>
                          {firstOptionRender.saveText[0]}
                        </span>
                        <span
                          className={styles.optionStyleRadioTextBottomRight}
                        >
                          {firstOptionRender.saveText[1]}
                        </span>
                      </>
                    ) : (
                      <span className={styles.optionStyleRadioTextBottomRight}>
                        {firstOptionRender.saveText}
                      </span>
                    )}
                  </div>
                )}
          </div>
        )}
      </div>
      <Price
        pricePerDay={firstOptionRender.pricePerDay}
        oldPrice={firstOptionRender.oldPrice}
      />
    </label>
  );
};

export default FirstPlanOption;
