import React from "react";
import Price from "./Price/Price";

import { useRadioInput } from "../../../../../context/RadioInputContext";
import { useErrorHandler } from "../../../../../context/ErrorHandlerContext";

import Loading from "../../Loading/Loading";

import styles from "./OptionsPlan.module.css";
import PaymentButton from "../../PaymentButton/PaymentButton";

const OptionsPlan = () => {
  const { selectedOption, currentRender, error, handleOptionChange } =
    useRadioInput();
  const { setErrorHandler } = useErrorHandler();

  if (error) {
    return setErrorHandler(error);
  }

  if (currentRender.length === 0) {
    return <Loading />;
  }
  return (
    <div className={styles.PlanContainer}>
      <div className={styles.OptionsPlanContainer}>
        {currentRender.map((element, index) => {
          const randomId = Math.round(Math.random() * 1000000);
          return (
            <label
              className={`${styles.optionStyle} ${
                selectedOption === index ? styles.actives : ""
              }`}
              htmlFor={`${element.productID}_${randomId}`}
              key={element.productID}
            >
              {element.mostPopular && (
                <div className={styles.mostPopular}>
                  <span className={styles.mostPopularText}>MOST POPULAR</span>
                </div>
              )}
              <div className={styles.optionStyleRadioOne}>
                <input
                  type="radio"
                  name={element.month || element.days}
                  id={`${element.productID}_${randomId}`}
                  value={element.month || element.days}
                  checked={selectedOption === index}
                  onChange={() => handleOptionChange(index)}
                />
                <span
                  className={`${styles.customCheckboxOne} ${
                    selectedOption === index
                      ? styles.customCheckboxOneAfter
                      : ""
                  }`}
                ></span>
                <span className={styles.optionStyleRadioText}>
                  {element.month
                    ? `${element.month} MONTH${element.month > 1 ? "S" : ""}`
                    : `${element.days}-DAY${element.days > 1 ? "S" : ""}`}
                </span>
              </div>
              <Price
                pricePerDay={element.pricePerDay}
                oldPrice={element.oldPrice}
                saveText={element.saveText ? element.saveText : null}
              />
            </label>
          );
        })}
      </div>
      <PaymentButton />
    </div>
  );
};

export default OptionsPlan;
