import React, { useEffect, useState } from "react";

import { useDiscount } from "../../../../../../context/ApplyDiscountContext";
import { useFirebaseConfig } from "../../../../../../context/FirebaseConfigContext";

import styles from "./FooterTextContainerStyle.module.css";

const FooterTextContainerTwo = () => {
  const { discountPageData } = useFirebaseConfig();

  const { selectedDiscountOption } = useDiscount();

  const [currentRenderData, setCurrentRenderData] = useState("");

  useEffect(() => {
    setCurrentRenderData(
      discountPageData.secondPlanOptionBottomText[selectedDiscountOption]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountPageData.secondPlanOptionBottomText, selectedDiscountOption]);

  return (
    <div className={styles.Footer}>
      <span className={styles.FooterText}>{currentRenderData}</span>
    </div>
  );
};

export default FooterTextContainerTwo;
