import React, { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { hashString } from "../../utils/hash";
import { useEvent } from "../../context/EventContext";
import { useConfig } from "../../context/ConfigDataContext";
// import { useVWOContext } from "../../context/vwoContext";

import ProgressBarModule from "./modules/ProgressBarModule/ProgressBarModule";
import ButtonModule from "./modules/ButtonModule/ButtonModule";
import LogoModule from "./modules/LogoModule/LogoModule";

import EmailStep from "./components/EmailStep/EmailStep";
import SingleStep from "./components/SingleStep/SingleStep";
import StaticStep from "./components/StaticStep/StaticStep";
import MultipleStep from "./components/MultipleStep/MultipleStep";
import RatingStep from "./components/RatingStep/RatingStep";
import FormStep from "./components/FormStep/FormStep";
import PlanStep from "./components/PlanStep/PlanStep";
import SummaryStep from "./components/SummaryStep/SummaryStep";
import LoaderStep from "./components/LoaderStep/LoaderStep";
import ReviewStep from "./components/ReviewStep/ReviewStep";
import TrialStep from "./components/TrialStep/TrialStep";
import AgeStep from "./components/AgeStep/AgeStep";
import KickstarterStep from "./components/KickstarterStep/KickstarterStep";
import JonasStep from "./components/JonasStep/JonasStep";

import { Page, Container, Header } from "./styles";

import styles from "./indexStyle.module.css";

const NewQuiz = () => {
  const { sendQuizRequest, findUserData, newQuizData, setNewQuiz } =
    useConfig();
  // const { variantNumber } = useVWOContext();

  const variantNumber = 2;

  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [userData, setUserData] = useState([]);
  const [quizId, setQuizId] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  const { analyticsEvent } = useEvent();

  const [quiz, setQuiz] = useState(null);
  useEffect(() => {
    setQuiz(newQuizData);
  }, [newQuizData]);

  useEffect(() => {
    analyticsEvent("quizPageView", currentStep + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  useEffect(() => {
    setNewQuiz(true);
    const initializeQuiz = async () => {
      const savedQuizId = params.quizId || "";
      setQuizId(savedQuizId);

      if (quiz) {
        quiz.steps
          .filter(
            (x) => x.hasOwnProperty("image") || x.hasOwnProperty("thumbnail")
          )
          .forEach((x) => (new Image().src = x.image || x.thumbnail));

        const hash = await hashString(JSON.stringify(quiz.steps));
        const savedHash = localStorage.getItem(
          `MemoverseQuestionsHash${savedQuizId}`
        );

        if (hash !== savedHash) {
          localStorage.setItem(`MemoverseQuestionsHash${savedQuizId}`, hash);
          localStorage.removeItem(`MemoverseQuizData${savedQuizId}`);
          localStorage.removeItem(`MemoverseCurrentStep${savedQuizId}`);
        } else {
          const savedData =
            JSON.parse(
              localStorage.getItem(`MemoverseQuizData${savedQuizId}`)
            ) || [];
          const savedStep =
            parseInt(
              localStorage.getItem(`MemoverseCurrentStep${savedQuizId}`)
            ) || 0;

          if (savedData.length === quiz.steps.length) {
            navigate("/new/plan");
            return;
          }

          setUserData(savedData);
          setCurrentStep(savedStep);
          navigate(
            `/new/quiz${savedQuizId ? `/${savedQuizId}` : ""}/${savedStep + 1}`,
            { replace: true }
          );
        }

        setSteps(quiz.steps);
      }
    };

    initializeQuiz();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quiz,
    params.quizId,
    navigate,
    setQuizId,
    setUserData,
    setCurrentStep,
    setSteps,
  ]);

  const handleNextStep = (data) => {
    const newUserData = [...userData, data];
    const nextStep = currentStep + 1;

    setUserData(newUserData);
    localStorage.setItem(
      `MemoverseQuizData${quizId}`,
      JSON.stringify(newUserData)
    );
    localStorage.setItem(`MemoverseCurrentStep${quizId}`, nextStep.toString());
    localStorage.setItem(
      `MemoverseQuizLastUpdate${quizId}`,
      Date.now().toString()
    );

    if (nextStep < steps.length) {
      setCurrentStep(nextStep);
      navigate(`/new/quiz${quizId ? `/${quizId}` : ""}/${nextStep + 1}`, {
        replace: true,
      });
    } else {
      if (findUserData() !== null) {
        sendQuizRequest();
      }
      navigate("/new/plan");
    }

    analyticsEvent("pageView");
    window.scrollTo(0, 0);
  };

  const handleBackStep = () => {
    const prevStep = currentStep - 1;
    const updatedUserData = userData.slice(0, -1);

    setUserData(updatedUserData);
    setCurrentStep(prevStep);

    localStorage.setItem(
      `MemoverseQuizData${quizId}`,
      JSON.stringify(updatedUserData)
    );
    localStorage.setItem(`MemoverseCurrentStep${quizId}`, prevStep.toString());
    localStorage.setItem(
      `MemoverseQuizLastUpdate${quizId}`,
      Date.now().toString()
    );

    navigate(`/new/quiz${quizId ? `/${quizId}` : ""}/${prevStep + 1}`, {
      replace: true,
    });
  };

  const stepComponents = {
    single: SingleStep,
    multiple: MultipleStep,
    static: StaticStep,
    rating: RatingStep,
    form: FormStep,
    email: EmailStep,
    summary: SummaryStep,
    plan: PlanStep,
    loader: LoaderStep,
    review: ReviewStep,
    trialStep: TrialStep,
    ageStep: AgeStep,
    kickstarter: KickstarterStep,
    jonas: JonasStep,
  };

  const memoizedUserData = useMemo(() => userData, [userData]);

  const CurrentStepComponent = stepComponents[steps[currentStep]?.type];

  return (
    <Page>
      <Header
        style={{
          flexDirection: variantNumber === 1 ? "column-reverse" : "column",
          alignItems: "center",
        }}
      >
        <div className={styles.topContainer}>
          <ButtonModule
            currentStep={currentStep}
            steps={steps}
            handleBackStep={handleBackStep}
            variantNumber={variantNumber}
          />
          <LogoModule variantNumber={variantNumber} />
        </div>

        <ProgressBarModule
          currentStep={currentStep}
          steps={steps}
          variantNumber={variantNumber}
        />
        {currentStep >= 2 && steps[currentStep].showProgressBar && (
          <span className={styles.textPart}>{steps[currentStep].part}</span>
        )}
      </Header>
      <Container
        style={{
          margin: "20px auto",
        }}
      >
        {CurrentStepComponent && (
          <CurrentStepComponent
            id={currentStep}
            step={steps[currentStep]}
            handleNextStep={handleNextStep}
            userData={memoizedUserData}
          />
        )}
      </Container>
    </Page>
  );
};

export default NewQuiz;
