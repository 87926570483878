import React, { useState } from "react";

import {
  Component,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
} from "../../styles";

import styles from "./AnswerQuestions.module.css";
import { useConfig } from "../../../../context/ConfigDataContext";

const AnswerQuestions = () => {
  const { findDataComponent } = useConfig();

  const data = findDataComponent(1, "AnswerQuestions");

  const Accordion = ({ title, body }) => {
    const [isOpen, setOpen] = useState(false);
    const handleOpen = () => {
      setOpen(!isOpen);
    };
    return (
      <AccordionItem>
        <AccordionTitle $isOpen={isOpen} onClick={handleOpen}>
          {title}
        </AccordionTitle>
        <AccordionBody $isOpen={isOpen}>{body}</AccordionBody>
      </AccordionItem>
    );
  };
  return (
    <div className={styles.container}>
      <Component $dark>
        <div className={styles.AnswerQuestionsHeaderText}>
          {data.headerText}
        </div>
        {data.questions.map((questElem, index) => {
          return (
            <Accordion
              key={index}
              title={questElem.title}
              body={questElem.body}
            />
          );
        })}
      </Component>
    </div>
  );
};

export default AnswerQuestions;
